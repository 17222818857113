@charset "UTF-8";


* {
	padding:0;
	margin:0;
	list-style: none;
	font-family: 'Happiness-Sans', 'Noto Sans', Arial, dotum, 'sans-serif';
	-webkit-text-size-adjust: none;
}

@import "_font.scss"; // 폰트
@import "_var.scss"; // 변수
@import "_mixins.scss"; // 믹스인
@import "_layout.scss"; // 레이아웃
@import "_table.scss"; // 테이블 (221225 추가)
@import "_button.scss"; // 버튼
@import "_tab.scss"; // 탭
@import "_form.scss"; // 폼
@import "_bullet.scss"; // 블렛
@import "_list.scss"; // 리스트
@import "_flag.scss"; // 플래그
@import "_carousel.scss"; // 캐러셀
@import "_layer.scss"; // 레이어팝업
@import "_winpop.scss"; // 레이어팝업
@import "_gnb.scss"; // GNB
@import "_footer.scss"; // Footer
@import "_floating.scss"; // 플로팅 컨텐츠
@import "_placeholder.scss";
@import "_paging.scss"; // 페이징
@import "_calendar.scss"; // 달력
@import "_policy.scss"; // 약관

html,
body {
	@include media-query(wscorll);
}

.hidden, legend, caption, hr, .blind, .hide {
	@include hidden;
}
fieldset {
	border:none;
}

a{
	&:hover,
	&:focus {
		text-decoration: none;
	}
}

img {
	vertical-align:top;
}


div {
	// @include box;
	// @include media-query(mo){
	//     background-color:$gray04
	// }
}

/* 스킵네비게이션 */
nav {
	&.skip {
		a {
			position:absolute;
			top:-100px;
			width:100%;
			z-index:150;
			background-color:$gray01;
			padding:5px;
			text-align:center;
			@include font(14px,700,$white,1.5);
			&:focus, &:active {
				top:0;
			}
		}
	}
}
/* //스킵네비게이션 */



/* 다운로드 진행표 테이븚 */
.download_wrap {
	@include table;
}