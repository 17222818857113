@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";


.slide_wrap {
	position:relative;
	.indi_wrap {
		position:absolute;
		left:0;
		right:0;
		bottom:24px;
		z-index:10;
		.indi_conts {
			font-size:0;
			text-align:center;
			> li {
				line-height:0;
				font-size:0;
				.btn_indi {
					> span {
						@include hidden;
					}
				}
			}
		}
		.indi_control {
			> span {
				@include hidden;
			}
		}
	}
	//프로스래스바 타입 추가
	.indi_progress {
		position:absolute;
		z-index:10;
		left:0;
		right:0;
		height:2px;
		.track {
			height:2px;
			width:100%;
			background-color:rgba(0,0,0,0.2);
			.current {
				display:block;
				height:2px;
				background-color:rgba(0,0,0,1);
				> i {
					@include hidden;
				}
			}
		}
	}
	.slide_content {
		.slide_track {
			content:' ';
			display:block;
			clear:both;
			overflow:hidden;
		}
		.slide_conts {
			position:relative;
			&.on { // PCU1.1에서 상단배너 이미지가 2560이라 다음이미지가 올라오는 현상 방지
				z-index:10;
			}
			.inner_box {
				a {
					display:block;
					&:active{
						outline:none;
						border:none;
					}
					&:focus {
						// outline: 2px solid blue;
						position:relative;
						//border:2px solid blue;
						&:after{
							@include sudo_pos(0,0,0,0){
								opacity:rgba(0,0,0,0.5);
							}
						}
					}
				}
			}
		}
	}
	.slide_controls {
		position:absolute;
		top:50%;
		left:50%;
		width:auto;
		max-width:1600px;
		z-index:10;
		button {
			position:absolute;
			opacity: 1;
			&.prev {
				right:0;
				transform: rotate(180deg);
			}
			&.next {
				left:0;
			}
			> span {
				@include hidden;
			}
			&.disabled {
				opacity: 0.5;
			}
		}
	}
}

.scroll_wrap {
	position:relative;
	> div {
		overflow:hidden;
	}
	.slide_controls {
		position:absolute;
		button {
			position:absolute;
			opacity: 1;
			&.prev {
				right:0;
				transform: rotate(180deg);
			}
		}
	}
}

.rolling_wrap {
	.list_wrap{
		position:relative;
		width:100%;
		height:100%;
		overflow:hidden;
		> ul {
			> li {
				> a {
					display:block;
				}
			}
		}
	}
	.slide_controls {
		position:absolute;
		.btn_arrow {
			&.prev {
				@include bg(28px,28px,'/images/webhome2/ico_arrow_right_28_black.png') {
					transform: rotate(-180deg);
				};
			}
			&.next {
				margin-left:12px;
				@include bg(28px,28px,'/images/webhome2/ico_arrow_right_28_black.png');
			}
			> span {
				@include hidden;
			}
		}
	}
}