@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";


/* 플래그 정의 */
.flag {
        position:absolute;
        top:16px;
        left:16px;
        padding-bottom:3px;
        background-color:$black;
        z-index:10;
        &:after {
            @include sudo_pos($right:-3px,$bottom:0px);
            @include arrow(right, 3px, $black);
            z-index:1;
        }
        span {
            position:relative;
            display:block;
            margin-left:3px;
            margin-right:-3px;
            padding:2px 8px 3px;
            background-color:$white;
            @include font(12px,700,$black,1.3);
            z-index:3;
        }

}
/* //플래그 정의 */