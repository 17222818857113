@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";


/* 레이어 팝업 정의 */
.lay_pop {
	display:none;
	width:714px;
	.lay_wrap {
		position:relative;
		// padding: 32px 40px 48px;
		padding: 38px 40px 48px; //230222_디자인변경사항 : 작은 파업 최소 높이 350px
		background-color: $white;
		.lay_tit {
			border-bottom:2px solid $black;
			h1 {
				padding-bottom:16px;
				span{
					// @include font(22px,700,$black,1.5);
					@include font(28px,700,$black,1.5);
				}
				
			}
		}
		.lay_conts{
			// max-height:750px;
			max-height:638px;
			overflow:auto;
			.alert_phrases {
				margin-top:24px;
				&.solo {
					margin-top:40px;
				}
				p {
					@include font(16px,400,$black,1.5);
					text-align:center;
					&.sub {
						@include font(16px,400,$gray01,1.5);
					}
				}
				strong {
					display:block;
					@include font(18px,700,$black,1.4);
					text-align:center;
				}
				dt {
					@include font(18px,700,$black,1.4);
				}
				dd {
					margin-top:8px;
					> p {
						text-align:left;
					}
				}
			}
		}
		&.alert {
			width:440px;
			padding-top:64px;
			.lay_tit {
				@include hidden;
			}
			.lay_conts{
				> div {
					text-align:center;
					p {
						padding:0 0 40px;
					}
				}
			}
		}
		.close {
			position:absolute;
			top:44px;
			right:40px;
			button{
				// @include bg(20px,20px,'/images/webhome2/ico_close_20_black.png');
				@include bg(32px,32px,'/images/webhome2/ico_close_32_black.png');
				span {
					@include hidden;
				}
			}
			
		}
	}
	/* size */
	&.small {
		width:472px;
	}
	&.large {
		width:956px;
		min-height:750px !important; // 임시 11/29
		.lay_wrap {
			.lay_tit {
				h1 {
					span {
						font-size:28px;
					}
				}
			}
			.close {
				button {
					width:32px;
					height:32px;
				}
			}
		}
	}
	&.xlarge {
		width:1110px;
		.lay_wrap {
			.close {
				button {
					width:32px;
					height:32px;
				}
			}
		}
	}
}

/* PGR 팝업 */
.lay_pop {
	.cmd_wrap {
		margin-top:24px;
		// text-align:center; //230217_디자인변경사항
		&.type02 {
			margin-top: 40px;
		}
		.title01 {
			@include font(18px,700,$black,1.4); //230217_디자인변경사항
		}
		.title_desc {
			margin-top:8px;
			@include font(16px,400,$gray01,1.5);
		}
		.bullet_list {
			margin-top: 8px;
		}
		.title_info {
			@include font(18px,700,$black,1.5);
			text-align: center;
		}
		.btn_wrap {
			margin-top:40px;
			font-size:0;
			display: flex;
			align-items: center;
			&.ty02 {
				margin-top: 56px;
			}
			.btn {
				padding: 0;
				box-sizing: border-box;
				width: calc(50% - 4px);
				height: 60px;
				text-align: center;
				> span {
					@include font(16px,700,$white,58px);
				}
				&.border {
					border: 2px solid $black;
					> span {
						@include font(16px,700,$black,58px);
					}
				}
				+ .btn {
					margin-left: 8px;
				}
			}
			&.solo {
				.btn {
					width:270px !important;
					margin:0 auto;
				}
			}
			//230217_디자인변경사항
			// .btn_rd {
			// 	display:inline-block;
			// 	min-width:154px;
			// 	margin:0 6px;
			// 	padding:15px 16px;
			// 	box-sizing:border-box;
			// 	border-radius:30px;
			// 	> span {
			// 		@include font(16px,700,$black,1.5);
			// 	}
			// 	&.border {
			// 		border:1px solid $black;
			// 	}
			// 	&.black {
			// 		background-color:$black;
			// 		> span {
			// 			color:$white;
			// 		}
			// 	}

			// }
		}
	}
}
/* //PGR 팝업 */

/* 윈도우팝업 */
.lay_pop {
	&.window {
		display:block;
		width:100%;
		.btn_wrap {
			margin-top:100px;
		}
	}
}
/* //레이어 팝업 정의 */