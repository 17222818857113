@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";

@font-face {
	font-family: 'Happiness-Sans';
	font-style: 'normal';
	font-weight: 400;

	/* 브라우저&디바이스 환경에 따른 font 파일 위치 정의 */
	//src: url('Happiness-Sans-Regular.eot'); /* IE9 Compat Modes */
	//src: url('Happiness-Sans-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ 
	src:local('Happiness-Sans'),
		url('/font/Happiness-Sans-Regular.woff2') format('woff2'), /*  Modern Browsers */ 
		url('/font/Happiness-Sans-Regular.woff') format('woff'), /* Modern Browsers */
		url('/font/Happiness-Sans-Regular.ttf') format('truetype'), /* Safari, Android, iOS */ 
		url('/font/Happiness-Sans-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Happiness-Sans';
	font-style: 'bold';
	font-weight: 700;

	/* 브라우저&디바이스 환경에 따른 font 파일 위치 정의 */
	//src: url('Happiness-Sans-Bold.eot'); /* IE9 Compat Modes */
	//src: url('Happiness-Sans-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ 
	src:local('Happiness-Sans'),
		url('/font/Happiness-Sans-Bold.woff2') format('woff2'), /*  Modern Browsers */ 
		url('/font/Happiness-Sans-Bold.woff') format('woff'), /* Modern Browsers */
		url('/font/Happiness-Sans-Bold.ttf') format('truetype'), /* Safari, Android, iOS */ 
		url('/font/Happiness-Sans-Bold.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Happiness-Sans-Title';
	font-style: 'bold';
	font-weight: 900;

	/* 브라우저&디바이스 환경에 따른 font 파일 위치 정의 */
	//src: url('Happiness-Sans-Regular.eot'); /* IE9 Compat Modes */
	//src: url('Happiness-Sans-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ 
	src:local('Happiness-Sans-Title'),  
		url('/font/Happiness-Sans-Title.woff2') format('woff2'), /*  Modern Browsers */ 
		url('/font/Happiness-Sans-Title.woff') format('woff'), /* Modern Browsers */
		url('/font/Happiness-Sans-Title.ttf') format('truetype'), /* Safari, Android, iOS */ 
		url('/font/Happiness-Sans-Title.svg#svgFontName') format('svg'); /* Legacy iOS */
}



/* 기본폰트정의 */
div,
p,
span,
dt,
dd,
li,
label {
	@include font(14px,400,$black,1.5);
}


.f22 {
	@include font(22px,400,$black,1.5);
}
.f18 {
	@include font(18px,400,$black,1.5);
}
.f16 {
	@include font(16px,400,$black,1.5);
}
.f12 {
	@include font(12px,400,$black,1.5);
}

.tit48 {
	@include font(48px,900,$black,1.3) {
		font-family: 'Happiness-Sans-Title';
	}
}
.tit40 {
	@include font(40px,900,$black,1.3) {
		font-family: 'Happiness-Sans-Title';
	}
}
.tit32 {
	@include font(32px,900,$black,1.3) {
		font-family: 'Happiness-Sans-Title';
	}
}
.tit28 {
	@include font(28px,900,$black,1.4) {
		font-family: 'Happiness-Sans-Title';
	}
}
/* //기본폰트정의 */