@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";

/* 버튼 정의 */
button {
	border-style: none;
	background-color: transparent;
	cursor: pointer;

	//달력버튼
	&.input_cal {
		display:inline-block;
		margin:11px 0 0 9px;
		margin-left:9px;
		@include bg(20px,20px,'/images/webhome2/ico_calendar_20.png');
		vertical-align:middle;
		> span {
			@include hidden;
		}
	}
}

a {
	text-decoration:none;
	&:hover {
		text-decoration:underline;
	}
}

.btn_wrap {
	&.row {
		button {
			& + button {
				margin-left:0;
				margin-top:8px;
			}
		}
	}
	&.solo {
		> button,
		> a {
			width:100% !important;
		}
	}
	button,
	a {
		& + button,
		& + a {
			// margin-left:8px;
			margin-left:5px; // 줄바꿈공백포함 8px
		}
		&:disabled {
			pointer-events: none;
			border:1px solid $gray04 !important;
			> span {
				color:$gray04 !important;
			}
		}
		&.lbtn{
			padding:13px 30px;
			&.border {
				border-radius: 4px;
				background-color:$white;
				border:1px solid $black;
				span {
					@include font(16px,700,$black,1.5);
				}
			}
		}
		&.btn {
			@include button($black,$white);
			&.black {
				background-color:$black;
				span {
					@include font(14px,700,$white,1.5);
				}
			}
			&.border {
				background-color:$white;
				border:1px solid $black;
				span {
					@include font(14px,700,$black,1.5);
				}
			}
			&.dis {
				@include button($gray04,$gray02);
			}
			// 상세검색
			&.detail { 
				&:after {
					@include sudo_dis(0,0,0,4px);
					@include bg(20px,20px,'/images/webhome2/ico_plus_20.png');
				}
			}
		}
		&.abtn {
			display:inline-block;
			padding:6px 18px;
			background-color:$white;
			border-radius: 16px;
			span{
				@include font(14px,700,$black,1.4);
				&.go:after {
					// @include sudo_ar14(2px,-6px,0,2px)
				}
			}
			&.black {
				background-color:$black;
				span {
					@include font(14px,700,$white,1.4);
				}        
			}
			&.border {
				border:1px solid $black;
			}
			
		}
		&.sbtn {
			display: inline-block;
			box-sizing: border-box;
			// min-width:91px; //카테고리별 변경가능
			height:34px;
			padding:7px 20px 7px;
			border:1px solid $black;
			background-color:$white;
			& + .sbtn {
				margin-left:2px;
				vertical-align: top;
			}
			> span {
				@include font(14px,700,$black,1.5);
			}
			&.black {
				background-color:$black;
				border:1px solid $black;
				span {
					@include font(14px,700,$white,1.4);
				}
			}
			// 아이콘들
			&.mail {
				&:before {
					@include sudo_dis(0,4px,0,0);
					@include bg(20px,20px,'/images/webhome2/ico_ms_20.png')
				}
			}
			&.receipt {
				&:before {
					@include sudo_dis(0,4px,0,0);
					@include bg(20px,20px,'/images/webhome2/ico_receipt_20.png')
				}
			}
			&.add {
				&:before {
					@include sudo_dis(0,4px,0,0);
					@include bg(20px,20px,'/images/webhome2/mypage/ico_plus_20.png')
				}
			}
			&.print {
				&:before {
					@include sudo_dis(0,4px,0,0);
					@include bg(20px,20px,'/images/webhome2/ico_print_20.png');
				}
			}
		}
		&.down {
			> span {
				&:after {
					@include sudo_dis(2px,0,0,0);
					@include bg(16px,16px,'/images/webhome2/ico_arrow_dwon_16_black.png');
				}
			}
		}
	}

	&.on {
		.btn {
			&.detail { 
				&:after {
					@include bg(20px,20px,'/images/webhome2/ico_minus_20.png');
				}
			}
		}
	}

	a {
		cursor: pointer;
		border-style: none;
		text-decoration:none;
		&.abtn {
			display:inline-block;
			padding:4px 18px;
			background-color:$white;
			border-radius: 14px;
			span{
				@include font(14px,700,$black,1.4);
				&.go:after {
					// @include sudo_ar14(2px,-6px,0,2px)
				}
			}
			&.black {
				background-color:$black;
				span {
					@include font(14px,700,$white,1.4);
				}
			}
			&.border {
				border:1px solid $black;
			}
		}
	}

	.linkbtn {
		display: inline-block;
		position: relative;
		width: 157px;
		padding: 10px 70px 9px 16px;
		box-sizing: border-box;
		background: $white;
		border-style: none;
		text-decoration:none;
		vertical-align: middle;
		cursor: pointer;
		> span {
			@include font(14px,700,$black,1.5);
		}
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 16px;
			transform: translateY(-50%);
			@include bg(24px,24px,'/images/webhome2/ico_btn_arrow_right_black.png');
		}
		&.outline {
			padding-top: 8px;
			padding-bottom: 7px;
			border: 2px solid $black;
		}
		&.black {
			background: $black;
			span {
				color: $white;
			}
			&::after {
				background-image: url('/images/webhome2/ico_btn_arrow_right_white.png');
			}
			&.outline {
				border-color: $white;
			}
		}
	}
	> * + * {
		margin-left: 8px;
	}
}

.button_area {
	font-size:0;
	text-align:center;
	.btn {
		display:inline-block;
		width:270px;
		height:60px;
		// margin:0 6px;
		margin:0 4px;
		box-sizing:border-box;
		@include font(16px,700,$black,1.4);
		line-height:60px;
		text-align:center;
		> span {
			@include font(16px,700,$black,1.4);
		}
		&.black {
			height:60px;
			border:2px solid $black;
			background-color:$black;
			> span {
				color:$white !important;
			}
		}
		&.border {
			border:2px solid $black;
		}
	}
}

.txt_link {
	text-decoration:none;
	@include font(16px,400,$black,1.3);
	&:hover {
		color:$green;
		border-bottom: solid $green;
	}
}
.t_under {
	text-decoration:underline;
}

.ico_link {
	text-decoration:none;
	.floor_info {
		@include font(22px,700,$black,1.3);
		&:before {
			// @include sudo_floor28(0,4px,0,0)
		}
	}
	
}

.arrow_link {
	display: inline-block;
	position: relative;
	padding-right: 16px;
	@include font(14px,700,$black,1.5);
	&:after {
		content: '';
		position: absolute;
		top: 2px;
		right: 0;
		@include bg(16px,16px,'/images/webhome2/ico_arrow_right_16_black.png')
	}
	span {
		@include font(inherit,inherit,inherit,inherit);
	}
}

.btn_border {
	display:inline-block;
	height:26px;
	padding:6px 18px 0;
	border-radius:30px;
	border:1px solid $black;
	@include font(14px,700,$black,1.5);
	cursor:pointer;
}

.more_list {
	display:block;
	padding:6px 20px;
	border:1px solid $black;
	> span {
		@include font(14px,700,$black,1.5);
		> strong {
			margin-left:4px;
			@include font(14px,700,$hgreen,1.5);
		}
	}
	&.sm {
		padding:0;
		border:none;
		&:after {
			@include sudo_dis(1px,0,0,0);
			@include bg(20px,20px,'/images/webhome2/ico_dropdown_20.png')
		}
	}
}


//레이어팝업 버튼
.lay_pop {
	.button_area {
		margin-top:40px;
		// margin-top:56px; 
		font-size:0;
		text-align:center;
		&.solo {
			> .btn {
				width:270px;
			}
		}
		&.full {
			> .btn {
				width:100%;
			}
		}
		&.half {
			> .btn {
				width: calc(50% - 4px);
			}
		}
		> .btn {
			display:inline-block;
			width:192px;
			margin:0 4px;
			&:first-child {
				margin-left:0;
			}
			&:last-child {
				margin-right:0;
			}
		}
		& + .table_wrap {
			margin-top:100px;
		}
	}
}
/* //버튼 정의 */