@charset "UTF-8";


/* 테이블(공통) */
.top_table_sec {
	> span {
		@include font(16px,400,$black,1.5);
		> strong {
			@include font(16px,700,$hgreen,1.5);
		}
	}
	& + .table_wrap {
		margin-top:20px !important;
	}
}
.table_wrap {
	&.col {
		border-top:1px solid $black;
		@include table() {
			th {
				padding:0 20px;
				background-color:#F0F5F4;
				@include font(14px,700 !important,$black,1.5);
				text-align:left;
				.rdo_wrap {
					label {
						line-height:22px;
						> span {
							font-size:14px;
						}
					}
				}
			}
			td {
				.rdo_wrap {
					label {
						line-height:24px;
						> span {
							font-size:14px;
							font-weight:400;
						}
					}
				}
				.no_result {
					width:100%;
					padding:20px 0;
					text-align:center;
					p {
						padding-top:0;
						@include font(14px,400,$gray02,1.5);
						&::before {
							content: none;
						}
					}
				}
				&.lec_branch {
					text-align:center;
					> a {
						text-decoration: underline;
						@include font(14px,700 !important,$black,1.5);
					}
				}
			}
		};
	}
	&.row {
		border-top:1px solid $black;
		@include table() {
			table {
				tbody {
					tr {
						&:last-child {
							> th {
								border-bottom:1px solid $gray05 !important;
							}
						}
						&:first-child {
							th {
								border-top: 0 none;
							}
						}
					}
					th {
						border-top:1px solid $white;
						border-left:1px solid $white !important;
						border-right:1px solid #F0F5F4;
						border-bottom:1px solid $white !important;
						padding:0 20px;
						background-color:#F0F5F4;
						@include font(14px,700 !important,$black,1.5);
						text-align:left;
						&.bottom {
							border-bottom:1px solid $gray05 !important;
						}
					}
					td {
						.add_file {
							@include font(14px,400,$black,1.5);
							text-decoration:underline;
						}
						.view {
							padding:20px 0;
							@include font(16px,400,$black,1.5);
						}
					}
				}
			}
		}
		//복합 th인 경우
		.multi_th {
			th[scope='row'] {
				padding-top:21px;
				vertical-align: top;
				border-bottom:1px solid $white;
				border-right: 0 !important; //230223_디자인 변경사항  : PCU1.1.1.2.1.5.1
				&.al_middle {
					vertical-align: middle;
					padding-top:0;
				}
				&:first-child {
					border-right: 1px solid $white !important; //230223_디자인 변경사항  : PCU1.1.1.2.1.5.1
				}
				 ~ th[scope='col'] { //230223_디자인 변경사항  : PCU1.1.1.2.1.5.1
					border-right: 0;
				 }
			}
			th[scope='col'] {
				// padding: 14px 0 15px;
				padding: 13px 0 14px;
			}
			td[colspan] {
				border-bottom:1px solid $white;
			}
		}
	}
	& + .table_wrap {
		margin-top:20px;
	}
	& + .paging_wrap {
		margin-top:32px;
	}
}
.table_wrap + .btm_btn_wrap {
	margin-top:24px;
	font-size:0;
	text-align:right;
	> .ubtn {
		margin-left:8px;
	}
}
.table_wrap + .bullet_list {
	margin-top:20px;
	> li + li {
		margin-top:8px;
	}
}
.table_wrap + .btn_wrap {
	&.top_table {
		position:absolute;
		top:0;
		right:0;
	}

}

//레이어팝업 테이블
.lay_pop {
	.lay_conts {
		.table_wrap {
			&.col {
				table {
					tbody{
						td {
							// padding:20px 12px;
							padding:20px;
						}
					}
				}
			}
		}
	}
}
/* //테이블(공통) */