@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";

.paging_wrap {
	text-align: center;
	.blind {
		@include hidden();
	}
	.paging_inner {
		display: inline-flex;
		position: relative;
		padding: 0 68px;
		
		[class^='ctrl_'] {
			overflow: hidden;
			position: absolute;
			top: 0;
			background-color: $white;
		}
		.ctrl_front {
			left: 0;
			@include bg(18px,18px,'/images/webhome2/ico_paging_front_18.png')
		}
		.ctrl_prev {
			left: 26px;
			@include bg(18px,18px,'/images/webhome2/ico_paging_prev_18.png')
		}
		.ctrl_next {
			right: 26px;
			@include bg(18px,18px,'/images/webhome2/ico_paging_next_18.png')
		}
		.ctrl_end {
			right: 0;
			@include bg(18px,18px,'/images/webhome2/ico_paging_end_18.png')
		}
		> span:not([class^='ctrl_']),
		> a:not([class^='ctrl_']) {
			display: inline-block;
			min-width: 18px;
			height: 18px;
			@include font(14px,400,$gray03,18px);
			text-align: center;
		}
		> span:not([class^='ctrl_']).on {
			color: $black;
			text-decoration: underline;
		}
		> *:not([class^='ctrl_']) + *:not([class^='ctrl_']) {
			margin-left: 8px;
		}
	}
	
}

/* 개발 요청 페이징 */
.paging {
	text-align: center;
	> a,
	> strong {
		display: inline-block;
		min-width: 18px;
		height: 18px;
		@include font(14px,400,$gray03,1.5);
		text-align: center;
	}
	> a {
		&.first,
		&.prev,
		&.next,
		&.last {
			background-color: $white;
		}
		&.prev {
			margin-right:22px;
			& + * {
				margin-left:0;
			}
		}
		&.next {
			margin-left:22px;
		}
		
	}
	> strong.on {
		color: $black;
		text-decoration: underline;
	}
	> * + * {
		margin-left: 5px;
	}
}
/* //개발 요청 페이징 */