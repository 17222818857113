@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";


/* 간격용 클래스 */
.mt32 {
	margin-top:32px;
}

/* 약관 */
.policy_wrap {
	& + .policy_wrap {
		margin-top:56px;
		padding-top:56px;
		border-top:1px solid $gray05;
	}
	.footer {
		> div {
			display:flex;
			justify-content: right;
			& + div {
				margin-top:40px;
			}
			> p {
				& + p {
					margin-top:0;
				}
				&:nth-child(2) {
					width:238px;
					text-align: right;
				}
				&:nth-child(3) {
					align-self: flex-end;
					padding-left:32px;
				}
			}
		}
		&.line {
			display:flex;
			justify-content: center;
			align-content: center;
			margin-bottom:40px;
			> span {
				&:first-child {
					width:17%;
				}
				width:10%;
				text-align: center;
			}
		}
	}
	h1 {
		margin-bottom:40px;
		text-align:center;
		@include font(18px,700,$black,1.5);
	}
	h2 {
		margin-top:40px;
		margin-bottom:16px;
		@include font(16px,700,$black,1.5);
	}

	ol {
		> li {
			& + li {
				margin-top:8px;
			}
			> ol {
				> li {
					margin-top:0;
				}
			}
		}
	}

	h3.title {
		&:not(:first-child) {
			margin-top:100px;
		}
	}
	h4 {
		margin-bottom:20px;
		@include font(18px,700,$black,1.5);
		&:not(:first-child) {
			margin-top:56px;
		}
	}
	h5 {
		margin-top:16px;
		margin-bottom:8px;
		@include font(16px,700,$black,1.5);
		&.fw_n {
			font-weight:normal;
		}
	}
	.tit {
		display:block;
		margin-top:16px;
		margin-bottom:20px;
		@include font(16px,400,$black,1.5);
	}
	p, li {
		@include font(16px,400,$black,1.4);
	}
	p + ol {
		padding:4px 0 0 22px;
	}
	p + ul {
		margin:12px 0;
	}
	p + p {
		margin-top:4px;
	}
	p + .btn_link {
		display:inline-block;
		margin-top:4px;
	}
	.solo_txt {
		&.foot {
			margin-top:40px;
		}
		&.date {
			margin-bottom:40px;
			text-align: center;
		}
		margin-bottom:40px;
		@include font(16px,400,$black,1.5);
	}
	.img_policy {
		margin-top:12px;
		& + .step_dl {
			margin-top:32px;
		}
	}
	.step_dl {
		dl {
			display:flex;
			dt {
				width:69px;
				@include font(16px,700,$black,1.5);
			}
			dd {
				@include font(16px,400,$black,1.5);
			}
			& + dl {
				margin-top:12px;
			}
		}
	}
	.solo {
		li {
			margin-top:0 !important;
		}
	}
	.tab_wrap {
		.tabs {
			li {
				margin-top:0;
			}
		}
	}
	li {
		&:not(:first-child) {
			margin-top:4px;
		}
		> ol,ul {
			padding:4px 0 0 22px;
			> li {
				margin-top:0 !important;
			}
		}
	}
	.bullet_list {
		margin-top:56px;
	}
	.txt_desc {
		margin-top:20px;
		& + .txt_desc {
			margin-top:12px;
			@include font(16px,400,$gray01,1.5);
		}
	}
	.cnt01 {
		margin-top:40px;
	}
	.cnt12 {
		margin-top:12px;
	}
	.cnt70 {
		margin-top:70px;
	}
	& + .btn_wrap {
		margin-top:56px;
		padding-top:24px;
		border-top:1px solid $gray05;
		text-align: right;
	}

	.focus_box {
		padding:26px;
		border:1px solid $gray05;
		> li + li {
			margin-top:12px;
		}
		label {
			padding-left:34px;
		}
	}

	strong {
		&.no_tit {
			display:block;
			margin-bottom:16px;
			text-align:center;
			@include font(16px,700,$black,1.5);
		}
	}
}
/* //약관 */

/* 약관2 */ //가이드가 위와 다름(PCA1.1.2.1.1.5.2.1T.html)
.policy_wrap01 {
	// padding-top:40px;
	padding-top:24px;
	.tit_div {
		& + .tit_div {
			margin-top:56px;
		}
		h2 {
			@include font(22px,700,$black,1.4);
			& + p {
				margin-top:16px;
				@include font(16px,400,$black,1.4);
			}
		}
		> ol {
			margin-top:40px;
			> li {
				@include font(18px,700,$black,1.4);
				> ol {
					margin-top:12px;
					> li {
						@include font(16px,400,$black,1.5);
					}
				}
				.input_wrap {
					margin-top:16px;
				}
				& + li {
					margin-top:48px;
				}
				> p {
					margin-top:8px;
					@include font(16px,400,$black,1.5);
				}
			}
		}
	}

	// 아코디언형식 약관
	&.accordian {
		.tit_div {
			h2.s_tit {
				@include font(18px,700,$black,1.4);
			}
			h3.s_tit {
				margin-top:48px;
				margin-bottom:12px;
				@include font(16px,400,$black,1.4);
			}
			h4.s_tit {
				margin-bottom:40px;
				@include font(16px,700,$black,1.4);
				&.solo {
					margin-top:48px;
					margin-bottom:16px;
				}
			}
			& + .tit_div {
				margin-top:64px;
			}
			& + .bullet_list {
				margin-top:48px;
			}
			.ui_accordion {
				> ul {
					> li + li {
						margin-top:64px;
						&.mt48 {
							margin-top:48px;
						}
					}
					
				}
				.head {
					> a {
						position:relative;
						width:100%;
						display:block;
						padding-bottom:12px;
						// border-bottom:1px solid $hgreen02;
						text-decoration: none;
						@include font(18px,700,$black,1.4);
						&:after {
							@include sudo_pos(0,0,0,initial);
							@include bg(20px,20px,'/images/webhome2/ico_accordion_arrow_20_active.png');
							transform:rotate(180deg);
						}
					}
				}
				.on {
					.head {
						> a {
							border:none;
							&:after {
								transform:rotate(0);
							}
						}
					}
				}
				.sub_rdo {
					.chk_wrap {
						margin-right:20px;
						> label {
							> span {
								@include font(16px,700,$black,1.5);
							}
						}
						&.sm {
							margin-right:16px;
							> label {
								> span {
									@include font(14px,400,$black,1.5);
								}
							}
						}
					}
				}
				.ui_accord_content {
					.d_terms {
						padding:28px;
						margin-top:12px;
						// margin-bottom:12px;
						margin-bottom:24px;
						border-top:1px solid $gray05;
						border-bottom:1px solid $gray05;
						background-color:$hgreen03;
						> p {
							@include font(14px,400,$black,1.5);
							& + p {
								margin-top:16px;
							}
						}
						.table_wrap {
							& + .bullet_list {
								margin-top:20px;
							}
						}
						.bullet_list {
							margin-top:8px;
							> li {
								color:$gray02;
							}
						}
						& + .input_wrap {
							margin-top:24px;
						}
						> dl {
							> dt {
								@include font(16px,700,$black,1.5);
							}
							> dd {
								margin-top:8px;
								@include font(16px,400,$black,1.5);
								> p {
									margin-bottom:20px;
								}
								> ol {
									> li + li {
										margin-top:20px;
									}
								}
							}
							& + dl {
								margin-top:40px;
							}
						}
					}
					// .sub_rdo {
					// 	.chk_wrap {
					// 		margin-right:20px;
					// 		> label {
					// 			> span {
					// 				@include font(16px,700,$black,1.5);
					// 			}
					// 		}
					// 		&.sm {
					// 			margin-right:16px;
					// 			> label {
					// 				> span {
					// 					@include font(14px,400,$black,1.5);
					// 				}
					// 			}
					// 		}
					// 	}
					// }
				}
			}
		}
	}

}
/* //약관2 */

//회원약관03 (s) - 다른_policy와 다름 (PCA1.1.4.8.html)
.wrap_policy03 {
  h6 {
    &.big01 {
      @include font(22px,700,$black,1.5);
      margin-bottom: 24px;
      margin-top: 64px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  p {
    &.tspace02 {
      @include font(18px,700,$black,1.5);
      margin-bottom: 16px;
      & + ul {
        margin-bottom: 24px;
        li {
          @include font(16px,400,$black,1.5);
          margin-top: 8px;
          &:first-child {
            margin-top: 0;
          }
          & > ul {
            margin-top: 4px;
            & > li {
              margin-top: 0;
              span {
                display: block;
                @include font(16px,400,$black,1.4);
                margin-top: 4px;
                & + span {
                  margin-bottom: 4px;
                  margin-top: 0;
                }
              }
            }
          }
          & > p {
            @include font(16px,400,$black,1.4);
            margin-top: 4px;
          }
        }
      }
      & + p {
        @include font(16px,400,$black,1.4);
        margin-bottom: 24px;
        & + ul {
          margin-top: 4px;
          & > li {
            @include font(16px,400,$black,1.5);
          }
          & + .tspace02 {
            margin-top: 24px;
          }
        }
      }
    }
  }
}
//회원약관03 (e)

// 약관04 상품권 이용 약관 (s) - PCA1.2.1.2.html
.wrap_policy04 {
  h4 {
    @include font(18px,700,$black,1.5);
    margin-top: 56px;
    & + p {
      margin-top: 20px;
    }
    & + ol {
      margin-top: 20px;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  p {
    @include font(16px,400,$black,1.5);
    & + ol {
      margin-left: 24px;
      margin-top: 4px;
      & > li {
        margin-top: 0;
      }
    }
  }
  ol {
    li {
      @include font(16px,400,$black,1.5);
      margin-top: 12px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  ul.bullet_list {
    margin-top: 12px;
  }
}
// 약관04 상품권 이용 약관 (e)


/* 카드신청 > 약관 (현행기준) */
.popup_terms {
	// margin-top:40px;
	margin-top:24px;
	p, ul, ol, li {
		& + .big01 {
			margin-top:48px;
		}
		& + .tspace02 {
			margin-top:28px;
		}
	}
	p {
		@include font(16px,400,$black,1.5);
		span {
			@include font(inherit,inherit,inherit,inherit);
		}
		& + ul,
		& + ol {
			margin-top:4px;
		}
		& + .privacy {
			margin-top: 16px;
		}
	}
	> ul,
	> ol {
		li {
			@include font(16px,400,$black,1.5);
			& + li {
				margin-top:8px;
			}
			> p {
				margin-top:4px;
				@include font(16px,400,$black,1.5);
			}
			> ul {
				margin-top:4px;
				> li {
					@include font(16px,400,$black,1.5);
				}
			}
		}
		& + p {
			margin-top: 8px;
		}
		& + .privacy {
			margin-top: 16px;
		}
	}
	a {
		@include font(inherit,inherit,inherit,inherit);
		&:hover {
			text-decoration: underline;
		}
	}

	.big01 {
		margin-bottom:40px;
		@include font(22px,700,$black,1.4);
	}
	.tspace02 {
		margin-bottom:16px;
		@include font(18px,700,$black,1.4);
		& + p {
			@include font(16px,400,$black,1.5);
		}
		& + ul {
			margin-top:4px;
			> li {
				@include font(16px,400,$black,1.5);
				& + li {
					margin-top:8px;
				}
				> p {
					margin-top:4px;
					@include font(16px,400,$black,1.5);
					
					&.sub_txt {
						margin-top:4px;
						@include font(14px,400,$gray01,1.5);
					}
				}
				> ul {
					margin-top:4px;
					> li {
						@include font(16px,400,$black,1.5);
					}
				}
			}
		}
	}
	.tspace03 {
		> li {
			@include font(16px,400,$black,1.5);
			> strong {
				@include font(18px,700,$black,1.4);
			}
			&.br {
				margin-top:40px;
			}
		}
		
	}
	.privacy + .tspace02 {
		margin-top: 28px;
	}
	.happypoint_terms {
		margin: 40px 0;
		padding: 20px 0;
		border-top: 1px solid $gray04;
		border-bottom: 1px solid $gray04;
		> ul {
			display: flex;
			flex-wrap: wrap;
			li {
				width: 50%;
				a {
					@include font(16px,400,$black,1.5);
				}
				&:nth-child(n+3) {
					margin-top: 8px;
				}
			}
		}
	}

	//추가분
	.sub_txt {
		margin-top:4px;
		@include font(14px,400,$gray01,1.5);
	}
	.bullet {
		position:relative;
		&.dot {
			margin-top:8px;
			padding-left:11px;
			&:before {
				width:4px;
				height:4px;
				background-color:$gray01;
				@include sudo_pos(9px,0,0,0);
				transform:rotate(45deg);
			}
		}
	}
	.underline {
		text-decoration: underline;
		@include font(inherit,inherit,inherit,inherit);
	}
}

/* 법인카드 > 약관 (현행기준) */
.wrap_policy {
	h4 {
		display:block;
		margin-top:64px;
		margin-bottom:24px;
		@include font(22px,700,$black,1.4);
	}
	h5 {
		display:block;
		margin-bottom:16px;
		@include font(18px,700,$black,1.4);
	}
	p,a {
		@include font(16px,400,$black,1.5);
	}
	> ol {
		> li {
			@include font(16px,400,$black,1.5);
			& + li {
				margin-top:8px;
			}
		}
		& + h5 {
			margin-top:24px;
		}
	}
}

/* 	회원 > 개인정보처리방침 > 개인정보취급(처리)방침L(현행기준) */
.wrap_policy {
	&.member {
		.br12 {
			display:block;
			height:12px;
		}
		h4 {
			display:block;
			margin-top:64px;
			margin-bottom:24px;
			@include font(22px,700,$black,1.4);
		}
		h5 {
			display:block;
			margin-bottom:16px;
			@include font(18px,700,$black,1.4);
		}
		p,a {
			@include font(16px,400,$black,1.5);
		}
		a {
			&:hover {
				text-decoration: underline;
			}
		}
		P {
			& + ul {
				margin-top:24px;
			}
			& + ol {
				margin-top:24px;
			}
		}
		> ol,
		> ul {
			margin-top:8px;
			@include font(16px,400,$black,1.5);
			> li {
				@include font(16px,400,$black,1.5);
				& + li {
					margin-top:8px;
				}
				> ol,
				> ul {
					margin-top:8px;
					@include font(16px,400,$black,1.5);
					> li {
						@include font(16px,400,$black,1.5);
						& + li {
							margin-top:4px;
						}
						> ol,
						> ul {
							margin-top:8px;
							@include font(16px,400,$black,1.5);
							> li {
								@include font(16px,400,$black,1.5);
								& + li {
									margin-top:4px;
								}
							}
						}
					}
				}
			}
			& + h5 {
				margin-top:24px;
			}
		}
		.bllist_type2 {
			margin-top:8px;
			> li {
				margin-top:4px;
				@include font(16px,400,$black,1.5);
			}
		}
		.bllist_type3 {
			margin-top:8px;
			margin-left:8px;
			> li {
				margin-top:4px;
				@include font(16px,400,$black,1.5);
			}
		}
		.policymenu {
			margin-top:48px;
			padding:24px;
			background-color:#F0F5F4;
			> ul {
				display:flex;
				justify-content:space-between;
				flex-wrap:wrap;
				margin-top:-12px;
				li {
					margin-top:12px;
					&:nth-child(odd) {
						flex:1 1 47%;
					}
					&:nth-child(even) {
						flex:1 1 53%;
					}
					a {
						@include font(16px,400,$black !important,1.4);
						text-decoration:underline;
					}
				}
			}
			& + p {
				margin-top:16px;
			}
		}
		.tb_type2 {
			border-top:1px solid $gray01;
			&.tspace02 {
				margin-top:24px;
			}
			&.bspace02 {
				margin-bottom:24px;
			}
			@include table {
				.fir {
					border-left:none;
				}
				a {
					@include font(14px,400,$black,1.5);
				}
				tr {
					> td:first-child {
						border-left:1px solid $white;
					}
				}
			};
		}
	}

	//그래프 및 아코디언 정의
	.paragraph {
		margin-top:32px;
		& + .paragraph {
			margin-top:48px;
		}
		> strong { //제목
			@include font(18px,700,$black,1.4);
			& + p {
				margin-top:16px;
			}
			> img {
				width:32px;
				height:32px;
				vertical-align: -9px;
			}
		}
		p {
			@include font(16px,400,$black,1.4);
			& + p {
				margin-top:12px;
			}
		}
		> p {
			margin-top:16px;
			br:first-child {
				@include sudo_dis(4px,0,0,0){
					display:block;
				};

			}
		}
		.label_table {
			width:100%;
			margin-top:48px;
			border-spacing:8px;
			th {
				padding-bottom:16px;
				text-align: left;
				@include font(16px,700,$black,1.4);
			}
			td {
				text-align: center;
				padding:20px;
				background-color: $hgreen03;
				img {
					display:inline-block;
					width:64px;
					margin-bottom:12px;
				}
				a {
					@include font(16px,400,$black,1.4);
				}
			}
			& + p {
				margin-top:12px;
				> strong {
					@include font(14px,400,$gray01,1.4);
				}
			}
		}
		.btn_type16 {
			&.tspace01 {
				margin-top:8px;
			}
			> a {
				@include font(14px,700,$hgreen,1.5);
			}
		}
	}
	.toggle_wrap {
		margin-top:8px;
		& + .paragraph {
			margin-top:48px;
		}
		@include table {
			table {
				position: relative;
				&:before {
					@include sudo_pos(0,0,0,0){
						width:100%;
						height:1px;
						background-color:$gray01
					};
				}
			}
			tr {
				> td:first-child {
					border-left:1px solid $white;
				}
			}
		};
		.depth1 {
			> li {
				width:100%;
				border-top:1px solid $gray05;
				border-bottom:1px solid $gray05;
				background-color: $gray06;
				&.on {
					.head {
						> a {
							&::after {
								transform: rotate(-180deg);
							}
						}
					}
				}
				.head {
					padding:12px 24px;
					> a {
						&::after {
							@include sudo_dis(4px,0,0,0);
							@include bg(16px,16px,'/images/webhome2/ico_arrow_dwon_16_black.png');
						}
					}
				}
				.ui_accord_content {
					padding:16px 24px 24px;
					background-color:$white;
					> ul,
					> ol {
						> li {
							@include font(16px,400,$black,1.5);
							& + li {
								margin-top:12px;
							}
							> p {
								margin-top:4px;
								@include font(16px,400,$black,1.5);
							}
							> ul,
							> ol {
								margin-top:4px;
								> li {
									@include font(16px,400,$black,1.5);
								}
							}
						}
					}
				}
			}
		}
		.depth2 {
			> li {}
		}
		& + p {
			margin-top:12px;
		}
	}
	.text_box {
		margin-top:16px;
		> ul {
			& + ul {
				margin-top:4px;
			}
			> li {
				@include font(16px,400,$black,1.4);
			}
		}
	}
}

