@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";

/* 리스트 내 결과 없음 */
ul {
	> li {
		.no_result {
			width:100%;
			padding:20px 0;
			text-align:center;
			p {
				@include font(14px,400,$gray02,1.5);
			}
		}
	}
}

/* 일반 리스트 내 정보 노출(마우스오버 시 노출) */
.list01 {
	> li {
		position:relative;
		display:inline-block;
		& + li {
			margin-left:9px;
		}
		> a {
			display:block;
			width:100%;
			height:100%;
			&::before {
				visibility:hidden;
				opacity: 0;
				@include sudo_pos(0,0,0,0);
				background: linear-gradient(180deg, rgba(0, 0, 0, 0) 8.13%, rgba(0, 0, 0, 0.5) 97.33%);
			}
			> img {
				width:100%;
			}
			> dl {
				visibility:hidden;
				position:absolute;
				bottom:24px;
				left:24px;
				> dt {
					@include font(16px,700,$white,1.5);
				}
				> dd {
					margin-top:4px;
					@include font(12px,400,$white,1.5);
				}
			}
			.txt {
				// visibility:hidden;
				position:absolute;
				bottom:24px;
				left:24px;
				@include font(16px,700,$white,1.5);
			}
			&:not(.ui_autoslider_elem):hover,
			&:not(.ui_autoslider_elem):focus {
				&::before,
				> dl,
				.txt {
					visibility:visible;
					opacity: 1;
					transition: all 0.1s ease-in-out;
				}
			}
		}
	}
}

/* 일반 리스트(마우스오버 시 딤노출) */
.list02 {
	> li {
		position:relative;
		display:inline-block;
		vertical-align: top;
		& + li {
			margin-left:9px;
		}
		> a {
			display:block;
			width:100%;
			height:100%;
			&::before {
				visibility:hidden;
				opacity: 0;
				height:351px;
				@include sudo_pos(0,0,0,0);
				background: linear-gradient(192.26deg, rgba(0, 0, 0, 0) 18.01%, rgba(0, 0, 0, 0.6) 92.31%);
			}
			&:hover,
			&:focus {
				&::before,
				> dl,
				.txt {
					visibility:visible;
					opacity: 1;
					transition: all 0.1s ease-in-out;
				}
			}
			> img {
				width:100%;
			}
			> dl {
				margin-top:24px;
				> dt {
					@include font(18px,700,$black,1.4);
					.txt_img {
						display:block;
						margin-top:-2px;
						// padding-bottom:2px;
						img {
							height:26.2px;
						}
					}
				}
				> dd {
					.txt {
						margin-top:8px;
						@include font(16px,400,$black,1.5);
					}
					.due {
						margin-top:12px;
						@include font(16px,400,$gray02,1.5);
					}
					
				}
			}
		}
	}
}

/* 매장안내 리스트 */
.shop_list {
	> ul {
		> li {
			position:relative;
			display:inline-block;
			width:472px;
			padding:22px 28px 24px;
			border:1px solid $gray05;
			box-sizing: border-box;
			& + li {
				margin-left:9px;
			}
			&:nth-child(n+4) {
				margin-top:12px;
			}
			&:nth-child(3n+1) {
				margin-left:0;
			}
			.branch_info {
				font-size: 0;
				.branch {
					@include font(14px,700,$hgreen,1.5);
				}
				.floor {
					@include font(14px,700,$gray02,1.5);
				}
				> span {
					display:inline-block;
					& + span {
						&::before {
							@include sudo_dis(5px,8px,0,8px);
							width:1px;
							height:10px;
							background-color: $gray04;
						}
					}
				}
			}
			.cate {
				margin-top:16px;
				@include font(16px,400,$black,1.5);
			}
			.title {
				margin-top:6px;
				@include ellipsis(2,52px);
				@include font(18px,700,$black,1.4);
			}
			.add_info {
				margin-top:8px;
				.seller {
					@include font(16px,400,$black,1.5);
				}
				.contact {
					@include font(16px,400,$gray02,1.5);
				}
				> span {
					display:inline-block;
					& + span {
						margin-left:8px;
					}
				}

			}
			.util_btns {
				position:absolute;
				right:23px;
				bottom:24px;
				.link_blank,
				.btn-brand-store {
					display:inline-block;
					vertical-align: top;
					margin-top:3px;
					@include bg(24px,24px,'/images/webhome2/ico_blank_link_24.png');
					> span {
						@include hidden;
					}
				}
				.map,
				.btn-brand-map {
					margin-left:10px;
					@include bg(24px,24px,'/images/webhome2/ico_pc_location_24_black.png');
					> span {
						@include hidden;
					}
				}
			}
		}
	}

}

/* 행사정보/문화센터/강좌 리스트 */
.list03 {
	> li {
		position:relative;
		display:inline-block;
		width:351px;
		& + li {
			margin-left:9px;
		}
		&:nth-child(n+5) {
			margin-top:48px;
		}
		&:nth-child(4n+1) {
			margin-left:0;
		}
		img {
			width:100%;
		}
		.branch_info {
			margin-top:24px;
			.branch {
				@include font(14px,700,$hgreen,1.5);
			}
			.state {
				@include font(14px,700,#ff5473,1.5);
				&.dis {
					color:$gray02;
				}
			}
			.etc {
				@include font(14px,700,$gray02,1.5);
			}
			> span {
				display:inline-block;
				& + span {
					&::before {
						@include sudo_dis(5px,8px,0,8px);
						width:1px;
						height:10px;
						background-color: $gray05;
					}
				}
			}
		}
		> a {
			text-decoration: none;
			> dl {
				margin-top:8px;
				> dt {
					@include ellipsis(2,52px);
					@include font(18px,700,$black,1.4);
				}
				> dd {
					margin-top:8px;
					.due {
						@include font(16px,400,$gray02,1.5);
					}
					.time {
						margin-left:12px;
						@include font(16px,400,$gray02,1.5);
					}
					&.class_info {
						.info {
							display:block;
							@include font(16px,400,$gray02,1.5);
							@include ellipsis(1,21px);
							> span {
								& + span {
									&::before {
										@include sudo_dis(8px,8px,0,5px);
										width:1px;
										height:10px;
										background-color: $gray05;
									}
								}
							}
						}
						.price {
							margin-top:8px;
							@include font(18px,700,$black,1.4);
							.won {
								margin-left:2px;
								vertical-align: 1px;
								@include font(16px,700,$black,1.5);
							}
						}
					}
				}
			}
		}
	}
}

/* 추천 상품 리스트 */
.list03 {
	&.prd_type {
		> li {
			margin-top:0;
			a {
				> dl {
					margin-top:24px;
					> dt {
						@include ellipsis(1,26px);
						@include font(18px,700,$black,1.4);
					}
					> dd {
						margin-top:4px;
						.desc {
							@include font(16px,400,$black,1.5);
							@include ellipsis(2,44px);
						}
						.price {
							margin-top:12px;
							@include font(22px,700,$hgreen,1.4);
							.won {
								@include font(20px,700,$hgreen,1.4);
							}
						}
						> del {
							text-decoration:line-through;
							&.price {
								margin-top:12px;
								@include font(18px,400,$gray02,1.5);
								.origin {
									@include hidden;
								}
								.won {
									@include font(18px,400,$gray02,1.5);
								}
							}
							& + .price {
								margin-top:0;
							}
						}
					}
				}
			}
		}
	}
}


/* 캐러셀용 */
.slide_wrap {
	.slide_conts {
		.inner_box {
			&.list01_box {
				position:relative;
				> a {
					display:block;
					width:100%;
					height:100%;
					&::before {
						// visibility:hidden;
						// opacity: 0;
						@include sudo_pos(0,0,0,0);
						background: linear-gradient(192.26deg, rgba(0, 0, 0, 0) 18.01%, rgba(0, 0, 0, 0.6) 92.31%);
					}
					> img {
						width:100%;
					}
					> dl {
						visibility:hidden;
						position:absolute;
						bottom:24px;
						left:24px;
						> dt {
							@include font(16px,700,$white,1.5);
						}
						> dd {
							margin-top:4px;
							@include font(12px,400,$white,1.5);
						}
					}
					.txt {
						// visibility:hidden;
						position:absolute;
						bottom:24px;
						left:24px;
						@include font(16px,700,$white,1.5);
					}
					// &:hover,
					// &:focus {
					// 	&::before,
					// 	> dl,
					// 	.txt {
					// 		visibility:visible;
					// 		opacity: 1;
					// 		transition: all 0.1s ease-in-out;
					// 	}
					// }
				}
			}
			&.list02_box {
				> a {
					display:block;
					width:100%;
					height:100%;
					> img {
						width:100%;
					}
					> dl {
						margin-top:24px;
						> dt {
							@include font(1rem,700,$black,1.4);
						}
						> dd {
							.txt {
								margin-top:8px;
								@include font(1rem,400,$black,1.5);
							}
							.due {
								margin-top:12px;
								@include font(0.875rem,400,$gray03,1.5);
							}
							
						}
					}
				}
			}
			&.list03_box {
				&.prd_type {
					a {
						text-decoration:none;
						img {
							width:100%;
						}
						> dl {
							margin-top:24px;
							> dt {
								@include ellipsis(1,26px);
								@include font(18px,700,$black,1.4);
							}
							> dd {
								margin-top:4px;
								.desc {
									@include font(16px,400,$black,1.5);
									@include ellipsis(2,44px);
								}
								.price {
									margin-top:12px;
									@include font(22px,700,$hgreen,1.4);
									.won {
										@include font(20px,700,$hgreen,1.4);
									}
								}
								> del {
									text-decoration:line-through;
									&.price {
										margin-top:12px;
										@include font(18px,400,$gray02,1.5);
										.origin {
											@include hidden;
										}
										.won {
											@include font(18px,400,$gray02,1.5);
										}
									}
									& + .price {
										margin-top:0;
									}
								}
							}
						}
					}
				}
			}
			
		}
	}
}