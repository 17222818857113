@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";

/* 기본 */
.ui-calendar-container {
	width: 288px;
	.ui-select-day {
		padding: 24px;
		box-shadow: 0px 4px 8px rgba(41, 51, 49, 0.3);
		.ui-calendar-header-second {
			position: relative;
			padding: 0 20px;
			text-align: center;
			.ui-calendar-now {
				@include font(18px,700,$black,1.5);
				em {
					font-style:normal;
					@include font(18px,700,$black,1.4);
				}
				span {
					vertical-align: top;
					@include font(inherit,inherit,inherit,inherit);
				}
				.ui-year-select {
					display:inline-block;
					margin-right:10px;
					margin-left: -15px;
					.ui-selectbox-wrap {
						display:inline-block;
						position:relative;
						& + em {
							margin-left:8px;
						}
						.ui-selectbox-view {
							.ui-select-button {
								position:relative;
								@include font(18px,700,$black,1.4);
								.hide {
									@include hidden;
								}
								.ico {
									position:absolute;
									top:2px;
									right:-4px;
									@include bg(20px,20px,'/images/webhome2/ico_dropdown_20.png');	
								}
							}
						}
						/* 230214 잔여업무리스트 16 수정 (공통수정사항) */
						.ui-selectbox-list {
							position:absolute;
							top:30px;
							left:0;
							width:79px;
							padding:12px 18px;
							background-color:$white;
							border: 1px solid $gray05;
							box-sizing: border-box;
							.ui-select-scrollarea {
								.ui-select-scrollarea-ul {
									li {
										a {
											@include font(14px,400,$black,1.5);
											text-decoration:none;
										}
										& + li {
											margin-top:8px;
										}
									}
								}
							}
						}
						&.on {
							.ico {
								transform: rotate(180deg);
							}
						}
						/* //230214 잔여업무리스트 16 수정 (공통수정사항) */
					}
				}
				> * {
					display: inline-block;
					vertical-align: middle;
				}
			}
			.ui-calendar-prev,
			.ui-calendar-next {
				position: absolute;
				top: 50%;
				margin-top: -10px;
				@include bg(20px,20px,'/images/webhome2/ico_arrow_right_20_black.png');
				font-size: 0;
				line-height: 0;
			}
			.ui-calendar-prev {
				left: 0;
				transform: rotate(180deg);
			}
			.ui-calendar-next {
				right: 0;
			}
		}
		.ui-calendar-date {
			margin: 10px -6px 0;
			table {
				width: 100%;
				table-layout: fixed;
				border-collapse: collapse;
				th, td {
					text-align: center;
				}
				th {
					padding: 5px 6px;
					@include font(12px,700,$gray02,1.4);
				}
				td {
					padding: 5px 6px;
					@include font(12px,400,$black,1.4);
					.ui-calendar-day,
					.ui-calendar-extend {
						display: inline-flex;
						justify-content: center;
						align-items: center;
						width: 24px;
						height: 24px;
					}
				}
				.ui-calendar-active {
					button.ui-calendar-day {
						background: $hgreen;
						color: $white;
					}
				}
				.ui-calendar-holiday {
					.ui-calendar-day {
						background: $black;
						color: $white;
					}
				}
				.ui-calendar-extend {
					.ui-calendar-day {
						background: $pink;
					}
				}
				.ui-calendar-today {
					.ui-calendar-day {
						background: $white;
						color: $black;
						border:1px solid $hgreen;
					}
					&.ui-calendar-holiday {
						.ui-calendar-day {
							background: $black;
							color: $white;
						}
					}
					&.ui-calendar-extend {
						.ui-calendar-day {
							background: $pink;
						}
					}
				}
			}
		}
	}
}

/* 지점안내 특화 */
.ui_store_calendar {
	.ui-calendar-container {
		width: 100%;
		.ui-select-day {
			padding-bottom: 10px;
			box-shadow: none;
		}
	}
}


/* 인풋 폼용 */
.calendar_wrap {
	font-size:0;
	& * {
		vertical-align:top;
	}
	.input_c {
		display:inline-block;
		.input_wrap {
			input {
				display:inline-block;
				width:180px;
				height:44px;
			}
			.input_cal {
				display:inline-block;
				margin:11px 0 0 9px;
				margin-left:9px;
				@include bg(20px,20px,'/images/webhome2/ico_calendar_20.png');
				vertical-align:middle;
				> span {
					@include hidden;
				}
			}
		}
	}
	.wave {
		display:inline-block;
		margin:0 6px 0 17px;
		@include font(16px,400,$black,1.5);
	}
	.sel_wrap {
		display:inline-block;
		margin-left:10px;
		.ui-selectbox-wrap {
			&.on {
				.ui-select-button {
					line-height:40px;
					.ui-select-text {
						margin:-2px 0 0 0;
					}
				}
			}
			.ui-select-button {
                padding:0 12px 0 16px;
				line-height:42px;
				.ico {
                    top:14px;
                    right:12px;
                }
                .ui-select-text {
                    font-size:14px;
                }
			}
            &.on {
                .ui-select-button {
                    line-height: 42px;
                }
                .ui-selectbox-list {
                    .ui-select-scrollarea-ul {
                        > li    {
                            padding:12px 16px;
                            > a {
                                font-size:14px;
                            }
                        }
                    }   
                }
            }
		}
		& + .input_c {
			margin-left:8px;
		}
	}
	.btn {
		margin-left:7px;
		&.black {
			height:44px;
			padding:11px 20px 12px;
			border-radius:4px;
			background-color:$black;
			line-height:22px;
			> span {
				display:inline-block;
				min-width:40px;
				@include font(14px,400,$white,1.5);
			}
		}
	}
}