@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";


/* 레이어 팝업 정의 */
.win_pop {
	width:100%;
	.win_wrap {
		position:relative;
		padding: 32px 40px 48px;
		background-color: $white;
		.win_tit {
			border-bottom:2px solid $black;
			h1 {
				padding-bottom:16px;
				span{
					// @include font(22px,700,$black,1.5);
					@include font(28px,700,$black,1.5);
				}
				
			}
		}
		.win_conts{
			// max-height:750px;
			// overflow:auto;
			.sup {
				margin-top:24px;
				@include font(16px,400,$gray01,1.4);
				& + .id_pw_find_section {
					margin-top:40px;
				}
			}
		}
		.close {
			position:absolute;
			top:37px;
			right:40px;
			button{
				// @include bg(20px,20px,'/images/webhome2/ico_close_20_black.png');
				@include bg(32px,32px,'/images/webhome2/ico_close_20_black.png');
				span {
					@include hidden;
				}
			}
			
		}
	}
}
/* //레이어 팝업 정의 */