@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";

/* 푸터 정의 */
footer {
	background-color: $black;
	height:538px;
	&.main {
		margin-top:0;
	}
	.footer_wrap {
		position:relative;
		display:flex;
		justify-content: center;
		width:1440px;
		margin:0 auto;
		padding-top:80px;
		&:after{ //화면밖 배경컬러 오류 수정
			@include sudo_pos(0,0,0,initial);
			width:100%;
			min-height:538px;
			background-color: $black;
			z-index: -1;
		}
		.logo {
			position:relative;
			width:363px;
			&:before {
				@include sudo_pos(0,0,0,0);
				@include bg(206px,24px,'/images/webhome2/logo_footer.png');
			}
			
			span {
				@include hidden;
			}
		}
		.conts {
			position:relative;
			width:1077px;
			> h2 {
				@include hidden;
			}
			.related_site {
				display:flex;
				justify-content: space-between;
				align-items: center;
				ul {
					li {
						display:inline-block;
						margin-left:56px;
						&:first-child {
							margin-left:0;
						}
						a {
							display: inline-block;
							@include font(16px,700,$white,1.5){
								text-decoration:none;
							};
							&:hover {
								text-decoration:underline;
							}
						}
					}
				}
				.family_site {
					position: relative;
					margin: -13px 0;
					> h2 {
						width: 160px;
						a {
							display: block;
							width: 100%;
							padding: 11px 48px 11px 16px;
							border: 1px solid transparent;
							box-sizing: border-box;
							background: $black;
							@include font(16px,700,$gray04,1.5);
							&::after {
								content: '';
								position: absolute;
								top: 50%;
								right: 16px;
								margin-top: -12px;
								@include bg(24px,24px,'/images/webhome2/ico_arrow_down_24_gray.png')
							}
						}
					}
					> ul {
						display: none;
						overflow-y: auto;
						position: absolute;
						top: calc(100% - 1px);
						left: 0;
						width: 100%;
						z-index: 10;
						max-height: 331px;
						padding: 6px 0;
						box-sizing: border-box;
						background-color: $black;
						li {
							display: block;
							margin: 0;
							a {
								display: block;
								padding: 6px 16px;
								@include font(12px,400,$gray04,1.4);
								&:hover {
									color: $white;
								}
							}
						}
					}
					&.on {
						> h2 a {
							border-color: $gray01;
							&::after {
								transform: rotate(180deg);
							}
						}
						> ul {
							display: block;
							border: 1px solid $gray01;
						}
					}
				}
			}
			.ref_link {
				display:flex;
				justify-content: flex-start;
				flex-shrink: initial;
				margin-top:48px;
				div {
					margin-right:80px;
					h2 {
						margin-bottom: 16px;
						@include font(16px,700,$gray03,1.5);
						a {
							color:$gray03;
						}
					}
					ul {
						li {
							a {
								display:inline-block;
								@include font(16px,400,$gray03,1.5){
									text-decoration:none;
								};
							}
							& + li {
								margin-top: 16px;
							}
						}
					}
					.bold {
						font-weight: 700;
					}
					.white {
						color: $white;
					}
					&.sub {
						ul {
							display:flex;
							justify-content: space-between;
							flex-wrap:wrap;
							width:224px;
							li {
								width:50%;
								margin-top: 0;
								a {
									@include font(14px,400,$gray03,24px);
								}
								&:nth-child(n+3) {
									margin-top: 16px;
								}
							}
						}
					}
				}
			}
			.site_info {
				margin-top:68px;
				h2 {
					@include hidden;
				}
				ul {
					display: flex;
					flex-wrap: wrap;
					width:760px; // 살짝 여유있게
					li {
						display:inline-block;
						@include font(14px,400,$gray03,1.5);
						// &:after {
						// 	@include sudo_dis(-1px,5px,0,5px){
						// 		content:'|';
						// 	};
						// }
						& + li {
							position: relative;
							margin-left: 8px;
							padding-left: 9px;
							&::after {
								content: '';
								position: absolute;
								top: 5px;
								left: 0;
								width: 1px;
								height: 10px;
								background: $gray02;
							}
							&.break {
								margin-left: 0;
								padding-left: 0;
								&::after {
									display: none;
								}
								&, & ~ li {
									margin-top: 6px;
								}
							}
						}
						a {
							@include font(inherit,inherit,inherit,inherit);
							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
				.copyright {
					margin-top:32px;
					@include font(14px,400,$gray03,1.5);
				}
			}
			.quality_certification {
				position:absolute;
				bottom:0;
				right:0;
				> h2 {
					@include hidden;
				}
				> ul {
					> li {
						display:inline-block;
						a {
							display: block;
							> img {
								height:40px;
							}
						}
						& + li {
							margin-left:12px;
						}
						&:last-child {
							margin-left:0;
						}
					}
				}
			}

			// 언어선택
			.sel_language {
				position: absolute;
				right: 0;
				bottom: 68px;
				width: 105px;
				> h2 {
					a {
						display: block;
						width: 100%;
						padding: 11px 36px 11px 14px;
						border: 1px solid transparent;
						box-sizing: border-box;
						background: $black;
						@include font(14px,400,$gray04,1.5);
						&::after {
							content: '';
							position: absolute;
							top: 50%;
							right: 16px;
							margin-top: -8px;
							@include bg(16px,16px,'/images/webhome2/ico_arrow_down_16_gray.png')
						}
					}
				}
				> ul {
					display: none;
					overflow-y: auto;
					position: absolute;
					top: calc(100% - 1px);
					left: 0;
					width: 100%;
					z-index: 10;
					max-height: 331px;
					padding: 6px 0;
					box-sizing: border-box;
					background-color: $black;
					li {
						display: block;
						margin: 0;
						a {
							display: block;
							padding: 6px 16px;
							@include font(12px,400,$gray04,1.4);
							&:hover {
								color: $white;
								text-decoration: underline;
							}
						}
					}
				}
				&.on {
					> h2 a {
						border-color: $gray01;
						&::after {
							transform: rotate(180deg);
						}
					}
					> ul {
						display: block;
						border: 1px solid $gray01;
					}
				}
			}
		}
	}
}
/* //푸터 정의 */