@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";


.floating {
	position:fixed;
	right:20px;
	bottom:20px;
	z-index:50;
	.chat {
		margin-bottom:12px;
		// a {
		// 	display:block;
		// 	width:72px;
		// 	height:72px;
		// 	@include bg(72px,72px,'/images/webhome2/ico_floating_chat_72.png');
		// 	span {
		// 		@include hidden;
		// 	}
			
		// }
	}
	.top {
		text-align:right;
		a {
			// display:block;
			display:inline-block;
			width:72px;
			height:72px;
			@include bg(72px,72px,'/images/webhome2/ico_top_72_black.png');
			span {
				@include hidden;
			}
		}
	}


// 현행 챗봇 스타일 정의
	.chat_message {
		width: 70px; border-radius: 35px;  overflow: hidden; height: 70px; transition: 0.3s; display: flex; flex-direction: row; align-items: center; background-color: #fff;cursor:pointer;
		&.active {
			width: 400px; 
			transition: width 0.3s;
		}
		a:hover {
			text-decoration: none;
		}
		.chat_open_wrap {
			position: relative; display: flex; flex-direction: row; align-items: center; padding-left: 80px;  background-color: #fff;
		}
		.chat_open_btn {
			width: 70px; height: 70px; background:url('https://www.ehyundai.com/images/webhome/outlets/layout/chatbot_off.png') no-repeat; background-size: 70px 70px; position: absolute; left: 0; top: 0; z-index: 1000;
		}
		.chat_close_btn {
			display: none; width:25px; height: 25px; background:url('https://www.ehyundai.com/images/webhome/outlets/layout/chatbot_close.png') no-repeat; background-size: 100%; font-size:0; margin-left: 30px; position: absolute; right: 0;
		}
		&.active {
			.chat_close_btn {
				display: block;
			}
		}
		.chat_btn_txt_wrap {
			width: 305px;
			.chat_btn_txt {
				display: none; 
				font-size: 18px; 
				color: #222;
				z-index: -2; 
				padding-right: 30px; 
				height: 70px; 
				line-height: 70px;
				&.on {
					display: inline-block!important; 
					height: 70px;
				}
			}
		}
	}
	
}