@charset "UTF-8";
* {
    padding: 0;
    margin: 0;
    list-style: none;
    font-family: "Happiness-Sans", "Noto Sans", Arial, dotum, "sans-serif";
    -webkit-text-size-adjust: none;
}

@font-face {
    font-family: "Happiness-Sans";
    font-style: "normal";
    font-weight: 400;
    /* 브라우저&디바이스 환경에 따른 font 파일 위치 정의 */
    src: local("Happiness-Sans"), url("/font/Happiness-Sans-Regular.woff2") format("woff2"), url("/font/Happiness-Sans-Regular.woff") format("woff"), url("/font/Happiness-Sans-Regular.ttf") format("truetype"), url("/font/Happiness-Sans-Regular.svg#svgFontName") format("svg"); /* Legacy iOS */
}
@font-face {
    font-family: "Happiness-Sans";
    font-style: "bold";
    font-weight: 700;
    /* 브라우저&디바이스 환경에 따른 font 파일 위치 정의 */
    src: local("Happiness-Sans"), url("/font/Happiness-Sans-Bold.woff2") format("woff2"), url("/font/Happiness-Sans-Bold.woff") format("woff"), url("/font/Happiness-Sans-Bold.ttf") format("truetype"), url("/font/Happiness-Sans-Bold.svg#svgFontName") format("svg"); /* Legacy iOS */
}
@font-face {
    font-family: "Happiness-Sans-Title";
    font-style: "bold";
    font-weight: 900;
    /* 브라우저&디바이스 환경에 따른 font 파일 위치 정의 */
    src: local("Happiness-Sans-Title"), url("/font/Happiness-Sans-Title.woff2") format("woff2"), url("/font/Happiness-Sans-Title.woff") format("woff"), url("/font/Happiness-Sans-Title.ttf") format("truetype"), url("/font/Happiness-Sans-Title.svg#svgFontName") format("svg"); /* Legacy iOS */
}
/* 기본폰트정의 */
div,
p,
span,
dt,
dd,
li,
label {
    font-size: 14px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}

.f22 {
    font-size: 22px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}

.f18 {
    font-size: 18px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}

.f16 {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}

.f12 {
    font-size: 12px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}

.tit48 {
    font-size: 48px;
    font-weight: 900;
    color: #171A19;
    line-height: 1.3;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    font-family: "Happiness-Sans-Title";
}

.tit40 {
    font-size: 40px;
    font-weight: 900;
    color: #171A19;
    line-height: 1.3;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    font-family: "Happiness-Sans-Title";
}

.tit32 {
    font-size: 32px;
    font-weight: 900;
    color: #171A19;
    line-height: 1.3;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    font-family: "Happiness-Sans-Title";
}

.tit28 {
    font-size: 28px;
    font-weight: 900;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    font-family: "Happiness-Sans-Title";
}

/* //기본폰트정의 */
/* 레이아웃 */
#contents {
    width: 100%;
    min-height: 500px;
    padding-top: 70px;
    padding-bottom: 160px;
}
#contents .conts .max {
    position: relative;
    max-width: 1920px;
    min-width: 1440px;
    margin: 0 auto;
}
#contents .conts .min {
    position: relative;
    width: 1440px;
    margin: 0 auto;
}
#contents .conts .no_side {
    width: 1198px;
    margin: 70px auto 0;
}
#contents .conts .no_side .view_wrap {
    padding-bottom: 160px;
}

/* //레이아웃 */
/* 서브 레이아웃 */
.conts > .side {
    position: relative;
    width: 1440px;
    margin: 0 auto;
}
.conts > .side::after {
    content: "";
    display: block;
    clear: both;
}
.conts .side_conts {
    float: left;
    width: 1110px;
    min-height: 200px;
}
.conts .aside {
    float: right;
    width: 190px;
    padding-top: 0;
}
.conts .aside .stit {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.conts .aside .stit + h2 {
    margin-top: 4px;
}
.conts .aside .stit + h2.no_link a {
    pointer-events: none;
}
.conts .aside .stit + h2.no_link a > span:last-child::after {
    content: none;
}
.conts .aside .stit + h2 a > span {
    display: inline-block;
    position: relative;
    padding-right: 32px;
    font-size: 24px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    letter-spacing: -1.5px;
}
.conts .aside .stit + h2 a > span:last-child::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(-11%);
    width: 28px;
    height: 28px;
    background-image: url("/images/webhome2/ico_dropdown_40.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.conts .aside > h2 {
    padding-bottom: 12px;
    font-size: 24px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    letter-spacing: -1.5px;
    border-bottom: 2px solid #171A19;
}
.conts .aside a {
    display: inline-block;
    transform: rotate(0.05deg);
    position: relative;
}
.conts .aside a * {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
}
.conts .aside a[target=_blank]::after {
    content: "";
    display: inline-block;
    margin-top: -1px;
    margin-left: -4px;
    width: 24px;
    height: 24px;
    background-image: url("/images/webhome2/ico_pc_link_24_gray.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-size: 24px auto;
    vertical-align: middle;
}
.conts .aside .nav {
    margin-top: 20px;
}
.conts .aside .nav .depth1 > li + li {
    margin-top: 16px;
}
.conts .aside .nav .depth1 > li > .head a,
.conts .aside .nav .depth1 > li > a {
    font-size: 16px;
    font-weight: 700;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.conts .aside .nav .depth1 > li.on > .head a,
.conts .aside .nav .depth1 > li.on > a,
.conts .aside .nav .depth1 > li.current > .head a,
.conts .aside .nav .depth1 > li.current > a {
    color: #171A19;
}
.conts .aside .nav .depth2 {
    margin-top: 16px;
    padding-left: 12px;
    padding-bottom: 16px;
    border-bottom: 1px solid #EAEBEA;
}
.conts .aside .nav .depth2 > li + li {
    margin-top: 10px;
}
.conts .aside .nav .depth2 > li {
    position: relative;
}
.conts .aside .nav .depth2 > li > .head a,
.conts .aside .nav .depth2 > li > a {
    font-size: 14px;
    font-weight: 700;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    transform: rotate(0.05deg);
}
.conts .aside .nav .depth2 > li.on > .head a,
.conts .aside .nav .depth2 > li.on > a,
.conts .aside .nav .depth2 > li.current > .head a,
.conts .aside .nav .depth2 > li.current > a {
    color: #171A19;
}
.conts .aside .nav .depth2 > li.current::after {
    content: "";
    position: absolute;
    top: 6px;
    left: -11px;
    border-width: 3px;
    border-style: solid;
    border-color: #171A19 #171A19 transparent transparent;
}
.conts .aside .nav .depth3 {
    margin-top: 10px;
    padding-left: 8px;
}
.conts .aside .nav .depth3 > li + li {
    margin-top: 4px;
}
.conts .aside .nav .depth3 > li > .head a,
.conts .aside .nav .depth3 > li > a {
    font-size: 12px;
    font-weight: 700;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.conts .aside .nav .depth3 > li.on > .head a,
.conts .aside .nav .depth3 > li.on > a,
.conts .aside .nav .depth3 > li.current > .head a,
.conts .aside .nav .depth3 > li.current > a {
    font-weight: 700;
    color: #171A19;
    text-decoration: underline;
}
.conts .aside .nav .out_link {
    display: inline-block;
    position: relative;
    padding-right: 18px;
}
.conts .aside .nav .out_link::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -2px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-image: url("/images/webhome2/ico_pc_link_16_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.conts .aside nav.lnb {
    margin-top: 20px;
}
.conts .aside nav.lnb > ul > li > a {
    font-size: 16px;
    font-weight: 700;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.conts .aside nav.lnb > ul > li.on > a {
    color: #171A19;
}
.conts .aside nav.lnb > ul > li + li {
    margin-top: 16px;
}

/* //서브 레이아웃 */
/* 윈도우팝업 */
#contents.win_pop {
    min-height: auto;
    padding: 0;
}

/* 카드신청 서브 레이아웃(카드 다른데서 오류나면 수정필요) */
.card .container .min {
    display: flex;
    justify-content: space-between;
}
.card .container .min .card_sub {
    width: 1110px;
}
.card .container .min .aside {
    position: static;
}

/* no data */
.no_result {
    padding: 120px 0;
    border: none;
}
.no_result p {
    position: relative;
    padding-top: 100px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.no_result p:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 80px;
    height: 80px;
    background-image: url("/images/webhome2/ico_nodata_80.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: translateX(-50%);
}
.no_result p.no_ico {
    padding: 64px 0;
    font-size: 18px;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.no_result p.no_ico:before {
    content: none;
}
.no_result.brd {
    border-top: 1px solid #171A19;
    border-bottom: 1px solid #EAEBEA;
}

.board_wrap .board_list {
    border-top: 1px solid #171A19;
    border-bottom: 1px solid #EAEBEA;
}
.board_wrap .board_list .board_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #EAEBEA;
}
.board_wrap .board_list .board_head .sup {
    padding-right: 20px;
    font-size: 22px;
    font-weight: 700;
    color: #00815A;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.board_wrap .board_list .board_head .tit {
    font-size: 22px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.board_wrap .board_list .board_head .date {
    margin-left: auto;
    padding-top: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.board_wrap .board_list .board_head .info > dl {
    display: inline-block;
}
.board_wrap .board_list .board_head .info > dl > dt {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.board_wrap .board_list .board_head .info > dl > dd {
    display: inline-block;
    margin-left: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.board_wrap .board_list .board_head .info > dl + dl {
    margin-left: 20px;
}
.board_wrap .board_list .board_body {
    padding: 64px 0;
}
.board_wrap .board_list + .btn_wrap {
    margin-top: 24px;
    text-align: right;
}

/* 테이블(공통) */
.top_table_sec > span {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.top_table_sec > span > strong {
    font-size: 16px;
    font-weight: 700;
    color: #00815A;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.top_table_sec + .table_wrap {
    margin-top: 20px !important;
}

.table_wrap.col {
    border-top: 1px solid #171A19;
}
.table_wrap.col table {
    width: 100%;
    border-collapse: collapse;
}
.table_wrap.col table thead th {
    padding: 14px 0;
    background-color: #F0F5F4;
    border-left: 1px solid #FFFFFF;
    text-align: center;
}
.table_wrap.col table thead th:first-child {
    border-left: none;
}
.table_wrap.col table thead th.bd_l {
    border-left: 1px solid #FFFFFF;
}
.table_wrap.col table thead th.bd_t {
    border-top: 1px solid #FFFFFF;
}
.table_wrap.col table thead th.bd_b {
    border-bottom: 1px solid #FFFFFF;
}
.table_wrap.col table tbody th {
    border-bottom: 1px solid #eaebea;
    border-left: 1px solid #eaebea;
    font-weight: normal;
}
.table_wrap.col table tbody th.first {
    border-left: none !important;
}
.table_wrap.col table tbody th.al_center {
    text-align: center;
}
.table_wrap.col table tbody th.al_right {
    text-align: right;
}
.table_wrap.col table tbody td {
    padding: 20px;
    border-bottom: 1px solid #eaebea;
    border-left: 1px solid #eaebea;
    background-color: #FFFFFF;
    /* 데이터 없을 경우 */
}
.table_wrap.col table tbody td.first {
    border-left: none;
}
.table_wrap.col table tbody td.al_center {
    text-align: center;
}
.table_wrap.col table tbody td.al_center .bullet_list {
    display: inline-block;
}
.table_wrap.col table tbody td.al_right {
    text-align: right;
}
.table_wrap.col table tbody td .fc_red {
    color: #FF383B;
}
.table_wrap.col table tbody td .fc_khaki {
    color: #00805A;
}
.table_wrap.col table tbody td .fc_gray02 {
    color: #727573;
}
.table_wrap.col table tbody td .no_result {
    padding: 20px 0;
    text-align: center;
}
.table_wrap.col table tbody td .no_result p {
    font-size: 14px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.table_wrap.col table tfoot td {
    padding: 20px;
    border-bottom: 1px solid #eaebea;
    border-left: 1px solid #eaebea;
    background-color: #FFFFFF;
}
.table_wrap.col table tfoot td.first {
    border-left: none;
}
.table_wrap.col table tfoot td.al_center {
    text-align: center;
}
.table_wrap.col table tfoot td.al_center .bullet_list {
    display: inline-block;
}
.table_wrap.col table tfoot .al_right {
    text-align: right;
}
.table_wrap.col th {
    padding: 0 20px;
    background-color: #F0F5F4;
    font-size: 14px;
    font-weight: 700 !important;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    text-align: left;
}
.table_wrap.col th .rdo_wrap label {
    line-height: 22px;
}
.table_wrap.col th .rdo_wrap label > span {
    font-size: 14px;
}
.table_wrap.col td .rdo_wrap label {
    line-height: 24px;
}
.table_wrap.col td .rdo_wrap label > span {
    font-size: 14px;
    font-weight: 400;
}
.table_wrap.col td .no_result {
    width: 100%;
    padding: 20px 0;
    text-align: center;
}
.table_wrap.col td .no_result p {
    padding-top: 0;
    font-size: 14px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.table_wrap.col td .no_result p::before {
    content: none;
}
.table_wrap.col td.lec_branch {
    text-align: center;
}
.table_wrap.col td.lec_branch > a {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 700 !important;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.table_wrap.row {
    border-top: 1px solid #171A19;
}
.table_wrap.row table {
    width: 100%;
    border-collapse: collapse;
}
.table_wrap.row table thead th {
    padding: 14px 0;
    background-color: #F0F5F4;
    border-left: 1px solid #FFFFFF;
    text-align: center;
}
.table_wrap.row table thead th:first-child {
    border-left: none;
}
.table_wrap.row table thead th.bd_l {
    border-left: 1px solid #FFFFFF;
}
.table_wrap.row table thead th.bd_t {
    border-top: 1px solid #FFFFFF;
}
.table_wrap.row table thead th.bd_b {
    border-bottom: 1px solid #FFFFFF;
}
.table_wrap.row table tbody th {
    border-bottom: 1px solid #eaebea;
    border-left: 1px solid #eaebea;
    font-weight: normal;
}
.table_wrap.row table tbody th.first {
    border-left: none !important;
}
.table_wrap.row table tbody th.al_center {
    text-align: center;
}
.table_wrap.row table tbody th.al_right {
    text-align: right;
}
.table_wrap.row table tbody td {
    padding: 20px;
    border-bottom: 1px solid #eaebea;
    border-left: 1px solid #eaebea;
    background-color: #FFFFFF;
    /* 데이터 없을 경우 */
}
.table_wrap.row table tbody td.first {
    border-left: none;
}
.table_wrap.row table tbody td.al_center {
    text-align: center;
}
.table_wrap.row table tbody td.al_center .bullet_list {
    display: inline-block;
}
.table_wrap.row table tbody td.al_right {
    text-align: right;
}
.table_wrap.row table tbody td .fc_red {
    color: #FF383B;
}
.table_wrap.row table tbody td .fc_khaki {
    color: #00805A;
}
.table_wrap.row table tbody td .fc_gray02 {
    color: #727573;
}
.table_wrap.row table tbody td .no_result {
    padding: 20px 0;
    text-align: center;
}
.table_wrap.row table tbody td .no_result p {
    font-size: 14px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.table_wrap.row table tfoot td {
    padding: 20px;
    border-bottom: 1px solid #eaebea;
    border-left: 1px solid #eaebea;
    background-color: #FFFFFF;
}
.table_wrap.row table tfoot td.first {
    border-left: none;
}
.table_wrap.row table tfoot td.al_center {
    text-align: center;
}
.table_wrap.row table tfoot td.al_center .bullet_list {
    display: inline-block;
}
.table_wrap.row table tfoot .al_right {
    text-align: right;
}
.table_wrap.row table tbody tr:last-child > th {
    border-bottom: 1px solid #EAEBEA !important;
}
.table_wrap.row table tbody tr:first-child th {
    border-top: 0 none;
}
.table_wrap.row table tbody th {
    border-top: 1px solid #FFFFFF;
    border-left: 1px solid #FFFFFF !important;
    border-right: 1px solid #F0F5F4;
    border-bottom: 1px solid #FFFFFF !important;
    padding: 0 20px;
    background-color: #F0F5F4;
    font-size: 14px;
    font-weight: 700 !important;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    text-align: left;
}
.table_wrap.row table tbody th.bottom {
    border-bottom: 1px solid #EAEBEA !important;
}
.table_wrap.row table tbody td .add_file {
    font-size: 14px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    text-decoration: underline;
}
.table_wrap.row table tbody td .view {
    padding: 20px 0;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.table_wrap.row .multi_th th[scope=row] {
    padding-top: 21px;
    vertical-align: top;
    border-bottom: 1px solid #FFFFFF;
    border-right: 0 !important;
}
.table_wrap.row .multi_th th[scope=row].al_middle {
    vertical-align: middle;
    padding-top: 0;
}
.table_wrap.row .multi_th th[scope=row]:first-child {
    border-right: 1px solid #FFFFFF !important;
}
.table_wrap.row .multi_th th[scope=row] ~ th[scope=col] {
    border-right: 0;
}
.table_wrap.row .multi_th th[scope=col] {
    padding: 13px 0 14px;
}
.table_wrap.row .multi_th td[colspan] {
    border-bottom: 1px solid #FFFFFF;
}
.table_wrap + .table_wrap {
    margin-top: 20px;
}
.table_wrap + .paging_wrap {
    margin-top: 32px;
}

.table_wrap + .btm_btn_wrap {
    margin-top: 24px;
    font-size: 0;
    text-align: right;
}
.table_wrap + .btm_btn_wrap > .ubtn {
    margin-left: 8px;
}

.table_wrap + .bullet_list {
    margin-top: 20px;
}
.table_wrap + .bullet_list > li + li {
    margin-top: 8px;
}

.table_wrap + .btn_wrap.top_table {
    position: absolute;
    top: 0;
    right: 0;
}

.lay_pop .lay_conts .table_wrap.col table tbody td {
    padding: 20px;
}

/* //테이블(공통) */
/* 버튼 정의 */
button {
    border-style: none;
    background-color: transparent;
    cursor: pointer;
}
button.input_cal {
    display: inline-block;
    margin: 11px 0 0 9px;
    margin-left: 9px;
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/ico_calendar_20.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    vertical-align: middle;
}
button.input_cal > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}

a {
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}

.btn_wrap.row button + button {
    margin-left: 0;
    margin-top: 8px;
}
.btn_wrap.solo > button,
.btn_wrap.solo > a {
    width: 100% !important;
}
.btn_wrap button + button, .btn_wrap button + a,
.btn_wrap a + button,
.btn_wrap a + a {
    margin-left: 5px;
}
.btn_wrap button:disabled,
.btn_wrap a:disabled {
    pointer-events: none;
    border: 1px solid #D4D6D4 !important;
}
.btn_wrap button:disabled > span,
.btn_wrap a:disabled > span {
    color: #D4D6D4 !important;
}
.btn_wrap button.lbtn,
.btn_wrap a.lbtn {
    padding: 13px 30px;
}
.btn_wrap button.lbtn.border,
.btn_wrap a.lbtn.border {
    border-radius: 4px;
    background-color: #FFFFFF;
    border: 1px solid #171A19;
}
.btn_wrap button.lbtn.border span,
.btn_wrap a.lbtn.border span {
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.btn_wrap button.btn,
.btn_wrap a.btn {
    display: inline-block;
    padding: 12px 30px;
    background-color: #171A19;
}
.btn_wrap button.btn span,
.btn_wrap a.btn span {
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.btn_wrap button.btn.black,
.btn_wrap a.btn.black {
    background-color: #171A19;
}
.btn_wrap button.btn.black span,
.btn_wrap a.btn.black span {
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.btn_wrap button.btn.border,
.btn_wrap a.btn.border {
    background-color: #FFFFFF;
    border: 1px solid #171A19;
}
.btn_wrap button.btn.border span,
.btn_wrap a.btn.border span {
    font-size: 14px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.btn_wrap button.btn.dis,
.btn_wrap a.btn.dis {
    display: inline-block;
    padding: 12px 30px;
    background-color: #D4D6D4;
}
.btn_wrap button.btn.dis span,
.btn_wrap a.btn.dis span {
    font-size: 14px;
    font-weight: 700;
    color: #727573;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.btn_wrap button.btn.detail:after,
.btn_wrap a.btn.detail:after {
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 4px;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/ico_plus_20.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.btn_wrap button.abtn,
.btn_wrap a.abtn {
    display: inline-block;
    padding: 6px 18px;
    background-color: #FFFFFF;
    border-radius: 16px;
}
.btn_wrap button.abtn span,
.btn_wrap a.abtn span {
    font-size: 14px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.btn_wrap button.abtn.black,
.btn_wrap a.abtn.black {
    background-color: #171A19;
}
.btn_wrap button.abtn.black span,
.btn_wrap a.abtn.black span {
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.btn_wrap button.abtn.border,
.btn_wrap a.abtn.border {
    border: 1px solid #171A19;
}
.btn_wrap button.sbtn,
.btn_wrap a.sbtn {
    display: inline-block;
    box-sizing: border-box;
    height: 34px;
    padding: 7px 20px 7px;
    border: 1px solid #171A19;
    background-color: #FFFFFF;
}
.btn_wrap button.sbtn + .sbtn,
.btn_wrap a.sbtn + .sbtn {
    margin-left: 2px;
    vertical-align: top;
}
.btn_wrap button.sbtn > span,
.btn_wrap a.sbtn > span {
    font-size: 14px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.btn_wrap button.sbtn.black,
.btn_wrap a.sbtn.black {
    background-color: #171A19;
    border: 1px solid #171A19;
}
.btn_wrap button.sbtn.black span,
.btn_wrap a.sbtn.black span {
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.btn_wrap button.sbtn.mail:before,
.btn_wrap a.sbtn.mail:before {
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-right: 4px;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/ico_ms_20.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.btn_wrap button.sbtn.receipt:before,
.btn_wrap a.sbtn.receipt:before {
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-right: 4px;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/ico_receipt_20.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.btn_wrap button.sbtn.add:before,
.btn_wrap a.sbtn.add:before {
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-right: 4px;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/mypage/ico_plus_20.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.btn_wrap button.sbtn.print:before,
.btn_wrap a.sbtn.print:before {
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-right: 4px;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/ico_print_20.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.btn_wrap button.down > span:after,
.btn_wrap a.down > span:after {
    content: "";
    display: inline-block;
    margin-top: 2px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
    width: 16px;
    height: 16px;
    background-image: url("/images/webhome2/ico_arrow_dwon_16_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.btn_wrap.on .btn.detail:after {
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/ico_minus_20.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.btn_wrap a {
    cursor: pointer;
    border-style: none;
    text-decoration: none;
}
.btn_wrap a.abtn {
    display: inline-block;
    padding: 4px 18px;
    background-color: #FFFFFF;
    border-radius: 14px;
}
.btn_wrap a.abtn span {
    font-size: 14px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.btn_wrap a.abtn.black {
    background-color: #171A19;
}
.btn_wrap a.abtn.black span {
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.btn_wrap a.abtn.border {
    border: 1px solid #171A19;
}
.btn_wrap .linkbtn {
    display: inline-block;
    position: relative;
    width: 157px;
    padding: 10px 70px 9px 16px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-style: none;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
}
.btn_wrap .linkbtn > span {
    font-size: 14px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.btn_wrap .linkbtn::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background-image: url("/images/webhome2/ico_btn_arrow_right_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.btn_wrap .linkbtn.outline {
    padding-top: 8px;
    padding-bottom: 7px;
    border: 2px solid #171A19;
}
.btn_wrap .linkbtn.black {
    background: #171A19;
}
.btn_wrap .linkbtn.black span {
    color: #FFFFFF;
}
.btn_wrap .linkbtn.black::after {
    background-image: url("/images/webhome2/ico_btn_arrow_right_white.png");
}
.btn_wrap .linkbtn.black.outline {
    border-color: #FFFFFF;
}
.btn_wrap > * + * {
    margin-left: 8px;
}

.button_area {
    font-size: 0;
    text-align: center;
}
.button_area .btn {
    display: inline-block;
    width: 270px;
    height: 60px;
    margin: 0 4px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    line-height: 60px;
    text-align: center;
}
.button_area .btn > span {
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.button_area .btn.black {
    height: 60px;
    border: 2px solid #171A19;
    background-color: #171A19;
}
.button_area .btn.black > span {
    color: #FFFFFF !important;
}
.button_area .btn.border {
    border: 2px solid #171A19;
}

.txt_link {
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.3;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.txt_link:hover {
    color: #00815A;
    border-bottom: solid #00815A;
}

.t_under {
    text-decoration: underline;
}

.ico_link {
    text-decoration: none;
}
.ico_link .floor_info {
    font-size: 22px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.3;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.arrow_link {
    display: inline-block;
    position: relative;
    padding-right: 16px;
    font-size: 14px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.arrow_link:after {
    content: "";
    position: absolute;
    top: 2px;
    right: 0;
    width: 16px;
    height: 16px;
    background-image: url("/images/webhome2/ico_arrow_right_16_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.arrow_link span {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    line-height: inherit;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}

.btn_border {
    display: inline-block;
    height: 26px;
    padding: 6px 18px 0;
    border-radius: 30px;
    border: 1px solid #171A19;
    font-size: 14px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    cursor: pointer;
}

.more_list {
    display: block;
    padding: 6px 20px;
    border: 1px solid #171A19;
}
.more_list > span {
    font-size: 14px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.more_list > span > strong {
    margin-left: 4px;
    font-size: 14px;
    font-weight: 700;
    color: #00815A;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.more_list.sm {
    padding: 0;
    border: none;
}
.more_list.sm:after {
    content: "";
    display: inline-block;
    margin-top: 1px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/ico_dropdown_20.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.lay_pop .button_area {
    margin-top: 40px;
    font-size: 0;
    text-align: center;
}
.lay_pop .button_area.solo > .btn {
    width: 270px;
}
.lay_pop .button_area.full > .btn {
    width: 100%;
}
.lay_pop .button_area.half > .btn {
    width: calc(50% - 4px);
}
.lay_pop .button_area > .btn {
    display: inline-block;
    width: 192px;
    margin: 0 4px;
}
.lay_pop .button_area > .btn:first-child {
    margin-left: 0;
}
.lay_pop .button_area > .btn:last-child {
    margin-right: 0;
}
.lay_pop .button_area + .table_wrap {
    margin-top: 100px;
}

/* //버튼 정의 */
/* 탭 정의 */
.tab_wrap {
    /* case PLO1.6.1T ~ PLO1.6.2T */
}
.tab_wrap .tabs ul {
    display: flex;
    justify-content: flex-start;
}
.tab_wrap .tabs ul li {
    margin-left: 32px;
}
.tab_wrap .tabs ul li:nth-child(1) {
    margin-left: 0;
}
.tab_wrap .tabs ul li a {
    position: relative;
    text-decoration: none;
    font-size: 24px;
    font-weight: 700;
    color: #9B9E9C;
    line-height: 1.3;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.tab_wrap .tabs ul li.on a {
    color: #171A19;
}
.tab_wrap .tabs ul li.on a::after {
    border-width: 4px;
    border-style: solid;
    border-color: #171A19 transparent transparent #171A19;
    content: "";
    position: absolute;
    top: 4px;
    left: initial;
    bottom: 0;
    right: -12px;
    width: 0;
    height: 0;
}
.tab_wrap .tabs ul li.on a > em {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.tab_wrap .tabs ul li a > span {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.tab_wrap .tabs ul li a:after {
    content: none;
}
.tab_wrap .tabs ul li a .hide {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.tab_wrap .tabs ul li.on a > span {
    font-size: 14px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.tab_wrap .tabs ul li.on a > span:after {
    border-width: 3px;
    border-style: solid;
    border-color: #171A19 transparent transparent #171A19;
    content: "";
    position: absolute;
    top: 4px;
    left: initial;
    bottom: 0;
    right: -12px;
    width: 0;
    height: 0;
}
.lay_pop .tab_wrap .tabs {
    padding: 40px 0 32px;
}

.tab_wrap.type_box .tabs ul {
    border: 1px solid #D4D6D4;
}
.tab_wrap.type_box .tabs ul li {
    flex: 1 0 0;
    position: relative;
    z-index: 0;
    min-height: 48px;
    margin-left: 0;
    box-sizing: border-box;
    background: #FFFFFF;
}
.tab_wrap.type_box .tabs ul li a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.tab_wrap.type_box .tabs ul li + li {
    border-left: 1px solid #D4D6D4;
}
.tab_wrap.type_box .tabs ul li.on {
    z-index: 1;
}
.tab_wrap.type_box .tabs ul li.on::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 2px solid #171A19;
    box-sizing: border-box;
}
.tab_wrap.type_box .tabs ul li.on a {
    position: relative;
    font-weight: 700;
    color: #171A19;
}
.tab_wrap.type_box .tabs ul li.on a::after {
    content: "";
    content: none;
    display: inline-block;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    margin-top: -8px;
    margin-left: 2px;
    border-width: 3px;
    border-style: solid;
    border-color: #171A19 transparent transparent #171A19;
}
.tab_wrap.type_box .tabs ul li.on a > span {
    font-size: 14px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.tab_wrap.type_box .tabs ul li.on a > span:after {
    border-width: 3px;
    border-style: solid;
    border-color: #171A19 transparent transparent #171A19;
    content: "";
    position: absolute;
    top: 3px;
    left: initial;
    bottom: 0;
    right: -8px;
    width: 0;
    height: 0;
}
.tab_wrap.type_box .tabs ul.col7 {
    flex-wrap: wrap;
}
.tab_wrap.type_box .tabs ul.col7 > li {
    flex: 0 1 auto;
    width: 14.2857142857%;
}
.tab_wrap.type_box .tabs ul.col7 > li:nth-child(n+8) {
    border-top: 1px solid #D4D6D4;
}
.tab_wrap.type_box .tabs ul.col7 > li:nth-child(7n+1) {
    border-left: 0 none;
}
.tab_wrap.type_box .tabs ul.col7 > li.on a::after {
    margin-right: -8px;
}

/* //탭 정의 */
/* 폼 */
.input, .input_wrap {
    position: relative;
}
.input input[type=text],
.input input[type=password],
.input input[type=number],
.input input[type=button],
.input input[type=email], .input_wrap input[type=text],
.input_wrap input[type=password],
.input_wrap input[type=number],
.input_wrap input[type=button],
.input_wrap input[type=email] {
    width: 100%;
    height: 52px;
    padding: 0 24px;
    border-radius: 4px;
    border: 1px solid #EAEBEA;
    box-sizing: border-box;
    outline-color: #171A19;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.input input[type=text]:active,
.input input[type=password]:active,
.input input[type=number]:active,
.input input[type=button]:active,
.input input[type=email]:active, .input_wrap input[type=text]:active,
.input_wrap input[type=password]:active,
.input_wrap input[type=number]:active,
.input_wrap input[type=button]:active,
.input_wrap input[type=email]:active {
    border-color: #EAEBEA;
}
.input input[type=text].btn,
.input input[type=password].btn,
.input input[type=number].btn,
.input input[type=button].btn,
.input input[type=email].btn, .input_wrap input[type=text].btn,
.input_wrap input[type=password].btn,
.input_wrap input[type=number].btn,
.input_wrap input[type=button].btn,
.input_wrap input[type=email].btn {
    padding-right: 126px;
}
.input input[type=text]:read-only,
.input input[type=password]:read-only,
.input input[type=number]:read-only,
.input input[type=button]:read-only,
.input input[type=email]:read-only, .input_wrap input[type=text]:read-only,
.input_wrap input[type=password]:read-only,
.input_wrap input[type=number]:read-only,
.input_wrap input[type=button]:read-only,
.input_wrap input[type=email]:read-only {
    background-color: #F7f7f7;
    border-color: #EAEBEA;
}
.input input[type=text]:disabled,
.input input[type=password]:disabled,
.input input[type=number]:disabled,
.input input[type=button]:disabled,
.input input[type=email]:disabled, .input_wrap input[type=text]:disabled,
.input_wrap input[type=password]:disabled,
.input_wrap input[type=number]:disabled,
.input_wrap input[type=button]:disabled,
.input_wrap input[type=email]:disabled {
    background-color: #F7f7f7;
    border-color: #EAEBEA;
    color: #D4D6D4;
}
.input input[type=text]::placeholder,
.input input[type=password]::placeholder,
.input input[type=number]::placeholder,
.input input[type=button]::placeholder,
.input input[type=email]::placeholder, .input_wrap input[type=text]::placeholder,
.input_wrap input[type=password]::placeholder,
.input_wrap input[type=number]::placeholder,
.input_wrap input[type=button]::placeholder,
.input_wrap input[type=email]::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.input input[type=text]:focus, .input input[type=text]:active,
.input input[type=password]:focus,
.input input[type=password]:active,
.input input[type=number]:focus,
.input input[type=number]:active,
.input input[type=button]:focus,
.input input[type=button]:active,
.input input[type=email]:focus,
.input input[type=email]:active, .input_wrap input[type=text]:focus, .input_wrap input[type=text]:active,
.input_wrap input[type=password]:focus,
.input_wrap input[type=password]:active,
.input_wrap input[type=number]:focus,
.input_wrap input[type=number]:active,
.input_wrap input[type=button]:focus,
.input_wrap input[type=button]:active,
.input_wrap input[type=email]:focus,
.input_wrap input[type=email]:active {
    border: 2px solid #00815A;
}
.input .btn_wrap, .input_wrap .btn_wrap {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}
.input + .input_hint, .input_wrap + .input_hint {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #727573;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}

.input_wrap {
    display: flex;
}
.input_wrap + .bar_type {
    margin-top: 20px;
}
.input_wrap.input_type {
    padding-right: 154px;
}
.input_wrap.bar_type > div {
    display: inline-flex;
    align-self: center;
}
.input_wrap.bar_type > div:before {
    content: " ";
    position: absolute;
    top: 50%;
    left: -16px;
    width: 6px;
    height: 1px;
    background: #171A19;
}
.input_wrap.bar_type > div:first-child:before {
    display: none;
}
.input_wrap.bar_type > div.txt {
    margin-left: 12px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.input_wrap.bar_type > div.txt:before {
    content: none;
}
.input_wrap.bar_type > div.input input {
    padding: 0 24px;
}
.input_wrap.date_type > div {
    margin-left: 0;
}
.input_wrap.date_type > span {
    display: inline-block;
    margin: 12px 0 0 8px;
    padding-right: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.input_wrap.date_type > span + div {
    margin-left: 8px;
}
.input_wrap.date_type > span + div.btn_wrap {
    margin-left: 16px;
}
.input_wrap.date_type > span + .rdo_wrap {
    margin-left: 42px;
}
.input_wrap.date_type > span.tit_span {
    margin-left: 16px;
}
.input_wrap.date_type > span.bar {
    margin-left: 16px;
}
.input_wrap.date_type > span.bar + div {
    margin-left: 16px;
}
.input_wrap.date_type > span + button {
    margin-left: 12px;
}
.input_wrap.date_type .rdo_wrap {
    margin-top: 13px;
}
.input_wrap.date_type + .bar {
    display: block;
    width: 714px;
    height: 24px;
    text-align: center;
}
.input_wrap.mix_type > div {
    margin-left: 12px;
}
.input_wrap.mix_type > span {
    display: inline-block;
    margin: 12px 0 0 8px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.input_wrap.mix_type > span + div {
    margin-left: 8px;
}
.input_wrap.mail_type > div {
    width: calc(33.3333333333% - 16px);
    margin-left: 32px;
}
.input_wrap.mail_type > div:nth-child(2) {
    width: 231px;
    margin-right: -26px;
}
.input_wrap.mail_type > div:nth-child(2):before {
    content: "@";
    position: absolute;
    top: 50%;
    left: -22px;
    transform: translateY(-50%);
}
.input_wrap.cal_type .input {
    position: relative;
}
.input_wrap.cal_type .input > button {
    position: absolute;
    top: 16px;
    right: 20px;
    margin-top: 0;
}
.input_wrap.cal_type .input input:read-only {
    background-color: #FFFFFF;
}
.input_wrap.normal_type > div {
    margin-left: 8px;
}
.input_wrap.card_choice .rdo_wrap + .rdo_wrap {
    margin-left: 8px;
}
.input_wrap.card_choice .rdo_wrap input:checked + label {
    border-color: #171A19;
}
.input_wrap.card_choice label {
    display: block;
    text-align: center;
    width: 169px;
    border: 1px solid #EAEBEA;
    border-radius: 4px;
    padding: 8px 0 16px;
    cursor: pointer;
}
.input_wrap.card_choice label > span {
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.input_wrap.card_choice label::before {
    display: block;
    position: static;
    margin: 0 auto;
}
.input_wrap.card_choice label.card01::before {
    width: 155px;
    height: 92px;
    background-image: url("/images/webhome2/card/img_check_card01.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.input_wrap.card_choice label.card02::before {
    width: 155px;
    height: 92px;
    background-image: url("/images/webhome2/card/img_check_card02.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.input_wrap.card_choice label.card03::before {
    width: 155px;
    height: 92px;
    background-image: url("/images/webhome2/card/img_check_card03.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.input_wrap.card_choice label.card04::before {
    width: 155px;
    height: 92px;
    background-image: url("/images/webhome2/card/img_check_card04.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.input_wrap > div {
    position: relative;
    margin-left: 25px;
}
.input_wrap > div:first-child {
    margin-left: 0;
}

.password_wrap {
    display: inline-flex;
    justify-content: flex-start;
    align-self: center;
    margin-left: 12px !important;
}
.password_wrap .masked {
    position: relative;
}
.password_wrap .masked::before {
    width: 12px;
    height: 12px;
    border-radius: 16px;
    background-color: #171A19;
    content: "";
    display: inline-block;
    margin-top: 4px;
    margin-right: 8px;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
}
.password_wrap .masked::after {
    width: 12px;
    height: 12px;
    border-radius: 16px;
    background-color: #171A19;
    content: "";
    display: inline-block;
    margin-top: 4px;
    margin-right: 12px;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
}
.password_wrap + div {
    align-self: center;
    margin-left: 12px;
}

.caution_wrap {
    width: 100%;
    margin-top: 12px;
}
.caution_wrap .text {
    color: #FF383B;
}
.caution_wrap .btn_wrap {
    float: right;
    margin-top: 12px;
}

.rdo_wrap {
    display: inline-block;
    position: relative;
}
.rdo_wrap.error label {
    padding-left: 32px;
}
.rdo_wrap.error label > span {
    font-size: 16px;
    font-weight: 400;
    color: #EB3912;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.rdo_wrap input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.rdo_wrap input:disabled + label:before {
    width: 24px;
    height: 24px;
    background-image: url("/images/webhome2/ico_radio_normal_24.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.rdo_wrap input:disabled + label > span {
    color: #D4D6D4;
}
.rdo_wrap input:disabled:checked + label:before {
    width: 24px;
    height: 24px;
    background-image: url("/images/webhome2/ico_radio_disable_24.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.rdo_wrap input:disabled:checked + label > span {
    color: #D4D6D4;
}
.rdo_wrap input + label:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background-image: url("/images/webhome2/ico_radio_normal_24.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.rdo_wrap input:focus + label {
    outline: 2px solid #00815A;
}
.rdo_wrap input:checked + label:before {
    width: 24px;
    height: 24px;
    background-image: url("/images/webhome2/ico_radio_active_24.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.rdo_wrap label {
    padding-left: 32px;
}
.rdo_wrap label > span {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.rdo_wrap.solo label {
    padding: 1px 0 4px 23px;
}
.rdo_wrap.solo label > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.rdo_wrap.sm input + label:before {
    width: 24px;
    height: 24px;
    background-image: url("/images/webhome2/ico_check_s_normal_24.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.rdo_wrap.sm input + label > span {
    color: #727573;
}
.rdo_wrap.sm input:checked + label:before {
    width: 24px;
    height: 24px;
    background-image: url("/images/webhome2/ico_check_s_active_24.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.rdo_wrap.sm input:checked + label > span {
    color: #171A19;
}
.rdo_wrap.type_txt {
    margin-top: 0 !important;
}
.rdo_wrap.type_txt input + label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding: 10px 0;
    border: 1px solid #EAEBEA;
    cursor: pointer;
    z-index: 1;
}
.rdo_wrap.type_txt input + label:before {
    content: none;
}
.rdo_wrap.type_txt input + label > span {
    font-size: 14px;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.rdo_wrap.type_txt input:checked + label {
    position: relative;
    z-index: 5;
    border: 1px solid #171A19;
}
.rdo_wrap.type_txt input:checked + label > span {
    font-size: 14px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.rdo_wrap.type_txt + .type_txt {
    margin-left: -1px !important;
}

.chk_wrap {
    display: inline-block;
    position: relative;
}
.chk_wrap.error label {
    padding-left: 32px;
}
.chk_wrap.error label > span {
    font-size: 16px;
    font-weight: 400;
    color: #EB3912;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.chk_wrap input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.chk_wrap input:disabled + label:before {
    width: 24px;
    height: 24px;
    background-image: url("/images/webhome2/ico_check_disable_24.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.chk_wrap input + label:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background-image: url("/images/webhome2/ico_check_normal_24.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.chk_wrap input:focus + label {
    outline: 1px solid #171A19;
}
.chk_wrap input:checked + label:before {
    width: 24px;
    height: 24px;
    background-image: url("/images/webhome2/ico_check_active_24.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.chk_wrap label {
    padding-left: 32px;
}
.chk_wrap label > span {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.chk_wrap.solo label {
    padding: 1px 0 4px 23px;
}
.chk_wrap.solo label > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.chk_wrap.chk_s label {
    padding-left: 28px;
}
.chk_wrap.chk_s input + label:before {
    top: 2px;
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/ico_check_normal_24.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.chk_wrap.chk_s + .chk_s {
    margin-left: 40px;
}
.chk_wrap.sm input + label:before {
    width: 24px;
    height: 24px;
    background-image: url("/images/webhome2/ico_check_s_normal_24.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.chk_wrap.sm input + label > span {
    font-size: 16px;
    font-weight: 400;
    color: #727573;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.chk_wrap.sm input:checked + label:before,
.chk_wrap.sm input:focus + label:before {
    width: 24px;
    height: 24px;
    background-image: url("/images/webhome2/ico_check_s_active_24.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.chk_wrap.sm input:checked + label > span,
.chk_wrap.sm input:focus + label > span {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}

.sel_wrap .ui-selectbox-wrap {
    position: relative;
    width: 100% !important;
}
.sel_wrap .ui-selectbox-wrap.on .ui-select-button {
    padding: 0 20px 0 24px;
    line-height: 50px;
    border-left: 1px solid #171A19;
    border-right: 1px solid #171A19;
    border-top: 1px solid #171A19;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.sel_wrap .ui-selectbox-wrap.on .ico {
    top: 10px;
    transform: rotate(180deg);
}
.sel_wrap .ui-selectbox-wrap .ui-select-button {
    display: block;
    padding: 0 20px 0 24px;
    border-radius: 4px;
    border: 1px solid #eaebea;
    background-color: #FFFFFF;
    line-height: 50px;
}
.sel_wrap .ui-selectbox-wrap .ui-select-button.placeholder .ui-select-text {
    font-size: 16px;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    vertical-align: middle;
}
.sel_wrap .ui-selectbox-wrap .ui-select-button .ui-select-text {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    vertical-align: middle;
}
.sel_wrap .ui-selectbox-wrap .ui-select-button .ui-select-essen {
    font-size: 16px;
    font-weight: 400;
    color: #D4D6D4;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.sel_wrap .ui-selectbox-wrap .ui-select-button .hide {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.sel_wrap .ui-selectbox-wrap .ui-select-button .ico {
    position: absolute;
    top: 16px;
    right: 20px;
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/ico_dropdown_20.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.sel_wrap .ui-selectbox-wrap .ui-selectbox-list {
    position: absolute;
    z-index: 1;
    width: 100%;
    max-height: 155px;
    overflow: auto;
    padding: 0;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-left: 1px solid #171A19;
    border-right: 1px solid #171A19;
    border-bottom: 1px solid #171A19;
    border-radius: 0 0 4px 4px;
}
.sel_wrap .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea-ul > li {
    padding: 12px 24px;
}
.sel_wrap .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea-ul > li > a {
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    text-decoration: none;
}
.sel_wrap .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea-ul > li.on, .sel_wrap .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea-ul > li:hover, .sel_wrap .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea-ul > li:focus {
    background-color: #F7FAF9;
}
.sel_wrap .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea-ul > li.on > a, .sel_wrap .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea-ul > li:hover > a, .sel_wrap .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea-ul > li:focus > a {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.sel_wrap .ui-selectbox-wrap.up.on .ui-select-button {
    border-left: 1px solid #171A19;
    border-right: 1px solid #171A19;
    border-bottom: 1px solid #171A19;
    border-top: 1px solid #EAEBEA;
    border-radius: 0 0 4px 4px;
}
.sel_wrap .ui-selectbox-wrap.up .ui-selectbox-list {
    border-left: 1px solid #171A19;
    border-right: 1px solid #171A19;
    border-top: 1px solid #171A19;
    border-bottom: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}
.sel_wrap.small .ui-selectbox-wrap .ui-select-button {
    padding: 0 12px 0 16px;
    height: 42px;
    line-height: 40px;
}
.sel_wrap.small .ui-selectbox-wrap .ui-select-button .ico {
    top: 14px;
    right: 12px;
}
.sel_wrap.small .ui-selectbox-wrap .ui-select-button .ui-select-text {
    font-size: 14px;
}
.sel_wrap.small .ui-selectbox-wrap.on .ui-select-button {
    line-height: 40px;
}
.sel_wrap.small .ui-selectbox-wrap.on .ui-selectbox-list .ui-select-scrollarea-ul > li {
    padding: 12px 16px;
}
.sel_wrap.small .ui-selectbox-wrap.on .ui-selectbox-list .ui-select-scrollarea-ul > li > a {
    font-size: 14px;
}

.form_row.input_type {
    padding-right: 154px;
}
.form_row .ico_required {
    display: inline-block;
    width: 5px;
    height: 5px;
    margin: 4px 2px 0 2px;
    border-radius: 50%;
    background-color: #00815A;
    vertical-align: top;
}
.form_row .ico_required .blind {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.form_row > dl {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}
.form_row > dl + dl {
    margin-top: 24px;
}
.form_row > dl + dl.chk_top {
    margin-top: 32px;
}
.form_row > dl > dt {
    width: 202px;
    flex: 0 0 auto;
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.form_row > dl > dt.top_line {
    display: inline-flex;
    align-self: flex-start;
    padding-top: 14px;
    vertical-align: top;
}
.form_row > dl > dt.top_line.multi {
    padding-top: 2px;
}
.form_row > dl > dt.rdo_line {
    display: inline-flex;
    align-self: flex-start;
    padding-top: 2px;
    vertical-align: top;
}
.form_row > dl > dd {
    flex: 1 1 auto;
    font-size: 0;
}
.form_row > dl > dd .rdo_wrap {
    margin-bottom: 8px;
}
.form_row > dl > dd .rdo_wrap + .rdo_wrap {
    margin-left: 34px;
}
.form_row > dl > dd .bullet_list {
    margin-top: 20px;
}

.textarea textarea {
    width: 100%;
    padding: 16px 24px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #EAEBEA;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    vertical-align: top;
    resize: none;
}
.textarea textarea::placeholder {
    color: #9B9E9C;
}

.file_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.file_box .upload_name {
    display: inline-block;
    width: 420px;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.file_box label {
    display: inline-block;
    width: 125px;
    height: 52px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #434545;
    margin-left: 8px;
    font-size: 16px;
    font-weight: 700;
    color: #434545;
    line-height: 52px;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    cursor: pointer;
}
.file_box input[type=file] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
}
.file_box input[name=pfile] {
    border: 0;
}

.row_wrap {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: left;
}
.row_wrap .down_file {
    display: inline-block;
    max-width: 420px;
    word-break: break-all;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.row_wrap .delete {
    min-width: 108px;
    display: inline-block;
    box-sizing: border-box;
    height: 34px;
    margin-left: 24px;
    padding: 7px 20px 7px;
    border: 1px solid #171A19;
    background-color: #FFFFFF;
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}

.star_rangking {
    font-size: 0;
}
.star_rangking .point {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/ico_star_rating.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-size: cover;
    background-position: left;
}
.star_rangking .point > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.star_rangking .point.p01 {
    width: 20px;
}
.star_rangking .point.p02 {
    width: 40px;
}
.star_rangking .point.p03 {
    width: 60px;
}
.star_rangking .point.p04 {
    width: 80px;
}
.star_rangking .point.p05 {
    width: 100px;
}

.sel_wrap .ui-selectbox-wrap.star {
    width: 211px !important;
}
.sel_wrap .ui-selectbox-wrap.star .ui-select-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
}
.sel_wrap .ui-selectbox-wrap.star.on .ui-select-button {
    height: 50px;
}

/* //폼 */
/* 블렛 정의 */
.bullet.square > li {
    position: relative;
    padding-left: 13px;
    color: #FFFFFF;
}
.bullet.square > li:before {
    width: 5px;
    height: 5px;
    background-color: #FFFFFF;
    transform: rotate(45deg);
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    bottom: 0;
    right: 0;
}

.bullet_list > li {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    color: #434545;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.bullet_list > li > a {
    color: #171A19;
}
.bullet_list > li > a.t_under {
    color: #434545;
}
.bullet_list > li .bar {
    margin-top: 8px;
}
.bullet_list > li .bar > li {
    padding-left: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.bullet_list > li .bar > li:before {
    width: 5px;
    height: 1px;
    background-color: #727573;
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    bottom: 0;
    right: 0;
}
.bullet_list > li .bar > li + li {
    margin-top: 4px !important;
}
.bullet_list.dot > li {
    padding-left: 11px;
}
.bullet_list.dot > li:before {
    width: 4px;
    height: 4px;
    background-color: #434545;
    content: "";
    position: absolute;
    top: 7px;
    left: 0;
    bottom: 0;
    right: 0;
    transform: rotate(45deg);
}
.bullet_list.dot > li + li {
    margin-top: 8px;
}
.bullet_list.bar > li {
    padding-left: 10px;
}
.bullet_list.bar > li:before {
    width: 5px;
    height: 1px;
    background-color: #727573;
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    bottom: 0;
    right: 0;
}
.bullet_list.star > li {
    padding-left: 10px;
}
.bullet_list.star > li:before {
    content: "*" !important;
    color: #727573;
    content: "";
    position: absolute;
    top: 3px;
    left: -2px;
    bottom: 0;
    right: 0;
}

/* //블렛 정의 */
/* 리스트 내 결과 없음 */
ul > li .no_result {
    width: 100%;
    padding: 20px 0;
    text-align: center;
}
ul > li .no_result p {
    font-size: 14px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}

/* 일반 리스트 내 정보 노출(마우스오버 시 노출) */
.list01 > li {
    position: relative;
    display: inline-block;
}
.list01 > li + li {
    margin-left: 9px;
}
.list01 > li > a {
    display: block;
    width: 100%;
    height: 100%;
}
.list01 > li > a::before {
    visibility: hidden;
    opacity: 0;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 8.13%, rgba(0, 0, 0, 0.5) 97.33%);
}
.list01 > li > a > img {
    width: 100%;
}
.list01 > li > a > dl {
    visibility: hidden;
    position: absolute;
    bottom: 24px;
    left: 24px;
}
.list01 > li > a > dl > dt {
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.list01 > li > a > dl > dd {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.list01 > li > a .txt {
    position: absolute;
    bottom: 24px;
    left: 24px;
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.list01 > li > a:not(.ui_autoslider_elem):hover::before,
.list01 > li > a:not(.ui_autoslider_elem):hover > dl,
.list01 > li > a:not(.ui_autoslider_elem):hover .txt, .list01 > li > a:not(.ui_autoslider_elem):focus::before,
.list01 > li > a:not(.ui_autoslider_elem):focus > dl,
.list01 > li > a:not(.ui_autoslider_elem):focus .txt {
    visibility: visible;
    opacity: 1;
    transition: all 0.1s ease-in-out;
}

/* 일반 리스트(마우스오버 시 딤노출) */
.list02 > li {
    position: relative;
    display: inline-block;
    vertical-align: top;
}
.list02 > li + li {
    margin-left: 9px;
}
.list02 > li > a {
    display: block;
    width: 100%;
    height: 100%;
}
.list02 > li > a::before {
    visibility: hidden;
    opacity: 0;
    height: 351px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(192.26deg, rgba(0, 0, 0, 0) 18.01%, rgba(0, 0, 0, 0.6) 92.31%);
}
.list02 > li > a:hover::before,
.list02 > li > a:hover > dl,
.list02 > li > a:hover .txt, .list02 > li > a:focus::before,
.list02 > li > a:focus > dl,
.list02 > li > a:focus .txt {
    visibility: visible;
    opacity: 1;
    transition: all 0.1s ease-in-out;
}
.list02 > li > a > img {
    width: 100%;
}
.list02 > li > a > dl {
    margin-top: 24px;
}
.list02 > li > a > dl > dt {
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.list02 > li > a > dl > dt .txt_img {
    display: block;
    margin-top: -2px;
}
.list02 > li > a > dl > dt .txt_img img {
    height: 26.2px;
}
.list02 > li > a > dl > dd .txt {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.list02 > li > a > dl > dd .due {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}

/* 매장안내 리스트 */
.shop_list > ul > li {
    position: relative;
    display: inline-block;
    width: 472px;
    padding: 22px 28px 24px;
    border: 1px solid #EAEBEA;
    box-sizing: border-box;
}
.shop_list > ul > li + li {
    margin-left: 9px;
}
.shop_list > ul > li:nth-child(n+4) {
    margin-top: 12px;
}
.shop_list > ul > li:nth-child(3n+1) {
    margin-left: 0;
}
.shop_list > ul > li .branch_info {
    font-size: 0;
}
.shop_list > ul > li .branch_info .branch {
    font-size: 14px;
    font-weight: 700;
    color: #00815A;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.shop_list > ul > li .branch_info .floor {
    font-size: 14px;
    font-weight: 700;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.shop_list > ul > li .branch_info > span {
    display: inline-block;
}
.shop_list > ul > li .branch_info > span + span::before {
    content: "";
    display: inline-block;
    margin-top: 5px;
    margin-right: 8px;
    margin-bottom: 0;
    margin-left: 8px;
    vertical-align: top;
    width: 1px;
    height: 10px;
    background-color: #D4D6D4;
}
.shop_list > ul > li .cate {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.shop_list > ul > li .title {
    margin-top: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 52px;
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.shop_list > ul > li .add_info {
    margin-top: 8px;
}
.shop_list > ul > li .add_info .seller {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.shop_list > ul > li .add_info .contact {
    font-size: 16px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.shop_list > ul > li .add_info > span {
    display: inline-block;
}
.shop_list > ul > li .add_info > span + span {
    margin-left: 8px;
}
.shop_list > ul > li .util_btns {
    position: absolute;
    right: 23px;
    bottom: 24px;
}
.shop_list > ul > li .util_btns .link_blank,
.shop_list > ul > li .util_btns .btn-brand-store {
    display: inline-block;
    vertical-align: top;
    margin-top: 3px;
    width: 24px;
    height: 24px;
    background-image: url("/images/webhome2/ico_blank_link_24.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.shop_list > ul > li .util_btns .link_blank > span,
.shop_list > ul > li .util_btns .btn-brand-store > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.shop_list > ul > li .util_btns .map,
.shop_list > ul > li .util_btns .btn-brand-map {
    margin-left: 10px;
    width: 24px;
    height: 24px;
    background-image: url("/images/webhome2/ico_pc_location_24_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.shop_list > ul > li .util_btns .map > span,
.shop_list > ul > li .util_btns .btn-brand-map > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}

/* 행사정보/문화센터/강좌 리스트 */
.list03 > li {
    position: relative;
    display: inline-block;
    width: 351px;
}
.list03 > li + li {
    margin-left: 9px;
}
.list03 > li:nth-child(n+5) {
    margin-top: 48px;
}
.list03 > li:nth-child(4n+1) {
    margin-left: 0;
}
.list03 > li img {
    width: 100%;
}
.list03 > li .branch_info {
    margin-top: 24px;
}
.list03 > li .branch_info .branch {
    font-size: 14px;
    font-weight: 700;
    color: #00815A;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.list03 > li .branch_info .state {
    font-size: 14px;
    font-weight: 700;
    color: #ff5473;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.list03 > li .branch_info .state.dis {
    color: #727573;
}
.list03 > li .branch_info .etc {
    font-size: 14px;
    font-weight: 700;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.list03 > li .branch_info > span {
    display: inline-block;
}
.list03 > li .branch_info > span + span::before {
    content: "";
    display: inline-block;
    margin-top: 5px;
    margin-right: 8px;
    margin-bottom: 0;
    margin-left: 8px;
    vertical-align: top;
    width: 1px;
    height: 10px;
    background-color: #EAEBEA;
}
.list03 > li > a {
    text-decoration: none;
}
.list03 > li > a > dl {
    margin-top: 8px;
}
.list03 > li > a > dl > dt {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 52px;
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.list03 > li > a > dl > dd {
    margin-top: 8px;
}
.list03 > li > a > dl > dd .due {
    font-size: 16px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.list03 > li > a > dl > dd .time {
    margin-left: 12px;
    font-size: 16px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.list03 > li > a > dl > dd.class_info .info {
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 21px;
}
.list03 > li > a > dl > dd.class_info .info > span + span::before {
    content: "";
    display: inline-block;
    margin-top: 8px;
    margin-right: 8px;
    margin-bottom: 0;
    margin-left: 5px;
    vertical-align: top;
    width: 1px;
    height: 10px;
    background-color: #EAEBEA;
}
.list03 > li > a > dl > dd.class_info .price {
    margin-top: 8px;
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.list03 > li > a > dl > dd.class_info .price .won {
    margin-left: 2px;
    vertical-align: 1px;
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}

/* 추천 상품 리스트 */
.list03.prd_type > li {
    margin-top: 0;
}
.list03.prd_type > li a > dl {
    margin-top: 24px;
}
.list03.prd_type > li a > dl > dt {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 26px;
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.list03.prd_type > li a > dl > dd {
    margin-top: 4px;
}
.list03.prd_type > li a > dl > dd .desc {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 44px;
}
.list03.prd_type > li a > dl > dd .price {
    margin-top: 12px;
    font-size: 22px;
    font-weight: 700;
    color: #00815A;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.list03.prd_type > li a > dl > dd .price .won {
    font-size: 20px;
    font-weight: 700;
    color: #00815A;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.list03.prd_type > li a > dl > dd > del {
    text-decoration: line-through;
}
.list03.prd_type > li a > dl > dd > del.price {
    margin-top: 12px;
    font-size: 18px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.list03.prd_type > li a > dl > dd > del.price .origin {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.list03.prd_type > li a > dl > dd > del.price .won {
    font-size: 18px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.list03.prd_type > li a > dl > dd > del + .price {
    margin-top: 0;
}

/* 캐러셀용 */
.slide_wrap .slide_conts .inner_box.list01_box {
    position: relative;
}
.slide_wrap .slide_conts .inner_box.list01_box > a {
    display: block;
    width: 100%;
    height: 100%;
}
.slide_wrap .slide_conts .inner_box.list01_box > a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(192.26deg, rgba(0, 0, 0, 0) 18.01%, rgba(0, 0, 0, 0.6) 92.31%);
}
.slide_wrap .slide_conts .inner_box.list01_box > a > img {
    width: 100%;
}
.slide_wrap .slide_conts .inner_box.list01_box > a > dl {
    visibility: hidden;
    position: absolute;
    bottom: 24px;
    left: 24px;
}
.slide_wrap .slide_conts .inner_box.list01_box > a > dl > dt {
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.slide_wrap .slide_conts .inner_box.list01_box > a > dl > dd {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.slide_wrap .slide_conts .inner_box.list01_box > a .txt {
    position: absolute;
    bottom: 24px;
    left: 24px;
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.slide_wrap .slide_conts .inner_box.list02_box > a {
    display: block;
    width: 100%;
    height: 100%;
}
.slide_wrap .slide_conts .inner_box.list02_box > a > img {
    width: 100%;
}
.slide_wrap .slide_conts .inner_box.list02_box > a > dl {
    margin-top: 24px;
}
.slide_wrap .slide_conts .inner_box.list02_box > a > dl > dt {
    font-size: 1rem;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.slide_wrap .slide_conts .inner_box.list02_box > a > dl > dd .txt {
    margin-top: 8px;
    font-size: 1rem;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.slide_wrap .slide_conts .inner_box.list02_box > a > dl > dd .due {
    margin-top: 12px;
    font-size: 0.875rem;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.slide_wrap .slide_conts .inner_box.list03_box.prd_type a {
    text-decoration: none;
}
.slide_wrap .slide_conts .inner_box.list03_box.prd_type a img {
    width: 100%;
}
.slide_wrap .slide_conts .inner_box.list03_box.prd_type a > dl {
    margin-top: 24px;
}
.slide_wrap .slide_conts .inner_box.list03_box.prd_type a > dl > dt {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 26px;
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.slide_wrap .slide_conts .inner_box.list03_box.prd_type a > dl > dd {
    margin-top: 4px;
}
.slide_wrap .slide_conts .inner_box.list03_box.prd_type a > dl > dd .desc {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 44px;
}
.slide_wrap .slide_conts .inner_box.list03_box.prd_type a > dl > dd .price {
    margin-top: 12px;
    font-size: 22px;
    font-weight: 700;
    color: #00815A;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.slide_wrap .slide_conts .inner_box.list03_box.prd_type a > dl > dd .price .won {
    font-size: 20px;
    font-weight: 700;
    color: #00815A;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.slide_wrap .slide_conts .inner_box.list03_box.prd_type a > dl > dd > del {
    text-decoration: line-through;
}
.slide_wrap .slide_conts .inner_box.list03_box.prd_type a > dl > dd > del.price {
    margin-top: 12px;
    font-size: 18px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.slide_wrap .slide_conts .inner_box.list03_box.prd_type a > dl > dd > del.price .origin {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.slide_wrap .slide_conts .inner_box.list03_box.prd_type a > dl > dd > del.price .won {
    font-size: 18px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.slide_wrap .slide_conts .inner_box.list03_box.prd_type a > dl > dd > del + .price {
    margin-top: 0;
}

/* 플래그 정의 */
.flag {
    position: absolute;
    top: 16px;
    left: 16px;
    padding-bottom: 3px;
    background-color: #171A19;
    z-index: 10;
}
.flag:after {
    content: "";
    position: absolute;
    top: initial;
    left: initial;
    bottom: 0px;
    right: -3px;
    width: 0;
    height: 0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 3px solid #171A19;
    z-index: 1;
}
.flag span {
    position: relative;
    display: block;
    margin-left: 3px;
    margin-right: -3px;
    padding: 2px 8px 3px;
    background-color: #FFFFFF;
    font-size: 12px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.3;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    z-index: 3;
}

/* //플래그 정의 */
.slide_wrap {
    position: relative;
}
.slide_wrap .indi_wrap {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 24px;
    z-index: 10;
}
.slide_wrap .indi_wrap .indi_conts {
    font-size: 0;
    text-align: center;
}
.slide_wrap .indi_wrap .indi_conts > li {
    line-height: 0;
    font-size: 0;
}
.slide_wrap .indi_wrap .indi_conts > li .btn_indi > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.slide_wrap .indi_wrap .indi_control > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.slide_wrap .indi_progress {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    height: 2px;
}
.slide_wrap .indi_progress .track {
    height: 2px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
}
.slide_wrap .indi_progress .track .current {
    display: block;
    height: 2px;
    background-color: rgb(0, 0, 0);
}
.slide_wrap .indi_progress .track .current > i {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.slide_wrap .slide_content .slide_track {
    content: " ";
    display: block;
    clear: both;
    overflow: hidden;
}
.slide_wrap .slide_content .slide_conts {
    position: relative;
}
.slide_wrap .slide_content .slide_conts.on {
    z-index: 10;
}
.slide_wrap .slide_content .slide_conts .inner_box a {
    display: block;
}
.slide_wrap .slide_content .slide_conts .inner_box a:active {
    outline: none;
    border: none;
}
.slide_wrap .slide_content .slide_conts .inner_box a:focus {
    position: relative;
}
.slide_wrap .slide_content .slide_conts .inner_box a:focus:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: rgba(0, 0, 0, 0.5);
}
.slide_wrap .slide_controls {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    max-width: 1600px;
    z-index: 10;
}
.slide_wrap .slide_controls button {
    position: absolute;
    opacity: 1;
}
.slide_wrap .slide_controls button.prev {
    right: 0;
    transform: rotate(180deg);
}
.slide_wrap .slide_controls button.next {
    left: 0;
}
.slide_wrap .slide_controls button > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.slide_wrap .slide_controls button.disabled {
    opacity: 0.5;
}

.scroll_wrap {
    position: relative;
}
.scroll_wrap > div {
    overflow: hidden;
}
.scroll_wrap .slide_controls {
    position: absolute;
}
.scroll_wrap .slide_controls button {
    position: absolute;
    opacity: 1;
}
.scroll_wrap .slide_controls button.prev {
    right: 0;
    transform: rotate(180deg);
}

.rolling_wrap .list_wrap {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.rolling_wrap .list_wrap > ul > li > a {
    display: block;
}
.rolling_wrap .slide_controls {
    position: absolute;
}
.rolling_wrap .slide_controls .btn_arrow.prev {
    width: 28px;
    height: 28px;
    background-image: url("/images/webhome2/ico_arrow_right_28_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: rotate(-180deg);
}
.rolling_wrap .slide_controls .btn_arrow.next {
    margin-left: 12px;
    width: 28px;
    height: 28px;
    background-image: url("/images/webhome2/ico_arrow_right_28_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.rolling_wrap .slide_controls .btn_arrow > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}

/* 레이어 팝업 정의 */
.lay_pop {
    display: none;
    width: 714px;
    /* size */
}
.lay_pop .lay_wrap {
    position: relative;
    padding: 38px 40px 48px;
    background-color: #FFFFFF;
}
.lay_pop .lay_wrap .lay_tit {
    border-bottom: 2px solid #171A19;
}
.lay_pop .lay_wrap .lay_tit h1 {
    padding-bottom: 16px;
}
.lay_pop .lay_wrap .lay_tit h1 span {
    font-size: 28px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.lay_pop .lay_wrap .lay_conts {
    max-height: 638px;
    overflow: auto;
}
.lay_pop .lay_wrap .lay_conts .alert_phrases {
    margin-top: 24px;
}
.lay_pop .lay_wrap .lay_conts .alert_phrases.solo {
    margin-top: 40px;
}
.lay_pop .lay_wrap .lay_conts .alert_phrases p {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    text-align: center;
}
.lay_pop .lay_wrap .lay_conts .alert_phrases p.sub {
    font-size: 16px;
    font-weight: 400;
    color: #434545;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.lay_pop .lay_wrap .lay_conts .alert_phrases strong {
    display: block;
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    text-align: center;
}
.lay_pop .lay_wrap .lay_conts .alert_phrases dt {
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.lay_pop .lay_wrap .lay_conts .alert_phrases dd {
    margin-top: 8px;
}
.lay_pop .lay_wrap .lay_conts .alert_phrases dd > p {
    text-align: left;
}
.lay_pop .lay_wrap.alert {
    width: 440px;
    padding-top: 64px;
}
.lay_pop .lay_wrap.alert .lay_tit {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.lay_pop .lay_wrap.alert .lay_conts > div {
    text-align: center;
}
.lay_pop .lay_wrap.alert .lay_conts > div p {
    padding: 0 0 40px;
}
.lay_pop .lay_wrap .close {
    position: absolute;
    top: 44px;
    right: 40px;
}
.lay_pop .lay_wrap .close button {
    width: 32px;
    height: 32px;
    background-image: url("/images/webhome2/ico_close_32_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.lay_pop .lay_wrap .close button span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.lay_pop.small {
    width: 472px;
}
.lay_pop.large {
    width: 956px;
    min-height: 750px !important;
}
.lay_pop.large .lay_wrap .lay_tit h1 span {
    font-size: 28px;
}
.lay_pop.large .lay_wrap .close button {
    width: 32px;
    height: 32px;
}
.lay_pop.xlarge {
    width: 1110px;
}
.lay_pop.xlarge .lay_wrap .close button {
    width: 32px;
    height: 32px;
}

/* PGR 팝업 */
.lay_pop .cmd_wrap {
    margin-top: 24px;
}
.lay_pop .cmd_wrap.type02 {
    margin-top: 40px;
}
.lay_pop .cmd_wrap .title01 {
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.lay_pop .cmd_wrap .title_desc {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #434545;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.lay_pop .cmd_wrap .bullet_list {
    margin-top: 8px;
}
.lay_pop .cmd_wrap .title_info {
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    text-align: center;
}
.lay_pop .cmd_wrap .btn_wrap {
    margin-top: 40px;
    font-size: 0;
    display: flex;
    align-items: center;
}
.lay_pop .cmd_wrap .btn_wrap.ty02 {
    margin-top: 56px;
}
.lay_pop .cmd_wrap .btn_wrap .btn {
    padding: 0;
    box-sizing: border-box;
    width: calc(50% - 4px);
    height: 60px;
    text-align: center;
}
.lay_pop .cmd_wrap .btn_wrap .btn > span {
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 58px;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.lay_pop .cmd_wrap .btn_wrap .btn.border {
    border: 2px solid #171A19;
}
.lay_pop .cmd_wrap .btn_wrap .btn.border > span {
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 58px;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.lay_pop .cmd_wrap .btn_wrap .btn + .btn {
    margin-left: 8px;
}
.lay_pop .cmd_wrap .btn_wrap.solo .btn {
    width: 270px !important;
    margin: 0 auto;
}

/* //PGR 팝업 */
/* 윈도우팝업 */
.lay_pop.window {
    display: block;
    width: 100%;
}
.lay_pop.window .btn_wrap {
    margin-top: 100px;
}

/* //레이어 팝업 정의 */
/* 레이어 팝업 정의 */
.win_pop {
    width: 100%;
}
.win_pop .win_wrap {
    position: relative;
    padding: 32px 40px 48px;
    background-color: #FFFFFF;
}
.win_pop .win_wrap .win_tit {
    border-bottom: 2px solid #171A19;
}
.win_pop .win_wrap .win_tit h1 {
    padding-bottom: 16px;
}
.win_pop .win_wrap .win_tit h1 span {
    font-size: 28px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.win_pop .win_wrap .win_conts .sup {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 400;
    color: #434545;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.win_pop .win_wrap .win_conts .sup + .id_pw_find_section {
    margin-top: 40px;
}
.win_pop .win_wrap .close {
    position: absolute;
    top: 37px;
    right: 40px;
}
.win_pop .win_wrap .close button {
    width: 32px;
    height: 32px;
    background-image: url("/images/webhome2/ico_close_20_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.win_pop .win_wrap .close button span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}

/* //레이어 팝업 정의 */
/* GNB 정의 */
#head {
    position: fixed;
    z-index: 55;
    top: 32px;
    left: 0;
    right: 0;
    width: 1440px;
    height: 70px;
    margin: 0 auto;
    background-color: #FFFFFF;
}
#head .header_wrap {
    margin: 0 auto;
    width: 1440px;
    display: flex;
    justify-content: space-between;
}
#head .header_wrap > div {
    display: inline-flex;
    align-items: center;
}
#head .header_wrap > div .logo {
    position: relative;
    margin-top: 5px;
}
#head .header_wrap > div .logo h1 {
    display: inline-block;
    margin-left: 32px;
    width: 139px;
    height: 22px;
    background-image: url("/images/webhome2/logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
#head .header_wrap > div .logo h1 span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
#head .header_wrap > div .logo h1 a {
    display: block;
    width: 100%;
    height: 100%;
}
#head .header_wrap > div .logo button {
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
    margin-left: 8px;
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/btn_gnb_20.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
#head .header_wrap > div .logo button span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
#head .header_wrap > div .logo .brand_gate {
    visibility: hidden;
    opacity: 0;
    height: 0;
    position: absolute;
    z-index: 5;
    top: 46px;
    left: 0;
    width: 231px;
    padding: 12px 0 20px;
    background-color: #FFFFFF;
    transition: all 0.1s ease-in;
    overflow: hidden;
}
#head .header_wrap > div .logo .brand_gate ul {
    width: 100%;
}
#head .header_wrap > div .logo .brand_gate ul li {
    padding-top: 16px;
    padding-left: 51px;
    padding-bottom: 16px;
}
#head .header_wrap > div .logo .brand_gate ul li:first-child {
    padding-top: 0;
}
#head .header_wrap > div .logo .brand_gate ul li:first-child a:before {
    background-image: url("/images/webhome2/logo_hdd_gate_logo_36.png");
}
#head .header_wrap > div .logo .brand_gate ul li + li {
    border-top: 1px solid #EAEBEA;
}
#head .header_wrap > div .logo .brand_gate ul li a {
    font-size: 18px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    text-decoration: none;
}
#head .header_wrap > div .logo .brand_gate ul li a::before {
    content: "";
    display: inline-block;
    margin-top: -4px;
    margin-right: 12px;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
    width: 36px;
    height: 36px;
    background-image: url("/images/webhome2/logo_hdc_gate_logo_36.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
#head .header_wrap > div .logo.on button {
    transform: rotate(-180deg);
}
#head .header_wrap > div .logo.on .brand_gate {
    visibility: visible;
    opacity: 1;
    height: 105px;
    transition: all 0.3s ease-in-out;
}
#head .header_wrap > div .main {
    margin-left: 16px;
}
#head .header_wrap > div .main h2 {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
#head .header_wrap > div .main > ul {
    display: flex;
    align-items: center;
    height: 70px;
}
#head .header_wrap > div .main > ul > li {
    display: inline-block;
    padding-right: 16px;
    padding-left: 16px;
}
#head .header_wrap > div .main > ul > li > a {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
#head .header_wrap > div .main > ul > li > a:hover {
    text-decoration: none;
    font-weight: bold;
}
#head .header_wrap > div .main > ul > li > a:hover:after {
    content: "";
    position: absolute;
    top: initial;
    left: 0;
    bottom: -6px;
    right: initial;
    width: 100%;
    height: 2px;
    background-color: #171A19;
}
#head .header_wrap > div .main > ul > li.on > a {
    font-weight: bold;
}
#head .header_wrap > div .main > ul > li.on > a:after {
    content: "";
    position: absolute;
    top: initial;
    left: 0;
    bottom: -6px;
    right: initial;
    width: 100%;
    height: 2px;
    background-color: #171A19;
}
#head .header_wrap > div .main > ul > li.on .sub {
    display: block;
}
#head .header_wrap > div .main > ul > li nav.sub {
    display: none;
    position: absolute;
    top: 70px;
    left: 50%;
    width: 1344px;
    margin-left: -720px;
    padding: 40px 0 64px;
    background-color: #FFFFFF;
}
#head .header_wrap > div .main > ul > li nav.sub > div {
    display: inline-block;
    width: 220px;
    margin-left: 56px;
}
#head .header_wrap > div .main > ul > li nav.sub > div p {
    font-size: 32px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.3;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
#head .header_wrap > div .main > ul > li nav.sub > div p span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
#head .header_wrap > div .main > ul > li nav.sub > ul {
    display: inline-block;
    margin-top: 9px;
    vertical-align: top;
}
#head .header_wrap > div .main > ul > li nav.sub > ul > li {
    display: inline-block;
    vertical-align: top;
    margin-left: 40px;
}
#head .header_wrap > div .main > ul > li nav.sub > ul > li:first-child {
    margin-left: 32px;
}
#head .header_wrap > div .main > ul > li nav.sub > ul > li > a {
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.3;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
#head .header_wrap > div .main > ul > li nav.sub > ul > li > a:after {
    content: "";
    display: inline-block;
    margin-top: -1px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/ico_arrow_right_14.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
#head .header_wrap > div .main > ul > li nav.sub > ul > li > span {
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.3;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
#head .header_wrap > div .main > ul > li nav.sub > ul > li > ul {
    margin-top: 20px;
}
#head .header_wrap > div .main > ul > li nav.sub > ul > li > ul > li {
    margin-bottom: 12px;
}
#head .header_wrap > div .main > ul > li nav.sub > ul > li > ul > li:last-child {
    margin-bottom: 0;
}
#head .header_wrap > div .main > ul > li nav.sub > ul > li > ul > li > a {
    font-size: 14px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.3;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
#head .header_wrap > div .main > ul > li nav.sub > ul > li > ul > li > a.link:after {
    content: "";
    display: inline-block;
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 2px;
    vertical-align: top;
    width: 16px;
    height: 16px;
    background-image: url("/images/webhome2/ico_pc_link_16_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
#head .header_wrap > div .main > ul > li nav.sub > ul > li > ul > li > a.dis {
    pointer-events: none;
    font-size: 14px;
    font-weight: 400;
    color: #D4D6D4;
    line-height: 1.3;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
#head .header_wrap > div .main > ul > li nav.sub .event_sec {
    display: inline-block;
    position: absolute;
    top: 40px;
    right: 40px;
    width: 284px;
    text-align: right;
}
#head .header_wrap > div .main > ul > li nav.sub .event_sec > a img {
    width: 284px;
    height: 240px;
}
#head .header_wrap > div .main > ul > li div.sub {
    display: none;
    position: absolute;
    top: 70px;
    left: 50%;
    width: 1344px;
    margin-left: -720px;
    padding: 40px 0 64px;
    background-color: #FFFFFF;
}
#head .header_wrap > div .main > ul > li div.sub > div {
    display: inline-block;
    width: 220px;
    margin-left: 56px;
}
#head .header_wrap > div .main > ul > li div.sub > div p {
    font-size: 32px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.3;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
#head .header_wrap > div .main > ul > li div.sub > div p span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
#head .header_wrap > div .main > ul > li div.sub > ul {
    display: inline-block;
    margin-top: 9px;
    vertical-align: top;
}
#head .header_wrap > div .main > ul > li div.sub > ul > li {
    display: inline-block;
    vertical-align: top;
    margin-left: 40px;
}
#head .header_wrap > div .main > ul > li div.sub > ul > li:first-child {
    margin-left: 32px;
}
#head .header_wrap > div .main > ul > li div.sub > ul > li .name span {
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.3;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
#head .header_wrap > div .main > ul > li div.sub > ul > li .name a {
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.3;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
#head .header_wrap > div .main > ul > li div.sub > ul > li .name a:after {
    content: "";
    display: inline-block;
    margin-top: -1px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/ico_arrow_right_14.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
#head .header_wrap > div .main > ul > li div.sub > ul > li .navigations > ul {
    margin-top: 20px;
}
#head .header_wrap > div .main > ul > li div.sub > ul > li .navigations > ul > li {
    margin-bottom: 12px;
}
#head .header_wrap > div .main > ul > li div.sub > ul > li .navigations > ul > li:last-child {
    margin-bottom: 0;
}
#head .header_wrap > div .main > ul > li div.sub > ul > li .navigations > ul > li > a {
    font-size: 14px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.3;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
#head .header_wrap > div .main > ul > li div.sub > ul > li .navigations > ul > li > a.link:after {
    content: "";
    display: inline-block;
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 2px;
    vertical-align: top;
    width: 16px;
    height: 16px;
    background-image: url("/images/webhome2/ico_pc_link_16_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
#head .header_wrap > div .main > ul > li div.sub > ul > li .navigations > ul > li > a.dis {
    pointer-events: none;
    font-size: 14px;
    font-weight: 400;
    color: #D4D6D4;
    line-height: 1.3;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
#head .header_wrap > div .main > ul > li div.sub .event_sec {
    display: inline-block;
    position: absolute;
    top: 40px;
    right: 40px;
    width: 284px;
    text-align: right;
}
#head .header_wrap > div .main > ul > li div.sub .event_sec > a img {
    width: 284px;
    height: 240px;
}
#head .header_wrap > div .member_service h2 {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
#head .header_wrap > div .member_service ul li {
    display: inline-block;
    padding-right: 8px;
    padding-left: 8px;
}
#head .header_wrap > div .member_service ul li a {
    font-size: 12px;
    font-weight: 400;
    color: #727573;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    text-decoration: none;
}
#head .header_wrap > div .member_service ul li .sub {
    display: none;
}
#head .header_wrap > div .lang_choice {
    position: relative;
    padding-right: 20px;
}
#head .header_wrap > div .lang_choice > a {
    display: block;
    width: 40px;
    height: 24px;
    width: 40px;
    height: 24px;
    background-image: url("/images/webhome2/btn_lang.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
#head .header_wrap > div .lang_choice > a h2 {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
#head .header_wrap > div .lang_choice.on > a {
    transform: rotateX(180deg);
}
#head .header_wrap > div .lang_choice .lang_list {
    visibility: hidden;
    opacity: 0;
    height: 0;
    position: absolute;
    z-index: 5;
    top: 20px;
    left: -12px;
    padding: 12px;
    background-color: #FFFFFF;
    transition: all 0.1s ease-in;
    overflow: hidden;
}
#head .header_wrap > div .lang_choice .lang_list ul {
    width: 100%;
}
#head .header_wrap > div .lang_choice .lang_list ul li + li {
    padding-top: 16px;
}
#head .header_wrap > div .lang_choice .lang_list ul li a {
    font-size: 12px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    text-decoration: none;
}
#head .header_wrap > div .lang_choice.on .lang_list {
    visibility: visible;
    opacity: 1;
    height: 94px;
    transition: all 0.3s ease-in-out;
}
#head .header_wrap > div .brand_search > a {
    width: 190px;
    height: 70px;
    background-color: #171A19;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
#head .header_wrap > div .brand_search > a:after {
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 6px;
    vertical-align: top;
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/ico_gnb_search_20.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
#head .header_wrap > div .brand_search > a span {
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.3;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
#head .header_wrap > div .brand_search > a:hover, #head .header_wrap > div .brand_search > a:focus {
    background-color: #00815A;
}
#head .header_wrap > div .brand_search.on .search_wrap {
    display: block;
}
#head .header_wrap > div .brand_search.on .search_wrap + .dim {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 0.4;
}
#head .header_wrap > div .brand_search .search_wrap {
    display: none;
    position: fixed;
    z-index: 53;
    top: 0;
    left: 0;
    right: 0;
    height: 296px;
    padding-top: 88px;
    background-color: #FFFFFF;
}
#head .header_wrap > div .brand_search .search_wrap .condition {
    margin: 0 auto;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap,
#head .header_wrap > div .brand_search .search_wrap .condition form {
    display: flex;
    align-items: center;
    justify-content: center;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .sel,
#head .header_wrap > div .brand_search .search_wrap .condition form .sel {
    margin-right: 8px;
    height: 74px;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .sel .ui-selectbox-wrap,
#head .header_wrap > div .brand_search .search_wrap .condition form .sel .ui-selectbox-wrap {
    position: relative;
    width: 100% !important;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .sel .ui-selectbox-wrap.on .ui-select-button,
#head .header_wrap > div .brand_search .search_wrap .condition form .sel .ui-selectbox-wrap.on .ui-select-button {
    position: relative;
    padding: 21px 22px 22px;
    border-left: 2px solid #171A19;
    border-right: 2px solid #171A19;
    border-top: 2px solid #171A19;
    border-bottom: none;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .sel .ui-selectbox-wrap.on .ui-select-button::after,
#head .header_wrap > div .brand_search .search_wrap .condition form .sel .ui-selectbox-wrap.on .ui-select-button::after {
    content: "";
    position: absolute;
    top: initial;
    left: 0;
    bottom: -1px;
    right: 0;
    z-index: 1;
    border-bottom: 1px solid #eaebea;
    height: 1px;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .sel .ui-selectbox-wrap.on .ui-select-button .ico,
#head .header_wrap > div .brand_search .search_wrap .condition form .sel .ui-selectbox-wrap.on .ui-select-button .ico {
    top: 23px;
    right: 22px;
    width: 24px;
    height: 24px;
    background-image: url("/images/webhome2/ico_dropdown_up_24.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .sel .ui-selectbox-wrap .ui-select-button,
#head .header_wrap > div .brand_search .search_wrap .condition form .sel .ui-selectbox-wrap .ui-select-button {
    display: block;
    width: 288px;
    padding: 22px 23px;
    border-radius: 0;
    background-color: #FFFFFF;
    border: 1px solid #eaebea;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .sel .ui-selectbox-wrap .ui-select-button .ico,
#head .header_wrap > div .brand_search .search_wrap .condition form .sel .ui-selectbox-wrap .ui-select-button .ico {
    position: absolute;
    top: 25px;
    right: 24px;
    width: 24px;
    height: 24px;
    background-image: url("/images/webhome2/ico_dropdown_24.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .sel .ui-selectbox-wrap .ui-select-button .ui-select-text,
#head .header_wrap > div .brand_search .search_wrap .condition form .sel .ui-selectbox-wrap .ui-select-button .ui-select-text {
    vertical-align: middle;
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .sel .ui-selectbox-wrap .ui-select-button .hide,
#head .header_wrap > div .brand_search .search_wrap .condition form .sel .ui-selectbox-wrap .ui-select-button .hide {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .sel .ui-selectbox-wrap .ui-selectbox-list,
#head .header_wrap > div .brand_search .search_wrap .condition form .sel .ui-selectbox-wrap .ui-selectbox-list {
    position: relative;
    width: 284px;
    max-height: 116px;
    overflow: auto;
    padding: 20px 24px;
    background-color: #FFFFFF;
    border-left: 2px solid #171A19;
    border-right: 2px solid #171A19;
    border-bottom: 2px solid #171A19;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .sel .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea-ul > li > a,
#head .header_wrap > div .brand_search .search_wrap .condition form .sel .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea-ul > li > a {
    font-size: 18px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .sel .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea-ul > li > a.disabled,
#head .header_wrap > div .brand_search .search_wrap .condition form .sel .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea-ul > li > a.disabled {
    color: #D4D6D4;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .sel .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea-ul > li.on > a,
#head .header_wrap > div .brand_search .search_wrap .condition form .sel .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea-ul > li.on > a {
    font-size: 18px;
    font-weight: 700;
    color: #00815A;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .sel .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea-ul > li + li,
#head .header_wrap > div .brand_search .search_wrap .condition form .sel .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea-ul > li + li {
    margin-top: 12px;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .input,
#head .header_wrap > div .brand_search .search_wrap .condition form .input {
    display: flex;
    position: relative;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .input input[type=text],
#head .header_wrap > div .brand_search .search_wrap .condition form .input input[type=text] {
    width: 565px;
    height: 74px;
    border-radius: 0;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .input input[type=text]:focus, #head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .input input[type=text]:active, #head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .input input[type=text]:visited,
#head .header_wrap > div .brand_search .search_wrap .condition form .input input[type=text]:focus,
#head .header_wrap > div .brand_search .search_wrap .condition form .input input[type=text]:active,
#head .header_wrap > div .brand_search .search_wrap .condition form .input input[type=text]:visited {
    outline: none;
    border: 2px solid #171A19;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .input button,
#head .header_wrap > div .brand_search .search_wrap .condition form .input button {
    position: relative;
    width: 74px;
    height: 74px;
    background-color: #171A19;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .input button span,
#head .header_wrap > div .brand_search .search_wrap .condition form .input button span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .input button:before,
#head .header_wrap > div .brand_search .search_wrap .condition form .input button:before {
    content: "";
    display: inline-block;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
    width: 32px;
    height: 32px;
    background-image: url("/images/webhome2/ico_search_32_white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .input .auto_complete,
#head .header_wrap > div .brand_search .search_wrap .condition form .input .auto_complete {
    display: none;
    position: absolute;
    top: 74px;
    width: calc(100% - 4px);
    background-color: #FFFFFF;
    border: 2px solid #171A19;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .input .auto_complete > ul,
#head .header_wrap > div .brand_search .search_wrap .condition form .input .auto_complete > ul {
    max-height: 105px;
    overflow: auto;
    padding: 24px 4px 24px 24px;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .input .auto_complete > ul > li > a,
#head .header_wrap > div .brand_search .search_wrap .condition form .input .auto_complete > ul > li > a {
    font-size: 18px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .input .auto_complete > ul > li > a.on,
#head .header_wrap > div .brand_search .search_wrap .condition form .input .auto_complete > ul > li > a.on {
    color: #00815A;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .input .auto_complete > ul > li > a > strong,
#head .header_wrap > div .brand_search .search_wrap .condition form .input .auto_complete > ul > li > a > strong {
    color: #00815A;
}
#head .header_wrap > div .brand_search .search_wrap .condition .input_wrap .input .auto_complete > ul > li + li,
#head .header_wrap > div .brand_search .search_wrap .condition form .input .auto_complete > ul > li + li {
    margin-top: 8px;
}
#head .header_wrap > div .brand_search .search_wrap .keyword {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 72px;
}
#head .header_wrap > div .brand_search .search_wrap .keyword > dl dt {
    margin-bottom: 20px;
    text-align: center;
    font-size: 28px;
    font-weight: 900;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.5px;
    font-family: "Happiness-Sans-Title";
}
#head .header_wrap > div .brand_search .search_wrap .keyword > dl dd ul li {
    display: inline-block;
}
#head .header_wrap > div .brand_search .search_wrap .keyword > dl dd ul li a {
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
#head .header_wrap > div .brand_search .search_wrap .keyword > dl dd ul li + li {
    margin-left: 24px;
}
#head .header_wrap > div .brand_search .search_wrap .close {
    position: fixed;
    top: 40px;
    left: calc(50% + 600px);
    width: 32px;
    height: 32px;
    background-image: url("/images/webhome2/ico_close_32_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
#head .header_wrap > div .brand_search .search_wrap .close > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
#head + .dim {
    display: none;
    position: fixed;
    z-index: 52;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 0.4;
}
#head.sub {
    top: 0;
    width: 100%;
}
#head.sub .header_wrap {
    width: calc(100% - 480px);
    min-width: 1440px;
}
#head.sub .header_wrap > div .logo h1 {
    margin-left: 0;
}
#head.sub .header_wrap > div .main > ul > li .sub {
    width: calc(100% - 480px);
    min-width: 1440px;
    margin-left: calc(max(1440px, 100% - 480px) / -2);
}
#head.sub .header_wrap > div .main > ul > li .sub > div {
    margin-left: 32px;
}
#head.sub .header_wrap > div .main > ul > li .sub .event_sec {
    right: 0;
}

/* //GNB 정의 */
/* 푸터 정의 */
footer {
    background-color: #171A19;
    height: 538px;
}
footer.main {
    margin-top: 0;
}
footer .footer_wrap {
    position: relative;
    display: flex;
    justify-content: center;
    width: 1440px;
    margin: 0 auto;
    padding-top: 80px;
}
footer .footer_wrap:after {
    content: "";
    position: absolute;
    top: 0;
    left: initial;
    bottom: 0;
    right: 0;
    width: 100%;
    min-height: 538px;
    background-color: #171A19;
    z-index: -1;
}
footer .footer_wrap .logo {
    position: relative;
    width: 363px;
}
footer .footer_wrap .logo:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 206px;
    height: 24px;
    background-image: url("/images/webhome2/logo_footer.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
footer .footer_wrap .logo span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
footer .footer_wrap .conts {
    position: relative;
    width: 1077px;
}
footer .footer_wrap .conts > h2 {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
footer .footer_wrap .conts .related_site {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
footer .footer_wrap .conts .related_site ul li {
    display: inline-block;
    margin-left: 56px;
}
footer .footer_wrap .conts .related_site ul li:first-child {
    margin-left: 0;
}
footer .footer_wrap .conts .related_site ul li a {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    text-decoration: none;
}
footer .footer_wrap .conts .related_site ul li a:hover {
    text-decoration: underline;
}
footer .footer_wrap .conts .related_site .family_site {
    position: relative;
    margin: -13px 0;
}
footer .footer_wrap .conts .related_site .family_site > h2 {
    width: 160px;
}
footer .footer_wrap .conts .related_site .family_site > h2 a {
    display: block;
    width: 100%;
    padding: 11px 48px 11px 16px;
    border: 1px solid transparent;
    box-sizing: border-box;
    background: #171A19;
    font-size: 16px;
    font-weight: 700;
    color: #D4D6D4;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
footer .footer_wrap .conts .related_site .family_site > h2 a::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 16px;
    margin-top: -12px;
    width: 24px;
    height: 24px;
    background-image: url("/images/webhome2/ico_arrow_down_24_gray.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
footer .footer_wrap .conts .related_site .family_site > ul {
    display: none;
    overflow-y: auto;
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    width: 100%;
    z-index: 10;
    max-height: 331px;
    padding: 6px 0;
    box-sizing: border-box;
    background-color: #171A19;
}
footer .footer_wrap .conts .related_site .family_site > ul li {
    display: block;
    margin: 0;
}
footer .footer_wrap .conts .related_site .family_site > ul li a {
    display: block;
    padding: 6px 16px;
    font-size: 12px;
    font-weight: 400;
    color: #D4D6D4;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
footer .footer_wrap .conts .related_site .family_site > ul li a:hover {
    color: #FFFFFF;
}
footer .footer_wrap .conts .related_site .family_site.on > h2 a {
    border-color: #434545;
}
footer .footer_wrap .conts .related_site .family_site.on > h2 a::after {
    transform: rotate(180deg);
}
footer .footer_wrap .conts .related_site .family_site.on > ul {
    display: block;
    border: 1px solid #434545;
}
footer .footer_wrap .conts .ref_link {
    display: flex;
    justify-content: flex-start;
    flex-shrink: initial;
    margin-top: 48px;
}
footer .footer_wrap .conts .ref_link div {
    margin-right: 80px;
}
footer .footer_wrap .conts .ref_link div h2 {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
footer .footer_wrap .conts .ref_link div h2 a {
    color: #9B9E9C;
}
footer .footer_wrap .conts .ref_link div ul li a {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    text-decoration: none;
}
footer .footer_wrap .conts .ref_link div ul li + li {
    margin-top: 16px;
}
footer .footer_wrap .conts .ref_link div .bold {
    font-weight: 700;
}
footer .footer_wrap .conts .ref_link div .white {
    color: #FFFFFF;
}
footer .footer_wrap .conts .ref_link div.sub ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 224px;
}
footer .footer_wrap .conts .ref_link div.sub ul li {
    width: 50%;
    margin-top: 0;
}
footer .footer_wrap .conts .ref_link div.sub ul li a {
    font-size: 14px;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 24px;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
footer .footer_wrap .conts .ref_link div.sub ul li:nth-child(n+3) {
    margin-top: 16px;
}
footer .footer_wrap .conts .site_info {
    margin-top: 68px;
}
footer .footer_wrap .conts .site_info h2 {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
footer .footer_wrap .conts .site_info ul {
    display: flex;
    flex-wrap: wrap;
    width: 760px;
}
footer .footer_wrap .conts .site_info ul li {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
footer .footer_wrap .conts .site_info ul li + li {
    position: relative;
    margin-left: 8px;
    padding-left: 9px;
}
footer .footer_wrap .conts .site_info ul li + li::after {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    width: 1px;
    height: 10px;
    background: #727573;
}
footer .footer_wrap .conts .site_info ul li + li.break {
    margin-left: 0;
    padding-left: 0;
}
footer .footer_wrap .conts .site_info ul li + li.break::after {
    display: none;
}
footer .footer_wrap .conts .site_info ul li + li.break, footer .footer_wrap .conts .site_info ul li + li.break ~ li {
    margin-top: 6px;
}
footer .footer_wrap .conts .site_info ul li a {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    line-height: inherit;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
footer .footer_wrap .conts .site_info ul li a:hover {
    text-decoration: underline;
}
footer .footer_wrap .conts .site_info .copyright {
    margin-top: 32px;
    font-size: 14px;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
footer .footer_wrap .conts .quality_certification {
    position: absolute;
    bottom: 0;
    right: 0;
}
footer .footer_wrap .conts .quality_certification > h2 {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
footer .footer_wrap .conts .quality_certification > ul > li {
    display: inline-block;
}
footer .footer_wrap .conts .quality_certification > ul > li a {
    display: block;
}
footer .footer_wrap .conts .quality_certification > ul > li a > img {
    height: 40px;
}
footer .footer_wrap .conts .quality_certification > ul > li + li {
    margin-left: 12px;
}
footer .footer_wrap .conts .quality_certification > ul > li:last-child {
    margin-left: 0;
}
footer .footer_wrap .conts .sel_language {
    position: absolute;
    right: 0;
    bottom: 68px;
    width: 105px;
}
footer .footer_wrap .conts .sel_language > h2 a {
    display: block;
    width: 100%;
    padding: 11px 36px 11px 14px;
    border: 1px solid transparent;
    box-sizing: border-box;
    background: #171A19;
    font-size: 14px;
    font-weight: 400;
    color: #D4D6D4;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
footer .footer_wrap .conts .sel_language > h2 a::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 16px;
    margin-top: -8px;
    width: 16px;
    height: 16px;
    background-image: url("/images/webhome2/ico_arrow_down_16_gray.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
footer .footer_wrap .conts .sel_language > ul {
    display: none;
    overflow-y: auto;
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    width: 100%;
    z-index: 10;
    max-height: 331px;
    padding: 6px 0;
    box-sizing: border-box;
    background-color: #171A19;
}
footer .footer_wrap .conts .sel_language > ul li {
    display: block;
    margin: 0;
}
footer .footer_wrap .conts .sel_language > ul li a {
    display: block;
    padding: 6px 16px;
    font-size: 12px;
    font-weight: 400;
    color: #D4D6D4;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
footer .footer_wrap .conts .sel_language > ul li a:hover {
    color: #FFFFFF;
    text-decoration: underline;
}
footer .footer_wrap .conts .sel_language.on > h2 a {
    border-color: #434545;
}
footer .footer_wrap .conts .sel_language.on > h2 a::after {
    transform: rotate(180deg);
}
footer .footer_wrap .conts .sel_language.on > ul {
    display: block;
    border: 1px solid #434545;
}

/* //푸터 정의 */
.floating {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 50;
}
.floating .chat {
    margin-bottom: 12px;
}
.floating .top {
    text-align: right;
}
.floating .top a {
    display: inline-block;
    width: 72px;
    height: 72px;
    width: 72px;
    height: 72px;
    background-image: url("/images/webhome2/ico_top_72_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.floating .top a span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.floating .chat_message {
    width: 70px;
    border-radius: 35px;
    overflow: hidden;
    height: 70px;
    transition: 0.3s;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    cursor: pointer;
}
.floating .chat_message.active {
    width: 400px;
    transition: width 0.3s;
}
.floating .chat_message a:hover {
    text-decoration: none;
}
.floating .chat_message .chat_open_wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 80px;
    background-color: #fff;
}
.floating .chat_message .chat_open_btn {
    width: 70px;
    height: 70px;
    background: url("https://www.ehyundai.com/images/webhome/outlets/layout/chatbot_off.png") no-repeat;
    background-size: 70px 70px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
}
.floating .chat_message .chat_close_btn {
    display: none;
    width: 25px;
    height: 25px;
    background: url("https://www.ehyundai.com/images/webhome/outlets/layout/chatbot_close.png") no-repeat;
    background-size: 100%;
    font-size: 0;
    margin-left: 30px;
    position: absolute;
    right: 0;
}
.floating .chat_message.active .chat_close_btn {
    display: block;
}
.floating .chat_message .chat_btn_txt_wrap {
    width: 305px;
}
.floating .chat_message .chat_btn_txt_wrap .chat_btn_txt {
    display: none;
    font-size: 18px;
    color: #222;
    z-index: -2;
    padding-right: 30px;
    height: 70px;
    line-height: 70px;
}
.floating .chat_message .chat_btn_txt_wrap .chat_btn_txt.on {
    display: inline-block !important;
    height: 70px;
}

input::placeholder {
    color: #9B9E9C;
}

.paging_wrap {
    text-align: center;
}
.paging_wrap .blind {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.paging_wrap .paging_inner {
    display: inline-flex;
    position: relative;
    padding: 0 68px;
}
.paging_wrap .paging_inner [class^=ctrl_] {
    overflow: hidden;
    position: absolute;
    top: 0;
    background-color: #FFFFFF;
}
.paging_wrap .paging_inner .ctrl_front {
    left: 0;
    width: 18px;
    height: 18px;
    background-image: url("/images/webhome2/ico_paging_front_18.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.paging_wrap .paging_inner .ctrl_prev {
    left: 26px;
    width: 18px;
    height: 18px;
    background-image: url("/images/webhome2/ico_paging_prev_18.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.paging_wrap .paging_inner .ctrl_next {
    right: 26px;
    width: 18px;
    height: 18px;
    background-image: url("/images/webhome2/ico_paging_next_18.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.paging_wrap .paging_inner .ctrl_end {
    right: 0;
    width: 18px;
    height: 18px;
    background-image: url("/images/webhome2/ico_paging_end_18.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.paging_wrap .paging_inner > span:not([class^=ctrl_]),
.paging_wrap .paging_inner > a:not([class^=ctrl_]) {
    display: inline-block;
    min-width: 18px;
    height: 18px;
    font-size: 14px;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 18px;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    text-align: center;
}
.paging_wrap .paging_inner > span:not([class^=ctrl_]).on {
    color: #171A19;
    text-decoration: underline;
}
.paging_wrap .paging_inner > *:not([class^=ctrl_]) + *:not([class^=ctrl_]) {
    margin-left: 8px;
}

/* 개발 요청 페이징 */
.paging {
    text-align: center;
}
.paging > a,
.paging > strong {
    display: inline-block;
    min-width: 18px;
    height: 18px;
    font-size: 14px;
    font-weight: 400;
    color: #9B9E9C;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    text-align: center;
}
.paging > a.first, .paging > a.prev, .paging > a.next, .paging > a.last {
    background-color: #FFFFFF;
}
.paging > a.prev {
    margin-right: 22px;
}
.paging > a.prev + * {
    margin-left: 0;
}
.paging > a.next {
    margin-left: 22px;
}
.paging > strong.on {
    color: #171A19;
    text-decoration: underline;
}
.paging > * + * {
    margin-left: 5px;
}

/* //개발 요청 페이징 */
/* 기본 */
.ui-calendar-container {
    width: 288px;
}
.ui-calendar-container .ui-select-day {
    padding: 24px;
    box-shadow: 0px 4px 8px rgba(41, 51, 49, 0.3);
}
.ui-calendar-container .ui-select-day .ui-calendar-header-second {
    position: relative;
    padding: 0 20px;
    text-align: center;
}
.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-now {
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-now em {
    font-style: normal;
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-now span {
    vertical-align: top;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    line-height: inherit;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-now .ui-year-select {
    display: inline-block;
    margin-right: 10px;
    margin-left: -15px;
}
.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-now .ui-year-select .ui-selectbox-wrap {
    display: inline-block;
    position: relative;
    /* 230214 잔여업무리스트 16 수정 (공통수정사항) */
    /* //230214 잔여업무리스트 16 수정 (공통수정사항) */
}
.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-now .ui-year-select .ui-selectbox-wrap + em {
    margin-left: 8px;
}
.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-now .ui-year-select .ui-selectbox-wrap .ui-selectbox-view .ui-select-button {
    position: relative;
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-now .ui-year-select .ui-selectbox-wrap .ui-selectbox-view .ui-select-button .hide {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-now .ui-year-select .ui-selectbox-wrap .ui-selectbox-view .ui-select-button .ico {
    position: absolute;
    top: 2px;
    right: -4px;
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/ico_dropdown_20.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-now .ui-year-select .ui-selectbox-wrap .ui-selectbox-list {
    position: absolute;
    top: 30px;
    left: 0;
    width: 79px;
    padding: 12px 18px;
    background-color: #FFFFFF;
    border: 1px solid #EAEBEA;
    box-sizing: border-box;
}
.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-now .ui-year-select .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea .ui-select-scrollarea-ul li a {
    font-size: 14px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    text-decoration: none;
}
.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-now .ui-year-select .ui-selectbox-wrap .ui-selectbox-list .ui-select-scrollarea .ui-select-scrollarea-ul li + li {
    margin-top: 8px;
}
.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-now .ui-year-select .ui-selectbox-wrap.on .ico {
    transform: rotate(180deg);
}
.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-now > * {
    display: inline-block;
    vertical-align: middle;
}
.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-prev,
.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-next {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/ico_arrow_right_20_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    font-size: 0;
    line-height: 0;
}
.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-prev {
    left: 0;
    transform: rotate(180deg);
}
.ui-calendar-container .ui-select-day .ui-calendar-header-second .ui-calendar-next {
    right: 0;
}
.ui-calendar-container .ui-select-day .ui-calendar-date {
    margin: 10px -6px 0;
}
.ui-calendar-container .ui-select-day .ui-calendar-date table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}
.ui-calendar-container .ui-select-day .ui-calendar-date table th, .ui-calendar-container .ui-select-day .ui-calendar-date table td {
    text-align: center;
}
.ui-calendar-container .ui-select-day .ui-calendar-date table th {
    padding: 5px 6px;
    font-size: 12px;
    font-weight: 700;
    color: #727573;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.ui-calendar-container .ui-select-day .ui-calendar-date table td {
    padding: 5px 6px;
    font-size: 12px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.ui-calendar-container .ui-select-day .ui-calendar-date table td .ui-calendar-day,
.ui-calendar-container .ui-select-day .ui-calendar-date table td .ui-calendar-extend {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
}
.ui-calendar-container .ui-select-day .ui-calendar-date table .ui-calendar-active button.ui-calendar-day {
    background: #00815A;
    color: #FFFFFF;
}
.ui-calendar-container .ui-select-day .ui-calendar-date table .ui-calendar-holiday .ui-calendar-day {
    background: #171A19;
    color: #FFFFFF;
}
.ui-calendar-container .ui-select-day .ui-calendar-date table .ui-calendar-extend .ui-calendar-day {
    background: #F5DCEA;
}
.ui-calendar-container .ui-select-day .ui-calendar-date table .ui-calendar-today .ui-calendar-day {
    background: #FFFFFF;
    color: #171A19;
    border: 1px solid #00815A;
}
.ui-calendar-container .ui-select-day .ui-calendar-date table .ui-calendar-today.ui-calendar-holiday .ui-calendar-day {
    background: #171A19;
    color: #FFFFFF;
}
.ui-calendar-container .ui-select-day .ui-calendar-date table .ui-calendar-today.ui-calendar-extend .ui-calendar-day {
    background: #F5DCEA;
}

/* 지점안내 특화 */
.ui_store_calendar .ui-calendar-container {
    width: 100%;
}
.ui_store_calendar .ui-calendar-container .ui-select-day {
    padding-bottom: 10px;
    box-shadow: none;
}

/* 인풋 폼용 */
.calendar_wrap {
    font-size: 0;
}
.calendar_wrap * {
    vertical-align: top;
}
.calendar_wrap .input_c {
    display: inline-block;
}
.calendar_wrap .input_c .input_wrap input {
    display: inline-block;
    width: 180px;
    height: 44px;
}
.calendar_wrap .input_c .input_wrap .input_cal {
    display: inline-block;
    margin: 11px 0 0 9px;
    margin-left: 9px;
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/ico_calendar_20.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    vertical-align: middle;
}
.calendar_wrap .input_c .input_wrap .input_cal > span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}
.calendar_wrap .wave {
    display: inline-block;
    margin: 0 6px 0 17px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.calendar_wrap .sel_wrap {
    display: inline-block;
    margin-left: 10px;
}
.calendar_wrap .sel_wrap .ui-selectbox-wrap.on .ui-select-button {
    line-height: 40px;
}
.calendar_wrap .sel_wrap .ui-selectbox-wrap.on .ui-select-button .ui-select-text {
    margin: -2px 0 0 0;
}
.calendar_wrap .sel_wrap .ui-selectbox-wrap .ui-select-button {
    padding: 0 12px 0 16px;
    line-height: 42px;
}
.calendar_wrap .sel_wrap .ui-selectbox-wrap .ui-select-button .ico {
    top: 14px;
    right: 12px;
}
.calendar_wrap .sel_wrap .ui-selectbox-wrap .ui-select-button .ui-select-text {
    font-size: 14px;
}
.calendar_wrap .sel_wrap .ui-selectbox-wrap.on .ui-select-button {
    line-height: 42px;
}
.calendar_wrap .sel_wrap .ui-selectbox-wrap.on .ui-selectbox-list .ui-select-scrollarea-ul > li {
    padding: 12px 16px;
}
.calendar_wrap .sel_wrap .ui-selectbox-wrap.on .ui-selectbox-list .ui-select-scrollarea-ul > li > a {
    font-size: 14px;
}
.calendar_wrap .sel_wrap + .input_c {
    margin-left: 8px;
}
.calendar_wrap .btn {
    margin-left: 7px;
}
.calendar_wrap .btn.black {
    height: 44px;
    padding: 11px 20px 12px;
    border-radius: 4px;
    background-color: #171A19;
    line-height: 22px;
}
.calendar_wrap .btn.black > span {
    display: inline-block;
    min-width: 40px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}

/* 간격용 클래스 */
.mt32 {
    margin-top: 32px;
}

/* 약관 */
.policy_wrap + .policy_wrap {
    margin-top: 56px;
    padding-top: 56px;
    border-top: 1px solid #EAEBEA;
}
.policy_wrap .footer > div {
    display: flex;
    justify-content: right;
}
.policy_wrap .footer > div + div {
    margin-top: 40px;
}
.policy_wrap .footer > div > p + p {
    margin-top: 0;
}
.policy_wrap .footer > div > p:nth-child(2) {
    width: 238px;
    text-align: right;
}
.policy_wrap .footer > div > p:nth-child(3) {
    align-self: flex-end;
    padding-left: 32px;
}
.policy_wrap .footer.line {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 40px;
}
.policy_wrap .footer.line > span {
    width: 10%;
    text-align: center;
}
.policy_wrap .footer.line > span:first-child {
    width: 17%;
}
.policy_wrap h1 {
    margin-bottom: 40px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap h2 {
    margin-top: 40px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap ol > li + li {
    margin-top: 8px;
}
.policy_wrap ol > li > ol > li {
    margin-top: 0;
}
.policy_wrap h3.title:not(:first-child) {
    margin-top: 100px;
}
.policy_wrap h4 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap h4:not(:first-child) {
    margin-top: 56px;
}
.policy_wrap h5 {
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap h5.fw_n {
    font-weight: normal;
}
.policy_wrap .tit {
    display: block;
    margin-top: 16px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap p, .policy_wrap li {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap p + ol {
    padding: 4px 0 0 22px;
}
.policy_wrap p + ul {
    margin: 12px 0;
}
.policy_wrap p + p {
    margin-top: 4px;
}
.policy_wrap p + .btn_link {
    display: inline-block;
    margin-top: 4px;
}
.policy_wrap .solo_txt {
    margin-bottom: 40px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap .solo_txt.foot {
    margin-top: 40px;
}
.policy_wrap .solo_txt.date {
    margin-bottom: 40px;
    text-align: center;
}
.policy_wrap .img_policy {
    margin-top: 12px;
}
.policy_wrap .img_policy + .step_dl {
    margin-top: 32px;
}
.policy_wrap .step_dl dl {
    display: flex;
}
.policy_wrap .step_dl dl dt {
    width: 69px;
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap .step_dl dl dd {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap .step_dl dl + dl {
    margin-top: 12px;
}
.policy_wrap .solo li {
    margin-top: 0 !important;
}
.policy_wrap .tab_wrap .tabs li {
    margin-top: 0;
}
.policy_wrap li:not(:first-child) {
    margin-top: 4px;
}
.policy_wrap li > ol, .policy_wrap li ul {
    padding: 4px 0 0 22px;
}
.policy_wrap li > ol > li, .policy_wrap li ul > li {
    margin-top: 0 !important;
}
.policy_wrap .bullet_list {
    margin-top: 56px;
}
.policy_wrap .txt_desc {
    margin-top: 20px;
}
.policy_wrap .txt_desc + .txt_desc {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 400;
    color: #434545;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap .cnt01 {
    margin-top: 40px;
}
.policy_wrap .cnt12 {
    margin-top: 12px;
}
.policy_wrap .cnt70 {
    margin-top: 70px;
}
.policy_wrap + .btn_wrap {
    margin-top: 56px;
    padding-top: 24px;
    border-top: 1px solid #EAEBEA;
    text-align: right;
}
.policy_wrap .focus_box {
    padding: 26px;
    border: 1px solid #EAEBEA;
}
.policy_wrap .focus_box > li + li {
    margin-top: 12px;
}
.policy_wrap .focus_box label {
    padding-left: 34px;
}
.policy_wrap strong.no_tit {
    display: block;
    margin-bottom: 16px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}

/* //약관 */
/* 약관2 */
.policy_wrap01 {
    padding-top: 24px;
}
.policy_wrap01 .tit_div + .tit_div {
    margin-top: 56px;
}
.policy_wrap01 .tit_div h2 {
    font-size: 22px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap01 .tit_div h2 + p {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap01 .tit_div > ol {
    margin-top: 40px;
}
.policy_wrap01 .tit_div > ol > li {
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap01 .tit_div > ol > li > ol {
    margin-top: 12px;
}
.policy_wrap01 .tit_div > ol > li > ol > li {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap01 .tit_div > ol > li .input_wrap {
    margin-top: 16px;
}
.policy_wrap01 .tit_div > ol > li + li {
    margin-top: 48px;
}
.policy_wrap01 .tit_div > ol > li > p {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap01.accordian .tit_div h2.s_tit {
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap01.accordian .tit_div h3.s_tit {
    margin-top: 48px;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap01.accordian .tit_div h4.s_tit {
    margin-bottom: 40px;
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap01.accordian .tit_div h4.s_tit.solo {
    margin-top: 48px;
    margin-bottom: 16px;
}
.policy_wrap01.accordian .tit_div + .tit_div {
    margin-top: 64px;
}
.policy_wrap01.accordian .tit_div + .bullet_list {
    margin-top: 48px;
}
.policy_wrap01.accordian .tit_div .ui_accordion > ul > li + li {
    margin-top: 64px;
}
.policy_wrap01.accordian .tit_div .ui_accordion > ul > li + li.mt48 {
    margin-top: 48px;
}
.policy_wrap01.accordian .tit_div .ui_accordion .head > a {
    position: relative;
    width: 100%;
    display: block;
    padding-bottom: 12px;
    text-decoration: none;
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap01.accordian .tit_div .ui_accordion .head > a:after {
    content: "";
    position: absolute;
    top: 0;
    left: initial;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-image: url("/images/webhome2/ico_accordion_arrow_20_active.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: rotate(180deg);
}
.policy_wrap01.accordian .tit_div .ui_accordion .on .head > a {
    border: none;
}
.policy_wrap01.accordian .tit_div .ui_accordion .on .head > a:after {
    transform: rotate(0);
}
.policy_wrap01.accordian .tit_div .ui_accordion .sub_rdo .chk_wrap {
    margin-right: 20px;
}
.policy_wrap01.accordian .tit_div .ui_accordion .sub_rdo .chk_wrap > label > span {
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap01.accordian .tit_div .ui_accordion .sub_rdo .chk_wrap.sm {
    margin-right: 16px;
}
.policy_wrap01.accordian .tit_div .ui_accordion .sub_rdo .chk_wrap.sm > label > span {
    font-size: 14px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap01.accordian .tit_div .ui_accordion .ui_accord_content .d_terms {
    padding: 28px;
    margin-top: 12px;
    margin-bottom: 24px;
    border-top: 1px solid #EAEBEA;
    border-bottom: 1px solid #EAEBEA;
    background-color: #F7FAF9;
}
.policy_wrap01.accordian .tit_div .ui_accordion .ui_accord_content .d_terms > p {
    font-size: 14px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap01.accordian .tit_div .ui_accordion .ui_accord_content .d_terms > p + p {
    margin-top: 16px;
}
.policy_wrap01.accordian .tit_div .ui_accordion .ui_accord_content .d_terms .table_wrap + .bullet_list {
    margin-top: 20px;
}
.policy_wrap01.accordian .tit_div .ui_accordion .ui_accord_content .d_terms .bullet_list {
    margin-top: 8px;
}
.policy_wrap01.accordian .tit_div .ui_accordion .ui_accord_content .d_terms .bullet_list > li {
    color: #727573;
}
.policy_wrap01.accordian .tit_div .ui_accordion .ui_accord_content .d_terms + .input_wrap {
    margin-top: 24px;
}
.policy_wrap01.accordian .tit_div .ui_accordion .ui_accord_content .d_terms > dl > dt {
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap01.accordian .tit_div .ui_accordion .ui_accord_content .d_terms > dl > dd {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.policy_wrap01.accordian .tit_div .ui_accordion .ui_accord_content .d_terms > dl > dd > p {
    margin-bottom: 20px;
}
.policy_wrap01.accordian .tit_div .ui_accordion .ui_accord_content .d_terms > dl > dd > ol > li + li {
    margin-top: 20px;
}
.policy_wrap01.accordian .tit_div .ui_accordion .ui_accord_content .d_terms > dl + dl {
    margin-top: 40px;
}

/* //약관2 */
.wrap_policy03 h6.big01 {
    font-size: 22px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    margin-bottom: 24px;
    margin-top: 64px;
}
.wrap_policy03 h6.big01:first-child {
    margin-top: 0;
}
.wrap_policy03 p.tspace02 {
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    margin-bottom: 16px;
}
.wrap_policy03 p.tspace02 + ul {
    margin-bottom: 24px;
}
.wrap_policy03 p.tspace02 + ul li {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    margin-top: 8px;
}
.wrap_policy03 p.tspace02 + ul li:first-child {
    margin-top: 0;
}
.wrap_policy03 p.tspace02 + ul li > ul {
    margin-top: 4px;
}
.wrap_policy03 p.tspace02 + ul li > ul > li {
    margin-top: 0;
}
.wrap_policy03 p.tspace02 + ul li > ul > li span {
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    margin-top: 4px;
}
.wrap_policy03 p.tspace02 + ul li > ul > li span + span {
    margin-bottom: 4px;
    margin-top: 0;
}
.wrap_policy03 p.tspace02 + ul li > p {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    margin-top: 4px;
}
.wrap_policy03 p.tspace02 + p {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    margin-bottom: 24px;
}
.wrap_policy03 p.tspace02 + p + ul {
    margin-top: 4px;
}
.wrap_policy03 p.tspace02 + p + ul > li {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy03 p.tspace02 + p + ul + .tspace02 {
    margin-top: 24px;
}

.wrap_policy04 h4 {
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    margin-top: 56px;
}
.wrap_policy04 h4 + p {
    margin-top: 20px;
}
.wrap_policy04 h4 + ol {
    margin-top: 20px;
}
.wrap_policy04 h4:first-child {
    margin-top: 0;
}
.wrap_policy04 p {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy04 p + ol {
    margin-left: 24px;
    margin-top: 4px;
}
.wrap_policy04 p + ol > li {
    margin-top: 0;
}
.wrap_policy04 ol li {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    margin-top: 12px;
}
.wrap_policy04 ol li:first-child {
    margin-top: 0;
}
.wrap_policy04 ul.bullet_list {
    margin-top: 12px;
}

/* 카드신청 > 약관 (현행기준) */
.popup_terms {
    margin-top: 24px;
}
.popup_terms p + .big01, .popup_terms ul + .big01, .popup_terms ol + .big01, .popup_terms li + .big01 {
    margin-top: 48px;
}
.popup_terms p + .tspace02, .popup_terms ul + .tspace02, .popup_terms ol + .tspace02, .popup_terms li + .tspace02 {
    margin-top: 28px;
}
.popup_terms p {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.popup_terms p span {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    line-height: inherit;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.popup_terms p + ul, .popup_terms p + ol {
    margin-top: 4px;
}
.popup_terms p + .privacy {
    margin-top: 16px;
}
.popup_terms > ul li,
.popup_terms > ol li {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.popup_terms > ul li + li,
.popup_terms > ol li + li {
    margin-top: 8px;
}
.popup_terms > ul li > p,
.popup_terms > ol li > p {
    margin-top: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.popup_terms > ul li > ul,
.popup_terms > ol li > ul {
    margin-top: 4px;
}
.popup_terms > ul li > ul > li,
.popup_terms > ol li > ul > li {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.popup_terms > ul + p,
.popup_terms > ol + p {
    margin-top: 8px;
}
.popup_terms > ul + .privacy,
.popup_terms > ol + .privacy {
    margin-top: 16px;
}
.popup_terms a {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    line-height: inherit;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.popup_terms a:hover {
    text-decoration: underline;
}
.popup_terms .big01 {
    margin-bottom: 40px;
    font-size: 22px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.popup_terms .tspace02 {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.popup_terms .tspace02 + p {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.popup_terms .tspace02 + ul {
    margin-top: 4px;
}
.popup_terms .tspace02 + ul > li {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.popup_terms .tspace02 + ul > li + li {
    margin-top: 8px;
}
.popup_terms .tspace02 + ul > li > p {
    margin-top: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.popup_terms .tspace02 + ul > li > p.sub_txt {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #434545;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.popup_terms .tspace02 + ul > li > ul {
    margin-top: 4px;
}
.popup_terms .tspace02 + ul > li > ul > li {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.popup_terms .tspace03 > li {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.popup_terms .tspace03 > li > strong {
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.popup_terms .tspace03 > li.br {
    margin-top: 40px;
}
.popup_terms .privacy + .tspace02 {
    margin-top: 28px;
}
.popup_terms .happypoint_terms {
    margin: 40px 0;
    padding: 20px 0;
    border-top: 1px solid #D4D6D4;
    border-bottom: 1px solid #D4D6D4;
}
.popup_terms .happypoint_terms > ul {
    display: flex;
    flex-wrap: wrap;
}
.popup_terms .happypoint_terms > ul li {
    width: 50%;
}
.popup_terms .happypoint_terms > ul li a {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.popup_terms .happypoint_terms > ul li:nth-child(n+3) {
    margin-top: 8px;
}
.popup_terms .sub_txt {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #434545;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.popup_terms .bullet {
    position: relative;
}
.popup_terms .bullet.dot {
    margin-top: 8px;
    padding-left: 11px;
}
.popup_terms .bullet.dot:before {
    width: 4px;
    height: 4px;
    background-color: #434545;
    content: "";
    position: absolute;
    top: 9px;
    left: 0;
    bottom: 0;
    right: 0;
    transform: rotate(45deg);
}
.popup_terms .underline {
    text-decoration: underline;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    line-height: inherit;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}

/* 법인카드 > 약관 (현행기준) */
.wrap_policy h4 {
    display: block;
    margin-top: 64px;
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy h5 {
    display: block;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy p, .wrap_policy a {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy > ol > li {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy > ol > li + li {
    margin-top: 8px;
}
.wrap_policy > ol + h5 {
    margin-top: 24px;
}

/* 	회원 > 개인정보처리방침 > 개인정보취급(처리)방침L(현행기준) */
.wrap_policy.member .br12 {
    display: block;
    height: 12px;
}
.wrap_policy.member h4 {
    display: block;
    margin-top: 64px;
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy.member h5 {
    display: block;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy.member p, .wrap_policy.member a {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy.member a:hover {
    text-decoration: underline;
}
.wrap_policy.member P + ul {
    margin-top: 24px;
}
.wrap_policy.member P + ol {
    margin-top: 24px;
}
.wrap_policy.member > ol,
.wrap_policy.member > ul {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy.member > ol > li,
.wrap_policy.member > ul > li {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy.member > ol > li + li,
.wrap_policy.member > ul > li + li {
    margin-top: 8px;
}
.wrap_policy.member > ol > li > ol,
.wrap_policy.member > ol > li > ul,
.wrap_policy.member > ul > li > ol,
.wrap_policy.member > ul > li > ul {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy.member > ol > li > ol > li,
.wrap_policy.member > ol > li > ul > li,
.wrap_policy.member > ul > li > ol > li,
.wrap_policy.member > ul > li > ul > li {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy.member > ol > li > ol > li + li,
.wrap_policy.member > ol > li > ul > li + li,
.wrap_policy.member > ul > li > ol > li + li,
.wrap_policy.member > ul > li > ul > li + li {
    margin-top: 4px;
}
.wrap_policy.member > ol > li > ol > li > ol,
.wrap_policy.member > ol > li > ol > li > ul,
.wrap_policy.member > ol > li > ul > li > ol,
.wrap_policy.member > ol > li > ul > li > ul,
.wrap_policy.member > ul > li > ol > li > ol,
.wrap_policy.member > ul > li > ol > li > ul,
.wrap_policy.member > ul > li > ul > li > ol,
.wrap_policy.member > ul > li > ul > li > ul {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy.member > ol > li > ol > li > ol > li,
.wrap_policy.member > ol > li > ol > li > ul > li,
.wrap_policy.member > ol > li > ul > li > ol > li,
.wrap_policy.member > ol > li > ul > li > ul > li,
.wrap_policy.member > ul > li > ol > li > ol > li,
.wrap_policy.member > ul > li > ol > li > ul > li,
.wrap_policy.member > ul > li > ul > li > ol > li,
.wrap_policy.member > ul > li > ul > li > ul > li {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy.member > ol > li > ol > li > ol > li + li,
.wrap_policy.member > ol > li > ol > li > ul > li + li,
.wrap_policy.member > ol > li > ul > li > ol > li + li,
.wrap_policy.member > ol > li > ul > li > ul > li + li,
.wrap_policy.member > ul > li > ol > li > ol > li + li,
.wrap_policy.member > ul > li > ol > li > ul > li + li,
.wrap_policy.member > ul > li > ul > li > ol > li + li,
.wrap_policy.member > ul > li > ul > li > ul > li + li {
    margin-top: 4px;
}
.wrap_policy.member > ol + h5,
.wrap_policy.member > ul + h5 {
    margin-top: 24px;
}
.wrap_policy.member .bllist_type2 {
    margin-top: 8px;
}
.wrap_policy.member .bllist_type2 > li {
    margin-top: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy.member .bllist_type3 {
    margin-top: 8px;
    margin-left: 8px;
}
.wrap_policy.member .bllist_type3 > li {
    margin-top: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy.member .policymenu {
    margin-top: 48px;
    padding: 24px;
    background-color: #F0F5F4;
}
.wrap_policy.member .policymenu > ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: -12px;
}
.wrap_policy.member .policymenu > ul li {
    margin-top: 12px;
}
.wrap_policy.member .policymenu > ul li:nth-child(odd) {
    flex: 1 1 47%;
}
.wrap_policy.member .policymenu > ul li:nth-child(even) {
    flex: 1 1 53%;
}
.wrap_policy.member .policymenu > ul li a {
    font-size: 16px;
    font-weight: 400;
    color: #171A19 !important;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
    text-decoration: underline;
}
.wrap_policy.member .policymenu + p {
    margin-top: 16px;
}
.wrap_policy.member .tb_type2 {
    border-top: 1px solid #434545;
}
.wrap_policy.member .tb_type2.tspace02 {
    margin-top: 24px;
}
.wrap_policy.member .tb_type2.bspace02 {
    margin-bottom: 24px;
}
.wrap_policy.member .tb_type2 table {
    width: 100%;
    border-collapse: collapse;
}
.wrap_policy.member .tb_type2 table thead th {
    padding: 14px 0;
    background-color: #F0F5F4;
    border-left: 1px solid #FFFFFF;
    text-align: center;
}
.wrap_policy.member .tb_type2 table thead th:first-child {
    border-left: none;
}
.wrap_policy.member .tb_type2 table thead th.bd_l {
    border-left: 1px solid #FFFFFF;
}
.wrap_policy.member .tb_type2 table thead th.bd_t {
    border-top: 1px solid #FFFFFF;
}
.wrap_policy.member .tb_type2 table thead th.bd_b {
    border-bottom: 1px solid #FFFFFF;
}
.wrap_policy.member .tb_type2 table tbody th {
    border-bottom: 1px solid #eaebea;
    border-left: 1px solid #eaebea;
    font-weight: normal;
}
.wrap_policy.member .tb_type2 table tbody th.first {
    border-left: none !important;
}
.wrap_policy.member .tb_type2 table tbody th.al_center {
    text-align: center;
}
.wrap_policy.member .tb_type2 table tbody th.al_right {
    text-align: right;
}
.wrap_policy.member .tb_type2 table tbody td {
    padding: 20px;
    border-bottom: 1px solid #eaebea;
    border-left: 1px solid #eaebea;
    background-color: #FFFFFF;
    /* 데이터 없을 경우 */
}
.wrap_policy.member .tb_type2 table tbody td.first {
    border-left: none;
}
.wrap_policy.member .tb_type2 table tbody td.al_center {
    text-align: center;
}
.wrap_policy.member .tb_type2 table tbody td.al_center .bullet_list {
    display: inline-block;
}
.wrap_policy.member .tb_type2 table tbody td.al_right {
    text-align: right;
}
.wrap_policy.member .tb_type2 table tbody td .fc_red {
    color: #FF383B;
}
.wrap_policy.member .tb_type2 table tbody td .fc_khaki {
    color: #00805A;
}
.wrap_policy.member .tb_type2 table tbody td .fc_gray02 {
    color: #727573;
}
.wrap_policy.member .tb_type2 table tbody td .no_result {
    padding: 20px 0;
    text-align: center;
}
.wrap_policy.member .tb_type2 table tbody td .no_result p {
    font-size: 14px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy.member .tb_type2 table tfoot td {
    padding: 20px;
    border-bottom: 1px solid #eaebea;
    border-left: 1px solid #eaebea;
    background-color: #FFFFFF;
}
.wrap_policy.member .tb_type2 table tfoot td.first {
    border-left: none;
}
.wrap_policy.member .tb_type2 table tfoot td.al_center {
    text-align: center;
}
.wrap_policy.member .tb_type2 table tfoot td.al_center .bullet_list {
    display: inline-block;
}
.wrap_policy.member .tb_type2 table tfoot .al_right {
    text-align: right;
}
.wrap_policy.member .tb_type2 .fir {
    border-left: none;
}
.wrap_policy.member .tb_type2 a {
    font-size: 14px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy.member .tb_type2 tr > td:first-child {
    border-left: 1px solid #FFFFFF;
}
.wrap_policy .paragraph {
    margin-top: 32px;
}
.wrap_policy .paragraph + .paragraph {
    margin-top: 48px;
}
.wrap_policy .paragraph > strong {
    font-size: 18px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy .paragraph > strong + p {
    margin-top: 16px;
}
.wrap_policy .paragraph > strong > img {
    width: 32px;
    height: 32px;
    vertical-align: -9px;
}
.wrap_policy .paragraph p {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy .paragraph p + p {
    margin-top: 12px;
}
.wrap_policy .paragraph > p {
    margin-top: 16px;
}
.wrap_policy .paragraph > p br:first-child {
    content: "";
    display: inline-block;
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
    display: block;
}
.wrap_policy .paragraph .label_table {
    width: 100%;
    margin-top: 48px;
    border-spacing: 8px;
}
.wrap_policy .paragraph .label_table th {
    padding-bottom: 16px;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy .paragraph .label_table td {
    text-align: center;
    padding: 20px;
    background-color: #F7FAF9;
}
.wrap_policy .paragraph .label_table td img {
    display: inline-block;
    width: 64px;
    margin-bottom: 12px;
}
.wrap_policy .paragraph .label_table td a {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy .paragraph .label_table + p {
    margin-top: 12px;
}
.wrap_policy .paragraph .label_table + p > strong {
    font-size: 14px;
    font-weight: 400;
    color: #434545;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy .paragraph .btn_type16.tspace01 {
    margin-top: 8px;
}
.wrap_policy .paragraph .btn_type16 > a {
    font-size: 14px;
    font-weight: 700;
    color: #00815A;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy .toggle_wrap {
    margin-top: 8px;
}
.wrap_policy .toggle_wrap + .paragraph {
    margin-top: 48px;
}
.wrap_policy .toggle_wrap table {
    width: 100%;
    border-collapse: collapse;
}
.wrap_policy .toggle_wrap table thead th {
    padding: 14px 0;
    background-color: #F0F5F4;
    border-left: 1px solid #FFFFFF;
    text-align: center;
}
.wrap_policy .toggle_wrap table thead th:first-child {
    border-left: none;
}
.wrap_policy .toggle_wrap table thead th.bd_l {
    border-left: 1px solid #FFFFFF;
}
.wrap_policy .toggle_wrap table thead th.bd_t {
    border-top: 1px solid #FFFFFF;
}
.wrap_policy .toggle_wrap table thead th.bd_b {
    border-bottom: 1px solid #FFFFFF;
}
.wrap_policy .toggle_wrap table tbody th {
    border-bottom: 1px solid #eaebea;
    border-left: 1px solid #eaebea;
    font-weight: normal;
}
.wrap_policy .toggle_wrap table tbody th.first {
    border-left: none !important;
}
.wrap_policy .toggle_wrap table tbody th.al_center {
    text-align: center;
}
.wrap_policy .toggle_wrap table tbody th.al_right {
    text-align: right;
}
.wrap_policy .toggle_wrap table tbody td {
    padding: 20px;
    border-bottom: 1px solid #eaebea;
    border-left: 1px solid #eaebea;
    background-color: #FFFFFF;
    /* 데이터 없을 경우 */
}
.wrap_policy .toggle_wrap table tbody td.first {
    border-left: none;
}
.wrap_policy .toggle_wrap table tbody td.al_center {
    text-align: center;
}
.wrap_policy .toggle_wrap table tbody td.al_center .bullet_list {
    display: inline-block;
}
.wrap_policy .toggle_wrap table tbody td.al_right {
    text-align: right;
}
.wrap_policy .toggle_wrap table tbody td .fc_red {
    color: #FF383B;
}
.wrap_policy .toggle_wrap table tbody td .fc_khaki {
    color: #00805A;
}
.wrap_policy .toggle_wrap table tbody td .fc_gray02 {
    color: #727573;
}
.wrap_policy .toggle_wrap table tbody td .no_result {
    padding: 20px 0;
    text-align: center;
}
.wrap_policy .toggle_wrap table tbody td .no_result p {
    font-size: 14px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy .toggle_wrap table tfoot td {
    padding: 20px;
    border-bottom: 1px solid #eaebea;
    border-left: 1px solid #eaebea;
    background-color: #FFFFFF;
}
.wrap_policy .toggle_wrap table tfoot td.first {
    border-left: none;
}
.wrap_policy .toggle_wrap table tfoot td.al_center {
    text-align: center;
}
.wrap_policy .toggle_wrap table tfoot td.al_center .bullet_list {
    display: inline-block;
}
.wrap_policy .toggle_wrap table tfoot .al_right {
    text-align: right;
}
.wrap_policy .toggle_wrap table {
    position: relative;
}
.wrap_policy .toggle_wrap table:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: #434545;
}
.wrap_policy .toggle_wrap tr > td:first-child {
    border-left: 1px solid #FFFFFF;
}
.wrap_policy .toggle_wrap .depth1 > li {
    width: 100%;
    border-top: 1px solid #EAEBEA;
    border-bottom: 1px solid #EAEBEA;
    background-color: #F7F7F7;
}
.wrap_policy .toggle_wrap .depth1 > li.on .head > a::after {
    transform: rotate(-180deg);
}
.wrap_policy .toggle_wrap .depth1 > li .head {
    padding: 12px 24px;
}
.wrap_policy .toggle_wrap .depth1 > li .head > a::after {
    content: "";
    display: inline-block;
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    vertical-align: top;
    width: 16px;
    height: 16px;
    background-image: url("/images/webhome2/ico_arrow_dwon_16_black.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.wrap_policy .toggle_wrap .depth1 > li .ui_accord_content {
    padding: 16px 24px 24px;
    background-color: #FFFFFF;
}
.wrap_policy .toggle_wrap .depth1 > li .ui_accord_content > ul > li,
.wrap_policy .toggle_wrap .depth1 > li .ui_accord_content > ol > li {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy .toggle_wrap .depth1 > li .ui_accord_content > ul > li + li,
.wrap_policy .toggle_wrap .depth1 > li .ui_accord_content > ol > li + li {
    margin-top: 12px;
}
.wrap_policy .toggle_wrap .depth1 > li .ui_accord_content > ul > li > p,
.wrap_policy .toggle_wrap .depth1 > li .ui_accord_content > ol > li > p {
    margin-top: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy .toggle_wrap .depth1 > li .ui_accord_content > ul > li > ul,
.wrap_policy .toggle_wrap .depth1 > li .ui_accord_content > ul > li > ol,
.wrap_policy .toggle_wrap .depth1 > li .ui_accord_content > ol > li > ul,
.wrap_policy .toggle_wrap .depth1 > li .ui_accord_content > ol > li > ol {
    margin-top: 4px;
}
.wrap_policy .toggle_wrap .depth1 > li .ui_accord_content > ul > li > ul > li,
.wrap_policy .toggle_wrap .depth1 > li .ui_accord_content > ul > li > ol > li,
.wrap_policy .toggle_wrap .depth1 > li .ui_accord_content > ol > li > ul > li,
.wrap_policy .toggle_wrap .depth1 > li .ui_accord_content > ol > li > ol > li {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.wrap_policy .toggle_wrap + p {
    margin-top: 12px;
}
.wrap_policy .text_box {
    margin-top: 16px;
}
.wrap_policy .text_box > ul + ul {
    margin-top: 4px;
}
.wrap_policy .text_box > ul > li {
    font-size: 16px;
    font-weight: 400;
    color: #171A19;
    line-height: 1.4;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}

@media (min-width: 1880px) {
    html,
body {
        overflow-x: hidden;
    }
}

.hidden, legend, caption, hr, .blind, .hide {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
}

fieldset {
    border: none;
}

a:hover, a:focus {
    text-decoration: none;
}

img {
    vertical-align: top;
}

/* 스킵네비게이션 */
nav.skip a {
    position: absolute;
    top: -100px;
    width: 100%;
    z-index: 150;
    background-color: #434545;
    padding: 5px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
nav.skip a:focus, nav.skip a:active {
    top: 0;
}

/* //스킵네비게이션 */
/* 다운로드 진행표 테이븚 */
.download_wrap table {
    width: 100%;
    border-collapse: collapse;
}
.download_wrap table thead th {
    padding: 14px 0;
    background-color: #F0F5F4;
    border-left: 1px solid #FFFFFF;
    text-align: center;
}
.download_wrap table thead th:first-child {
    border-left: none;
}
.download_wrap table thead th.bd_l {
    border-left: 1px solid #FFFFFF;
}
.download_wrap table thead th.bd_t {
    border-top: 1px solid #FFFFFF;
}
.download_wrap table thead th.bd_b {
    border-bottom: 1px solid #FFFFFF;
}
.download_wrap table tbody th {
    border-bottom: 1px solid #eaebea;
    border-left: 1px solid #eaebea;
    font-weight: normal;
}
.download_wrap table tbody th.first {
    border-left: none !important;
}
.download_wrap table tbody th.al_center {
    text-align: center;
}
.download_wrap table tbody th.al_right {
    text-align: right;
}
.download_wrap table tbody td {
    padding: 20px;
    border-bottom: 1px solid #eaebea;
    border-left: 1px solid #eaebea;
    background-color: #FFFFFF;
    /* 데이터 없을 경우 */
}
.download_wrap table tbody td.first {
    border-left: none;
}
.download_wrap table tbody td.al_center {
    text-align: center;
}
.download_wrap table tbody td.al_center .bullet_list {
    display: inline-block;
}
.download_wrap table tbody td.al_right {
    text-align: right;
}
.download_wrap table tbody td .fc_red {
    color: #FF383B;
}
.download_wrap table tbody td .fc_khaki {
    color: #00805A;
}
.download_wrap table tbody td .fc_gray02 {
    color: #727573;
}
.download_wrap table tbody td .no_result {
    padding: 20px 0;
    text-align: center;
}
.download_wrap table tbody td .no_result p {
    font-size: 14px;
    font-weight: 400;
    color: #727573;
    line-height: 1.5;
    letter-spacing: -0.25px;
    font-family: "Happiness-Sans";
}
.download_wrap table tfoot td {
    padding: 20px;
    border-bottom: 1px solid #eaebea;
    border-left: 1px solid #eaebea;
    background-color: #FFFFFF;
}
.download_wrap table tfoot td.first {
    border-left: none;
}
.download_wrap table tfoot td.al_center {
    text-align: center;
}
.download_wrap table tfoot td.al_center .bullet_list {
    display: inline-block;
}
.download_wrap table tfoot .al_right {
    text-align: right;
}