@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";


/* 블렛 정의 */
.bullet {
	&.square {
		> li {
			position:relative;
			padding-left:13px;
			color:$white;
			&:before {
				width:5px;
				height:5px;
				background-color:$white;
				transform:rotate(45deg);
				@include sudo_pos(8px,0,0,0);
			}
		}
	}
}

//dot
.bullet_list {
	> li {
		position:relative;
		@include font(14px,400,$gray01,1.5);
		> a {
			color:$black;
			&.t_under {
				color:$gray01;
			}
		}
		.bar {
			margin-top:8px;
			> li {
				padding-left:10px;
				@include font(14px,400,$gray02,1.5);
				&:before {
					width:5px;
					height:1px;
					background-color:$gray02;
					@include sudo_pos(8px,0,0,0);
				}
				& + li {
					margin-top:4px !important;
				}
			}
		}
	}
	&.dot {
		> li {
			padding-left:11px;
			&:before {
				// content:' ';
				// position:absolute;
				// top:7px;
				// left:0;
				width:4px;
				height:4px;
				background-color:$gray01;
				@include sudo_pos(7px,0,0,0);
				transform:rotate(45deg);
			}
			& + li {
				margin-top:8px;
			}
		}
	}
	&.bar {
		> li {
			padding-left:10px;
			&:before {
				width:5px;
				height:1px;
				background-color:$gray02;
				@include sudo_pos(8px,0,0,0);
			}
		}
	}
	&.star {
		> li {
			padding-left:10px;
			&:before {
				content:'*' !important;
				color:$gray02;
				@include sudo_pos(3px,0,0,-2px);
			}
		}
	}
}
/* //블렛 정의 */