@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";


/* 탭 정의 */
.tab_wrap {
	.tabs {
		ul {
			display:flex;
			justify-content: flex-start;
			li {
				margin-left:32px;
				&:nth-child(1){
					margin-left:0;
				}
				a {
					position:relative;
					text-decoration:none;
					@include font(24px,700,$gray03,1.3) //230217_디자인변경사항 :컬러변경
				}
				&.on {
					a {
						color:$black;
						&::after {
							border-width: 4px;
							border-style: solid;
							border-color: $black transparent transparent $black;
							// @include sudo_dis(4px,0,0,6px);
							@include sudo_pos(4px,-12px,0,initial);
							width:0;
							height:0;
						}
						> em {
							@include hidden;
						}
					}
				}
				
				// a태그 안에 span이 있는 경우
				a {
					> span {
						position:relative;
						@include font(14px,400,$gray02,1.5);
					}
					&:after {
						content:none;
					}
					.hide {
						@include hidden;
					}
				}
				&.on {
					a {
						> span {
							@include font(14px,700,$black,1.5);
							&:after {
								border-width: 3px;
								border-style: solid;
								border-color: $black transparent transparent $black;
								@include sudo_pos(4px,-12px,0,initial);
								width:0;
								height:0;
							}
						}
					}
				}
			}
		}
	}

	/* case PLO1.6.1T ~ PLO1.6.2T */
	&.tab_sub {
		.tabs {
			ul {
				li {
					// flex-basis:50%;
					a {
						// display:inline-block;
						// width:100%;
						// height:48px;
						// padding:0 20px;
						// box-sizing:border-box;
						// border:2px solid #f3f3f3;
						// background-color:$white;
						// @include font(14px,700,$gray03,48px);
						// text-align:center;
					}
					// &.on {
					// 	a {
					// 		border:2px solid $black;
					// 		background-color:transparent;
					// 		@include font(14px,700,$black,48px);
					// 		&:after {
					// 			display:none;
					// 		}
					// 	}
					// }
					// & + li {
					// 	margin-left:0px;
					// }
				}
			}
		}
	}

	// 박스타입
	@at-root .lay_pop & {
		.tabs {
			padding: 40px 0 32px;
		}
	}
	&.type_box {
		.tabs {
			ul {
				border: 1px solid $gray04;
				li {
					flex: 1 0 0;
					position: relative;
					z-index: 0;
					min-height: 48px;
					margin-left: 0;
					box-sizing: border-box;
					background: $white;
					a {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 100%;
						height: 100%;
						@include font(14px,400,$gray02,1.5);
					}
					& + li {
						border-left: 1px solid $gray04;
					}
					&.on {
						z-index: 1;
						&::before {
							content: '';
							position: absolute; //세모아이콘은 레이어로 떠야함
							top: -1px;
							left: -1px;
							right: -1px;
							bottom: -1px;
							border: 2px solid $black;
							box-sizing: border-box;
						}
						a {
							position: relative;
							font-weight: 700;
							color: $black;
							&::after {
								content: '';
								content: none;
								display: inline-block;
								position: relative;
								top: auto;
								left: auto;
								right: auto;
								bottom: auto;
								margin-top: -8px;
								margin-left: 2px;
								border-width: 3px;
								border-style: solid;
								border-color: $black transparent transparent $black;
							}
						}
						a {
							> span {
								@include font(14px,700,$black,1.5);
								&:after {
									border-width: 3px;
									border-style: solid;
									border-color: $black transparent transparent $black;
									// @include sudo_pos(4px,-12px,0,initial);
									@include sudo_pos(3px,-8px,0,initial);
									width:0;
									height:0;
								}
							}
						}
					}
				}
				&.col7 {
					flex-wrap: wrap;
					> li {
						flex: 0 1 auto;
						width: calc(100% / 7);
						&:nth-child(n+8) {
							border-top: 1px solid $gray04;
						}
						&:nth-child(7n+1) {
							border-left: 0 none;
						}
						&.on a::after {
							margin-right: -8px;
						}
					}
				}
			}
		}
	}
}
/* //탭 정의 */