@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";


/* GNB 정의 */
#head {
	position:fixed;
	z-index:55;
	top:32px;
	left:0;
	right:0;
	width:1440px;
	height:70px;
	margin:0 auto;
	background-color:$white;
	// &.top {
	// 	top:0;
	// 	width:100%;
	// 	.header_wrap {
	// 		.main {
	// 			> ul {
	// 				> li {
	// 					&.on {
	// 						.sub {
	// 							width:1344px;
	// 							left:50%;
	// 							margin-left:-720px;
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }
	.header_wrap {
		margin:0 auto;
		width:1440px;
		display:flex;
		justify-content: space-between;
		> div {
			display:inline-flex;
			align-items: center;
			.logo {
				position:relative;
				margin-top:5px;
				h1 {
					display:inline-block;
					margin-left:32px;
					@include bg(139px,22px,'/images/webhome2/logo.png');
					span {
						@include hidden;
					}
					a {
						display:block;
						width:100%;
						height:100%;
					}
				}
				button {
					display:inline-block;
					vertical-align: top;
					margin-top:2px;
					margin-left:8px;
					@include bg(20px,20px,'/images/webhome2/btn_gnb_20.png');
					span {
						@include hidden;
					}
				}
				.brand_gate {
					// display:none;
					visibility:hidden;
					opacity: 0;
					height:0;
					position:absolute;
					z-index:5;
					top:46px;
					left:0;
					width:231px;
					padding:12px 0 20px;
					background-color: $white;
					transition: all 0.1s ease-in;
					overflow: hidden;
					ul {
						width:100%;
						li {
							padding-top:16px;
							padding-left:51px;
							padding-bottom:16px;
							&:first-child {
								padding-top:0;
								a {
									&:before {
										background-image: url('/images/webhome2/logo_hdd_gate_logo_36.png');
									}
								}
							}
							& + li {
								border-top:1px solid $gray05;
							}
							a {
								@include font(18px,400,$black,1.5);
								text-decoration:none;
								&::before {
									@include sudo_dis(-4px,12px,0,0);
									@include bg(36px,36px,'/images/webhome2/logo_hdc_gate_logo_36.png');
									
								}
							}
						}
					}
				}
				&.on {
					button {
						transform: rotate(-180deg);
					}
					.brand_gate {
						visibility: visible;
						opacity: 1;
						height:105px;
						transition: all 0.3s ease-in-out;
					}
				}
			}
			.main {
				margin-left:16px;
				h2 {
					@include hidden;
				}
				> ul {
					display:flex;
					align-items: center;
					height:70px;
					> li {
						display:inline-block;
						padding-right:16px;
						padding-left:16px;
						> a {
							position:relative;
							@include font(14px,400,$black,1.4);
							&:hover {
								text-decoration:none;
								font-weight:bold;
								&:after {
									@include sudo_pos($left:0,$bottom:-6px);
									width:100%;
									height:2px;
									background-color:$black;
								}
							}
						}
						&.on {
							> a {
								font-weight:bold;
								&:after {
									@include sudo_pos($left:0,$bottom:-6px);
									width:100%;
									height:2px;
									background-color:$black;
								}
							}
							.sub {
								display:block;
							}
						}
						
						//메가메뉴(오리지널)
						nav.sub {
							display:none;
							position:absolute;
							top:70px;
							left:50%;
							width:1344px;
							margin-left:-720px;
							padding:40px 0 64px;
							background-color: $white;
							> div {
								display:inline-block;
								width:220px;
								margin-left:56px;
								p {
									@include font(32px,700,$black,1.3);
									span {
										@include hidden;
									}
								}
							}
							> ul {
								display:inline-block;
								margin-top:9px;
								vertical-align: top;
								 > li {
									display:inline-block;
									vertical-align:top;
									margin-left:40px;
									&:first-child {
										margin-left:32px;
									}
									> a {
										@include font(16px,700,$black,1.3);
										&:after {
											@include sudo_dis(-1px,0,0,0);
											@include bg(20px,20px, '/images/webhome2/ico_arrow_right_14.png')
										}
									} 
									> span {
										@include font(16px,700,$black,1.3);
									}
									> ul {
										margin-top:20px;
										> li {
											margin-bottom:12px;
											&:last-child {
												margin-bottom:0;
											}
											> a {
												@include font(14px,400,$black,1.3);
												&.link:after {
													@include sudo_dis(4px,0,0,2px);
													@include bg(16px,16px,'/images/webhome2/ico_pc_link_16_black.png');
												}
												&.dis {
													pointer-events: none;
													@include font(14px,400,$gray04,1.3);
												}
											}
										}
									}
								}
							}
							.event_sec {
								display:inline-block;
								position:absolute;
								top:40px;
								right:40px;
								width:284px;
								text-align:right;
								> a {
									img {
										width:284px;
										height:240px;
									}
								}
							}
						}
						//메가메뉴(오리지널)//
						//메가메뉴(현행)
						div.sub {
							display:none;
							position:absolute;
							top:70px;
							left:50%;
							width:1344px;
							margin-left:-720px;
							padding:40px 0 64px;
							background-color: $white;
							> div {
								display:inline-block;
								width:220px;
								margin-left:56px;
								p {
									@include font(32px,700,$black,1.3);
									span {
										@include hidden;
									}
								}
							}
							> ul {
								display:inline-block;
								margin-top:9px;
								vertical-align: top;
								 > li {
									display:inline-block;
									vertical-align:top;
									margin-left:40px;
									&:first-child {
										margin-left:32px;
									}
									.name {
										span {
											@include font(16px,700,$black,1.3);
										}
										a {
											@include font(16px,700,$black,1.3);
											&:after {
												@include sudo_dis(-1px,0,0,0);
												@include bg(20px,20px, '/images/webhome2/ico_arrow_right_14.png')
											}
										}
									}
									.navigations {
										> ul {
											margin-top:20px;
											> li {
												margin-bottom:12px;
												&:last-child {
													margin-bottom:0;
												}
												> a {
													@include font(14px,400,$black,1.3);
													&.link:after {
														@include sudo_dis(4px,0,0,2px);
														@include bg(16px,16px,'/images/webhome2/ico_pc_link_16_black.png');
													}
													&.dis {
														pointer-events: none;
														@include font(14px,400,$gray04,1.3);
													}
												}
											}
										}
									}
								}
							}
							.event_sec {
								display:inline-block;
								position:absolute;
								top:40px;
								right:40px;
								width:284px;
								text-align:right;
								> a {
									img {
										width:284px;
										height:240px;
									}
								}
							}
						}
						//메가메뉴(현행)//
					}
				}
			}
			.member_service {
				h2 {
					@include hidden;
				}	
				ul {
					li {
						display:inline-block;
						padding-right:8px;
						padding-left:8px;
						a {
							@include font(12px,400,$gray02,1.4){
								text-decoration:none;
							};
						}
						.sub {
							display:none;
						}
					}
				}
			}
			.lang_choice {
				position:relative;
				padding-right:20px;
				> a {
					display:block;
					width:40px;
					height:24px;
					@include bg(40px,24px,'/images/webhome2/btn_lang.png');
					h2 {
						@include hidden;
					}
				}
				&.on {
					> a {
						transform: rotateX(180deg)
					}
				}
				.lang_list {
					// display:none;
					visibility:hidden;
					opacity: 0;
					height:0;
					position:absolute;
					z-index:5;
					top:20px;
					left:-12px;
					padding:12px;
					background-color: $white;
					transition: all 0.1s ease-in;
					overflow: hidden;
					ul {
						width:100%;
						li {
							& + li {
								padding-top:16px;
							}
							a {
								@include font(12px,400,$black,1.5);
								text-decoration:none;
							}
						}
					}
				}
				&.on {
					.lang_list {
						visibility: visible;
						opacity: 1;
						height:94px;
						transition: all 0.3s ease-in-out;
					}
				}
			}
			.brand_search {
				> a {
					width:190px;
					height:70px;
					background-color:$black;
					text-decoration:none;
					@include el-center;
					&:after {
						@include sudo_dis(0,0,0,6px);
						@include bg(20px,20px,'/images/webhome2/ico_gnb_search_20.png');
					} 
					span {
						@include font(14px,700,$white,1.3);
						
					}
					&:hover,&:focus {
						background-color:$green;
					}
				}
				&.on {
					.search_wrap {
						display:block;
						& + .dim {
							position:fixed;
							z-index:-1;
							top:0;
							left:0;
							right:0;
							bottom:0;
							background-color:#000000;
							opacity:0.4;
						}
					}
				}
				.search_wrap {
					display:none;
					position:fixed;
					z-index:53;
					top:0;
					left:0;
					right:0;
					height:296px;
					padding-top:88px;
					background-color:$white;
					.condition {
						margin:0 auto;
						.input_wrap,
						form {
							display:flex;
							align-items: center;
							justify-content: center;
							.sel {
								margin-right:8px;
								height:74px;
								.ui-selectbox-wrap {
									position:relative;
									width:100% !important;
									&.on {
										.ui-select-button {
											position:relative;
											padding:21px 22px 22px;
											border-left:2px solid $black;
											border-right:2px solid $black;
											border-top:2px solid $black;
											border-bottom:none;
											&::after {
												@include sudo_pos(initial,0,-1px,0);
												z-index:1;
												border-bottom:1px solid #eaebea;
												height:1px;
											}
											.ico {
												top:23px;
												right:22px;
												@include bg(24px,24px,'/images/webhome2/ico_dropdown_up_24.png');
											}
										}
									}
									.ui-select-button {
										display:block;
										// width:255px;
										width:288px;
										padding:22px 23px;
										border-radius:0;
										background-color: $white;
										border:1px solid #eaebea;
										.ico {
											position:absolute;
											top:25px;
											right:24px;
											@include bg(24px,24px,'/images/webhome2/ico_dropdown_24.png');
										}
										.ui-select-text {
											vertical-align:middle;
											@include font(18px,700,$black,1.5);
										}
										.hide {
											@include hidden;
										}
									}
									.ui-selectbox-list {
										position:relative;
										// width:251px;
										width:284px;
										max-height: 116px;
										overflow: auto;
										padding:20px 24px;
										background-color:$white;
										border-left:2px solid $black;
										border-right:2px solid $black;
										border-bottom:2px solid $black;
										.ui-select-scrollarea-ul{
											> li {
												> a {
													@include font(18px,400,$black,1.5);
													&.disabled {
														color:$gray04;
													}
												}
												&.on {
													> a {
														@include font(18px,700,$hgreen,1.5);
													}
												}
												& + li {
													margin-top:12px;
												}
											}
										}
									}
								}
							}
							.input {
								display:flex;
								position:relative;
								input[type=text] {
									width:565px;
									height:74px;
									border-radius:0;
									&:focus, &:active, &:visited {
										outline:none;
										border:2px solid $black;
									}
									// &:valid {
									// 	& + .auto_complete {
									// 		display:block;
									// 	}
									// }
								}
								button {
									position:relative;
									width:74px;
									height:74px;
									background-color:$black;
									span{
										@include hidden;
									}
									&:before {
										@include sudo_dis(0,0,0,0); 
										@include bg(32px,32px,'/images/webhome2/ico_search_32_white.png');
									}
								}
								.auto_complete {
									display:none;
									position:absolute;
									top:74px;
									width: calc(100% - 4px);
									background-color:$white;
									border:2px solid $black;
									> ul {
										max-height:105px;
										overflow:auto;
										padding:24px 4px 24px 24px;
										> li {
											> a {
												@include font(18px,400,$black,1.5);
												&.on {
													color:$hgreen;
												}
												> strong {
													color:$hgreen;
												}
											}
											& + li {
												margin-top:8px;
											}
										}
									}
								}
							}
						}
					}
					.keyword {
						display:flex;
						align-items:center;
						justify-content: center;
						margin-top:72px;
						> dl {
							 dt {
								 margin-bottom:20px;
								 text-align: center;
								 @include tfont(28px,900,$black,1.5)
							 }
							 dd {
								 ul {
									 li {
										 display:inline-block;
										 a {
											 @include font(18px,700,$black,1.5);
										 }
										 & + li {
											margin-left:24px;
										}
									 }
								 }
							 }
						}
					}
					.close {
						position:fixed;
						top:40px;
						left: calc(50% + 600px);
						@include bg(32px,32px,'/images/webhome2/ico_close_32_black.png');
						> span {
							@include hidden;
						}
					}
				}
			}
		}
	}
	& + .dim { // 메가메뉴 확장 시 딤처리
		display:none;
		position:fixed;
		z-index:52;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background-color:#000000;
		opacity:0.4;
	}
	&.sub{
		top:0;
		width:100%;
		.header_wrap{
			width:calc(100% - 480px);
			min-width:1440px;
			> div {
				.logo {
					h1 {
						margin-left:0;
					}
				}
				.main {
					> ul {
						> li {
							.sub {
								width:calc(100% - 480px);
								min-width:1440px;
								margin-left:calc(max(1440px, (100% - 480px))/-2);
								> div {
									margin-left:32px;
								}
								.event_sec {
									right:0;
								}
							}
						}
					}
				}
			}
		}
	}
}
/* //GNB 정의 */