@charset "UTF-8";

@import "_var.scss";
@import "_mixins.scss";

/* 폼 */

.input, .input_wrap {
	position:relative;
	input[type="text"],
	input[type="password"],
	input[type="number"],
	input[type="button"],
	input[type="email"] {
		width:100%;
		height:52px;
		padding:0 24px;
		border-radius:4px;
		border:1px solid $gray05;
		box-sizing:border-box;
		outline-color:$black;
		@include font(16px,400,$black,1.4);
		&:active {
			border-color:$gray05;
		}
		&.btn {
			padding-right:126px;
		}
		&:read-only {
			background-color:#F7f7f7;
			border-color: $gray05;
		}
		&:disabled{
			background-color:#F7f7f7;
			border-color: $gray05;
			color:$gray04;
		}
		&::placeholder {
			@include font(14px,400,$gray03,1.4); //230222_디자인변경사항
		}
		&:focus,&:active {
			border:2px solid $hgreen;
			// outline: transparent;
		}
	}
	.btn_wrap {
		position:absolute;
		top:50%;
		right:20px;
		transform: translateY(-50%);
	}
	& + .input_hint {
		margin-top:8px;
		@include font(14px,400,$gray02,1.4);
	}
}

.input_wrap {
	display:flex;
	& + .bar_type {
		margin-top:20px;
	}
	&.input_type {
		padding-right:154px;
	}
	&.bar_type {
		> div {
			display:inline-flex;
			align-self: center;
			&:before {
				content:' ';
				position:absolute;
				// top:30px;
				top: 50%;
				left:-16px;
				width:6px;
				height:1px;
				background: $black;
			}
			&:first-child:before {
				display:none;
			}
			&.txt {
				margin-left:12px;
				@include font(16px,400,$black,1.5);
				&:before {
					content: none;
				}
			}
			&.input input {
				// padding: 0 16px;
				padding: 0 24px;
			}
		}
		
	}
	&.date_type {
		> div {
			margin-left:0;
		}
		> span {
			display:inline-block;
			margin: 12px 0 0 8px;
			padding-right: 4px;
			@include font(16px,400,$black,1.5);
			& + div {
				margin-left:8px;
				&.btn_wrap {
					margin-left:16px;
				}
			}
			& + .rdo_wrap {
				margin-left:42px;
			}
			&.tit_span {
				margin-left:16px;
			}
			&.bar {
				margin-left:16px;
				& + div {
					margin-left:16px;
				}
			}
			& + button {
				margin-left:12px;
			}
		}
		.rdo_wrap {
			margin-top:13px;
		}
		& + .bar {
			display:block;
			width:714px;
			height:24px;
			text-align:center;
		}
	}
	&.mix_type {
		> div {
			margin-left:12px;
		}
		> span {
			display:inline-block;
			margin: 12px 0 0 8px;
			@include font(16px,400,$black,1.5);
			& + div {
				margin-left:8px;
			}
		}
	}
	&.mail_type {
		> div {
			width:calc((100% / 3) - 16px);
			margin-left:32px;
			&:nth-child(2) {
				width:231px;
				margin-right:-26px;
			}
			&:nth-child(2):before {
				content:'@';
				position:absolute;
				top:50%;
				left:-22px;
				transform: translateY(-50%);
			}
		}
	}
	&.cal_type {
		.input {
			position:relative;
			> button {
				position:absolute;
				top:16px;
				right:20px;
				margin-top:0;
			}
			input {
				&:read-only {
					background-color:$white;
				}
			}
		}
	}
	&.normal_type {
		> div {
			margin-left:8px;
		}
	}
	&.card_choice {
		.rdo_wrap {
			& + .rdo_wrap {
				margin-left:8px;
			}
			input {
				&:checked {
					& + label {
						border-color: $black;
					}
				}
			}
		}
		label {
			display:block;
			text-align: center;
			width:169px;
			border:1px solid $gray05;
			border-radius: 4px;
			padding:8px 0 16px;
			cursor: pointer;
			> span {
				@include font(16px,700,$black,1.5);	
			}
			&::before {
				display:block;
				position:static;
				margin:0 auto;
			}
			&.card01 {
				&::before {
					@include bg(155px,92px,'/images/webhome2/card/img_check_card01.jpg');
				}
			}
			&.card02 {
				&::before {
					@include bg(155px,92px,'/images/webhome2/card/img_check_card02.jpg');
				}
			}
			&.card03 {
				&::before {
					@include bg(155px,92px,'/images/webhome2/card/img_check_card03.jpg');
				}
			}
			&.card04 {
				&::before {
					@include bg(155px,92px,'/images/webhome2/card/img_check_card04.jpg');
				}
			}
		}
	}
	> div {
		position:relative;
		// width:calc((100% / 3) - 16px);
		margin-left:25px;
		&:first-child {
			margin-left:0;
		}
	}
}

.password_wrap {
	display: inline-flex;
	justify-content: flex-start;
	align-self: center;
	margin-left:12px !important;
	.masked {
		position:relative;
		&::before {
			width:12px;
			height:12px;
			border-radius: 16px;
			background-color: $black;
			@include sudo_dis(4px,8px,0,0);
		}
		&::after {
			width:12px;
			height:12px;
			border-radius: 16px;
			background-color: $black;
			@include sudo_dis(4px,12px,0,0);
		}
	}
	& + div {
		align-self: center;
		margin-left:12px;

	}
}


.caution_wrap {
	width:100%;
	margin-top:12px;
	.text {
		color:$red;
	}
	.btn_wrap {
		float:right;
		margin-top:12px;
	}
}

.rdo_wrap {
	display:inline-block;
	position:relative;
	&.error {
		label {
			padding-left:32px;
			> span {
				@include font(16px,400,$ared,1.4);
			}
		}
	}
	input {
		@include hidden;
		&:disabled {
			& + label {
				&:before {
					@include bg(24px,24px,'/images/webhome2/ico_radio_normal_24.png');
				}
				> span {
					color:$gray04;
				}
			}
		}
		&:disabled:checked {
			& + label {
				&:before {
					@include bg(24px,24px,'/images/webhome2/ico_radio_disable_24.png');
				}
				> span {
					color:$gray04;
				}
			}
		}
	}
	input + label {
		&:before {
			content:' ';
			position:absolute;
			top:0;
			left:0;
			@include bg(24px,24px,'/images/webhome2/ico_radio_normal_24.png');
		}
	}
	input {
		&:focus + label {
			outline:2px solid $green;
			// outline: 0;
		}
	}
	input:checked + label {
		&:before {
			@include bg(24px,24px,'/images/webhome2/ico_radio_active_24.png');
		}
	}
	label {
		padding-left:32px;
		> span {
			@include font(16px,400,$black,1.4);
		}
	}
	&.solo {
		label {
			padding:1px 0 4px 23px;
			> span {
				@include hidden;
			}
		}
	}
	&.sm {
		input + label {
			&:before {
				@include bg(24px,24px,'/images/webhome2/ico_check_s_normal_24.png');
			}
			> span {
				color:$gray02;
			}
		}
		input:checked + label {
			&:before {
				@include bg(24px,24px,'/images/webhome2/ico_check_s_active_24.png');
			}
			> span {
				color:$black;
			}
		}
	}
	&.type_txt {
		margin-top:0 !important;
		input + label {
			display:inline-flex;
			align-items: center;
			justify-content: center;
			padding-left:0;
			padding:10px 0;
			border:1px solid $gray05;
			cursor: pointer;
			z-index:1;
			&:before {
				content: none;
			}
			> span {
				@include font(14px,400,$gray03,1.5);
			}
		}
		input:checked + label {
			position:relative;
			z-index:5;
			border:1px solid $black;
			> span {
				@include font(14px,700,$black,1.5);
			}
		}
		& + .type_txt {
			margin-left:-1px !important;
		}
	}
}

.chk_wrap {
	display:inline-block;
	position:relative;
	&.error {
		label {
			padding-left:32px;
			> span {
				@include font(16px,400,$ared,1.4);
			}
		}
	}
	input {
		@include hidden;
		&:disabled {
			& + label {
				&:before {
					@include bg(24px,24px,'/images/webhome2/ico_check_disable_24.png');
				}
			}
		}
	}
	input + label {
		&:before {
			content:' ';
			position:absolute;
			top:0;
			left:0;
			@include bg(24px,24px,'/images/webhome2/ico_check_normal_24.png');
		}
	}
	
	input {
		&:focus + label {
			outline:1px solid $black;
			// outline: 0;
		}
	}
	input:checked + label {
		&:before {
			@include bg(24px,24px,'/images/webhome2/ico_check_active_24.png');
		}
	}
	label {
		padding-left:32px;
		> span {
			@include font(16px,400,$black,1.4);
		}
	}
	&.solo {
		label {
			padding:1px 0 4px 23px;
			> span {
				@include hidden;
			}
		}
	}
	&.chk_s {
		label {
			padding-left:28px;
		}
		input + label {
			&:before {
				top:2px;
				@include bg(20px,20px,'/images/webhome2/ico_check_normal_24.png');
			}
		}
		& + .chk_s {
			margin-left:40px;
		}
	}
	&.sm {
		input + label {
			&:before {
				@include bg(24px,24px,'/images/webhome2/ico_check_s_normal_24.png');
			}
			> span {
				@include font(16px,400,$gray02,1.4); //230222_디자인변경사항 : PMY1.1.1.1
			}
		}
		input:checked + label,
		input:focus + label {
			&:before {
				@include bg(24px,24px,'/images/webhome2/ico_check_s_active_24.png');
			}
			> span {
				@include font(16px,400,$black,1.4); //230222_디자인변경사항 : PMY1.1.1.1
			}
		}
	}
}

.sel_wrap {
	.ui-selectbox-wrap {
		position:relative;
		width:100% !important;
		&.on {
			.ui-select-button {
				padding:0 20px 0 24px;
				// padding:0 16px 0 15px;// 가이드 변경??
				line-height:50px;
				border-left:1px solid $black;
				border-right:1px solid $black;
				border-top:1px solid $black;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
			.ico {
				top:10px;
				transform:rotate(180deg);
			}
		}
		.ui-select-button {
			display:block;
			padding:0 20px 0 24px;
			// padding:0 16px; // 가이드 변경??
			// border-radius:none;
			border-radius:4px;
			border:1px solid #eaebea;
			background-color:$white;
			line-height:50px;
			&.placeholder {
				.ui-select-text {
					@include font(16px,400,$gray03,1.5);
					vertical-align:middle;
				}
			}
			.ui-select-text {
				// @include font(16px,400,$black,1.5);
				@include font(16px,400,$black,1.5); // 공통변경?
				vertical-align:middle;
			}
			.ui-select-essen {
				@include font(16px,400,$gray04,1.5);
			}
			.hide {
				@include hidden;
			}
			.ico {
				position:absolute;
				top:16px;
				right:20px;
				@include bg(20px,20px,'/images/webhome2/ico_dropdown_20.png');
			}
		}
		.ui-selectbox-list {
			// display:block !important;
			position:absolute;
			z-index:1;
			width:100%;
			max-height: 155px;
			// height:114px; //PCA1.1.3.6.2.1T 내 셀렉트 공벡 때문에 변경
			overflow: auto;
			padding:0;
			background-color:$white;
			box-sizing:border-box;
			border-left:1px solid $black;
			border-right:1px solid $black;
			border-bottom:1px solid $black;
            border-radius:0 0 4px 4px;
			.ui-select-scrollarea-ul{
				> li {
					padding:12px 24px;
					> a {
						display:block;
						@include font(16px,400,$gray02,1.5) {
							text-decoration:none;
						};
					}
					&.on, &:hover, &:focus {
						background-color:$hgreen03;
						> a {
							@include font(16px,400,$black,1.5);
						}
					}
				}
			}
		}

		//하단위치 스타일
		&.up {
			&.on {
				.ui-select-button {
					// line-height:48px;
					border-left:1px solid $black;
					border-right:1px solid $black;
					border-bottom:1px solid $black;
					border-top:1px solid $gray05;
                    border-radius:0 0 4px 4px;
				}
			}
			.ui-selectbox-list {
				border-left:1px solid $black;
				border-right:1px solid $black;
				border-top:1px solid $black;
				border-bottom:1px solid transparent;
                border-radius:4px 4px 0 0;
			}
		}
	}
    &.small {
        .ui-selectbox-wrap {
            .ui-select-button {
                padding:0 12px 0 16px;
                height:42px;
                line-height:40px;
                .ico {
                    top:14px;
                    right:12px;
                }
                .ui-select-text {
                    font-size:14px;
                }
            }
            &.on {
                .ui-select-button {
                    line-height:40px;
                }
                .ui-selectbox-list {
                    .ui-select-scrollarea-ul {
                        > li    {
                            padding:12px 16px;
                            > a {
                                font-size:14px;
                            }
                        }
                    }   
                }
            }
        }
    }
}

.form_row {
	&.input_type {
		padding-right:154px;
	}
	.ico_required {
		display:inline-block;
		width:5px;
		height:5px;
		margin:4px 2px 0 2px;
		border-radius:50%;
		background-color:$green;
		vertical-align:top;
		.blind {
			@include hidden;
		}
	}
	> dl {
		display: flex;
		justify-content:flex-start;
		align-items: baseline;
		& + dl {
			margin-top:24px;
			// margin-top:32px; // 가이드?
			&.chk_top	{
				margin-top:32px;
			}
		}
		> dt {
			width:202px;
			flex: 0 0 auto;
			@include font(16px,700,$black,1.5);
			&.top_line {
				display: inline-flex;
				align-self: flex-start;
				padding-top:14px;
				vertical-align:top;
				&.multi {
					padding-top:2px;
				}
			}
			&.rdo_line {
				display: inline-flex;
				align-self: flex-start;
				padding-top:2px;
				vertical-align:top;
			}
		}
		> dd {
			flex: 1 1 auto;
			font-size: 0;
			.rdo_wrap {
				margin-bottom:8px;
			}
			.rdo_wrap + .rdo_wrap {
				margin-left:34px;
			}
			.bullet_list {
				margin-top:20px;
			}
		}
	}
}

.textarea {
	textarea {
		width:100%;
		padding:16px 24px;
		box-sizing:border-box;
		border-radius:4px;
		border:1px solid $gray05;
		@include font(16px,400,$black,1.5);
		vertical-align:top;
		resize:none;
		&::placeholder {
			color:$gray03;
		}
	}
}

//파일첨부 
.file_box {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	.upload_name {
		display: inline-block;
		width: 420px;
		vertical-align: middle;
		@include font(14px,400,$gray03,1.4);
	}
	label {
		display: inline-block;
		width: 125px;
		height: 52px;
		box-sizing: border-box;
		text-align: center;
		border-radius:4px;
		border:1px solid $gray01;
		margin-left: 8px;
		@include font(16px,700,$gray01,52px);
		cursor: pointer;
	}
	input[type="file"] {
		position: absolute;
		width: 0;
		height: 0;
		padding: 0;
		overflow: hidden;
		border: 0;
	}
	input[name="pfile"] {
		border: 0;
	}
}
.row_wrap {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: left;
	.down_file {
		display: inline-block;
		max-width: 420px;
		word-break: break-all;
		@include font(16px,400,$black,1.4);
	}
	.delete {
		min-width: 108px;
		display: inline-block;
		box-sizing: border-box;
		height: 34px;
		margin-left:24px;
		padding: 7px 20px 7px;
		border: 1px solid #171A19;
		background-color: #FFFFFF;
		text-decoration: none;
		text-align: center;
		@include font(14px,700,$black,1.4);
	}
}


//별점
.star_rangking {
	font-size: 0;
	.point {
		display:inline-block;
		@include bg(20px,20px,'/images/webhome2/ico_star_rating.png'){
			background-size:cover;
			background-position: left;
		};
		> span {
			@include hidden;
		}
		&.p01 {
			width:20px;
		}
		&.p02 {
			width:40px;
		}
		&.p03 {
			width:60px;
		}
		&.p04 {
			width:80px;
		}
		&.p05 {
			width:100px;
		}
	}
}
// 셀렉트박스 내 별점
.sel_wrap {
	.ui-selectbox-wrap {
		&.star {
			width:211px !important;
			.ui-select-button {
				display:flex;
				justify-content: flex-start;
				align-items: center;
				height:50px;
			}
			&.on {
				.ui-select-button {
					height:50px;
				}
			}
		}
	}
}

/* //폼 */