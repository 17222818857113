@charset "UTF-8";

//font-color
$black : #171A19;
$white : #FFFFFF;
$green : #00815A;
// $hgreen :#00805A;
$hgreen :#00815A; // 가이드가 아닌 것으로 확인되어 통일(green과)
$hgreen02 :#F0F5F4; // 테이블헤더 상단용
$hgreen03 :#F7FAF9;
$greenline: #D8E9E5;
$red : #FF383B;
$pink : #F5DCEA;
$hpink : #DD298D; // 버블
$ared: #EB3912;

$gray01 : #434545;
$gray02 : #727573;
$gray03 : #9B9E9C;
$gray04 : #D4D6D4;
$gray05 : #EAEBEA;
$gray06 : #F7F7F7; //PES1.1.1 디자인에 있어서 추가(0119)


//font-weight
$tit : 'Happiness-Sans-Title';

