@charset "UTF-8";


// 숨김 스타일(웹접근성)
@mixin hidden {
	position: absolute; //레이아웃에 영향을 주지 않게 해당 아이템 영역을 없앰
	width: 1px; //width, height값을 최소한의 크기로 조절.
	height: 1px;
	margin: -1px; //화면상 아예 안나오게.
	overflow: hidden; //overflow된 콘텐츠를 숨김
	clip-path: polygon(0 0, 0 0, 0 0); //클리핑 범위를 모두 0으로 지정해서 요소를 숨김
}


//기본 박스 스타일
@mixin box($pd:10px, $mt:20px, $b-color:$black, $b-radius:5px) {
	margin-top:$mt;
	padding:$pd;
	border-color:$b-color;
	border-radius:$b-radius;
	box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
	@content;
}


// 텍스트 종류(링크 포함) -> 삭제예정
@mixin text($style) {
	@if $style == normal {
		font-family: 'Happiness-Sans';
		font-size:14px;
		font-weight:400;
		color:$black;
		text-decoration:none;
	}
	@if $style == bold {
		font-family: 'Happiness-Sans-Bold';
		font-size:14px;
		font-weight:700;
		color:$black;
		text-decoration:none;
	}
}

@mixin link($style) {
	@if $style == normal {
		font-family: 'Happiness-Sans';
		font-size:14px;
		font-weight:400;
		color:$black;
		text-decoration:underline;
	}
	@if $style == bold {
		font-family: 'Happiness-Sans';
		font-size:14px;
		font-weight:900;
		color:$black;
		text-decoration:underline;
	}
}


//폰트 스타일 지정 (크기,볼륨,색상)
@mixin font($size, $weight, $color, $height) {
	font-size:$size;
	font-weight:$weight;
	color:$color;
	line-height:$height;
	letter-spacing: -0.25px;
	font-family: 'Happiness-Sans';
	@content;
}
@mixin tfont($size, $weight, $color, $height) {
	font-size:$size;
	font-weight:$weight;
	color:$color;
	line-height:$height;
	letter-spacing: -0.5px;
	font-family: 'Happiness-Sans-Title';
	@content;
}

// @mixin bfont($size, $weight, $color, $height) {
// 	font-size:$size;
// 	font-weight:$weight;
// 	color:$color;
// 	line-height:$height;
// 	letter-spacing: -0.5px;
// 	font-family: 'Happiness-Sans-Bold';
// 	@content;
// }


// 미디어 쿼리
@mixin media-query($break-point) {
	@if $break-point == pc {
		@media (min-width: 1024px) { 
			@content;
		}
	}
	@if $break-point == mo {
		@media (max-width: 1023px) { 
			@content;
		}
	}
	@if $break-point == wscorll { //가로스크롤16px넓이때문에 실제로는 1920에서 가로스크롤이 생기는거 방지하기 위해 별도 사이즈 설정
		@media (min-width: 1880px) { 
			overflow-x:hidden;
			// background:#000;
		}
	}
}

// 기본테이블
@mixin table {
	table {
		width:100%;
		border-collapse:collapse;
		thead {
			th {
				padding:14px 0;
				background-color:#F0F5F4;
				border-left:1px solid $white;
				text-align:center;
				&:first-child {
					border-left:none;
				}
				&.bd_l {
					border-left:1px solid $white;
				}
				&.bd_t {
					border-top:1px solid $white;
				}
				&.bd_b{
					border-bottom:1px solid $white;
				}
			}
		}
		tbody {
			th {
				border-bottom:1px solid #eaebea;
				border-left:1px solid #eaebea;
				font-weight:normal;
				&.first {
					border-left:none !important;
				}
				&.al_center {
					text-align:center;
				}
				&.al_right {
					text-align:right;
				}
			}
			td {
				padding:20px;
				border-bottom:1px solid #eaebea;
				border-left:1px solid #eaebea;
				background-color: $white;
				&.first {
					border-left:none;
				}
				&.al_center {
					text-align:center;
					.bullet_list {
						display:inline-block;
					}
				}
				&.al_right {
					text-align:right;
				}
				.fc_red {
					color:$red;
				}
				.fc_khaki {
					color:#00805A;
				}
				.fc_gray02 {
					color:$gray02;
				}
				/* 데이터 없을 경우 */
				.no_result {
					padding:20px 0;
					text-align:center;
					p {
						@include font(14px,400,$gray02,1.5);
					}
				}
			}
		}
		tfoot {
			td {
				padding:20px;
				border-bottom:1px solid #eaebea;
				border-left:1px solid #eaebea;
				background-color: $white;
				&.first {
					border-left:none;
				}
				&.al_center {
					text-align:center;
					.bullet_list {
						display:inline-block;
					}
				}
			}
			.al_right {
				text-align:right;
			}
		}
	}
	@content;
}

// 말줄임
@mixin ellipsis($line, $height) {
	overflow : hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: $line;
	-webkit-box-orient: vertical;
	height:$height;
	@content;
}

//박스 중앙정렬
@mixin el-center {
	display: flex;
	justify-content: center;
	align-items: center;
	@content;
}

@mixin flex-center {
	justify-content: center;
	align-items: center;
	@content;
}


// 삼각형(말풍선)
@mixin arrow($pos, $size, $color) {
	width: 0; 
	height: 0; 
	@if $pos == up {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-bottom: $size solid $color;
	}
	@if $pos == down {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-top: $size solid $color;
	}
	@if $pos == right {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-left: $size solid $color;
	}
	@if $pos == left {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-right: $size solid $color;
	}
}


@mixin bg($width,$height,$url) {
	width:$width;
	height:$height;
	background-image: url($url);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	@content;
}


//가상선택자(레이어,블록)
@mixin sudo_pos($top:initial, $right:initial, $bottom:initial, $left:initial) {
	content:'';
	position:absolute;
	top:$top;
	left:$left;
	bottom:$bottom;
	right:$right;
	@content;
}

@mixin sudo_dis($mt,$mr,$mb,$ml) {
	content:'';
	display:inline-block;
	margin-top:$mt;
	margin-right:$mr;
	margin-bottom:$mb;
	margin-left:$ml;
	vertical-align: top;
	@content;
}


// 버튼 
@mixin button($bgcolor,$fontcolor){
	display:inline-block;
	padding:12px 30px;
	background-color:$bgcolor;
	span {
		@include font(14px,700,$fontcolor,1.4);
	}
}

// 스크롤바 비노출(동작O)
@mixin sc_hidden {
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
}


// 가상 선택자 아이콘 모음 (보류)
// @mixin sudo_ar14($mt,$mr,$mb,$ml) {
//     content:'';
//     display:inline-block;
//     width:14px;
//     height:14px;
//     margin-top:$mt;
//     margin-right:$mr;
//     margin-bottom:$mb;
//     margin-left:$ml;
//     background-image:url('/images/webhome2/ico_arrow_right_14.png');
//     background-size:contain;
//     vertical-align: top;
// }
// @mixin sudo_floor28($mt,$mr,$mb,$ml) {
//     content:'';
//     display:inline-block;
//     width:28px;
//     height:28px;
//     margin-top:$mt;
//     margin-right:$mr;
//     margin-bottom:$mb;
//     margin-left:$ml;
//     background-image:url('/images/webhome2/ico_floor_28.png');
//     background-size:contain;
//     vertical-align: top;
// }
// @mixin sudo_tab12($mt,$mr,$mb,$ml) {
//     content:'';
//     display:inline-block;
//     width:12px;
//     height:12px;
//     margin-top:$mt;
//     margin-right:$mr;
//     margin-bottom:$mb;
//     margin-left:$ml;
//     background-image:url('/images/webhome2/ico_tab_active_12.png');
//     background-size:contain;
//     vertical-align: top;
// }
