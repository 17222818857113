@charset "UTF-8";

/* 레이아웃 */
#contents {
	width:100%;
	min-height:500px; //최소높이
	// padding-top:102px; 
	padding-top:70px; // 헤드 높이만큼 간격 (디자인확인)
	padding-bottom:160px;
	.conts {
		.max { //최대
			position:relative;
			max-width:1920px;
			min-width:1440px;
			margin:0 auto;
		}
		.min { //최소
			position:relative;
			width:1440px;
			margin:0 auto;
		}
		.no_side { // 사이드메뉴 없을때
			width:1198px;
			// margin:0 auto;
			margin:70px auto 0 ; // gnb만큰 간격 생성(PMA1.2)
			.view_wrap {
				padding-bottom:160px;
			}
		}
	}
}
/* //레이아웃 */

/* 서브 레이아웃 */
.conts {
	> .side {
		position: relative;
		width: 1440px;
		margin: 0 auto;
		&::after {
			content: '';
			display: block;
			clear: both;
		}
	}

	// 본문 컨테이너
	.side_conts {
		float: left;
		width: 1110px;
		min-height: 200px;
	}

	// SNB
	.aside {
		float: right;
		width: 190px;
		padding-top:0;

		.stit {
			display: block;
			@include font (14px,400,$black,1.5);
		}

		.stit + h2 {
			margin-top: 4px;
			&.no_link {
				a {
					pointer-events: none;
					> span {
						&:last-child {
							&::after {
								content: none;
							}
						}
					}
				}
			}
			a {
				> span {
					// display: block;
					display: inline-block;
					position: relative;
					padding-right:32px;
					@include font (24px,700,$black,1.4){
						letter-spacing: -1.5px;
					};
					&:last-child {
						&::after {
							content: '';
							position: absolute;
							bottom: 0;
							right: 0;
							transform: translateY(-11%);
							@include bg(28px,28px,'/images/webhome2/ico_dropdown_40.png');
						}
					}
				}
			}
		}

		> h2 {
			padding-bottom: 12px;
			@include font (24px,700,$black,1.4){
				letter-spacing: -1.5px;
			};
			border-bottom: 2px solid $black;
		}

		a {
			display: inline-block;
			transform: rotate(0.05deg);
			position: relative;
			* {
				font-weight: inherit;
				font-size: inherit;
				line-height: inherit;
				color: inherit;
			}
			&[target='_blank']::after {
				content: '';
				display: inline-block;
				margin-top: -1px;
				margin-left: -4px;
				@include bg(24px,24px,'/images/webhome2/ico_pc_link_24_gray.png');
				background-size: 24px auto;
				vertical-align: middle;
			}
		}

		.nav {
			margin-top: 20px;
			// open 스타일
			> ul {
				> li {
					&.on {
						.depth2 {
							// display:block;
						}
					}
				}
			}
			.depth1 {
				> li + li {
					margin-top: 16px;
				}
				> li > .head a,
				> li > a {
					@include font (16px,700,$gray02,1.5);
				}
				> li.on > .head a,
				> li.on > a,
				> li.current > .head a,
				> li.current > a {
					color: $black;
				}
				// > li.on + li {
				// 	margin-top: 20px;
				// }
			}
			.depth2 {
				// display:none;
				margin-top: 16px;
				// margin-bottom: 20px;
				padding-left: 12px;
				padding-bottom: 16px;
				border-bottom: 1px solid $gray05; //230106 수정
				> li + li {
					margin-top: 10px;
				}
				> li {
					position: relative;
				}
				> li > .head a,
				> li > a {
					@include font (14px,700,$gray02,1.5);
					transform: rotate(0.05deg);
				}
				> li.on > .head a,
				> li.on > a,
				> li.current > .head a,
				> li.current > a {
					color: $black;
				}
				> li.current::after {
					content: '';
					position: absolute;
					top: 6px;
					left: -11px;
					border-width: 3px;
					border-style: solid;
					border-color: $black $black transparent transparent;
				}
			}
			.depth3 {
				margin-top: 10px;
				padding-left: 8px;
				> li + li {
					// margin-top: 8px;
					margin-top: 4px;
				}
				> li > .head a,
				> li > a {
					@include font (12px,700,$gray02,1.5);
				}
				> li.on > .head a,
				> li.on > a,
				> li.current > .head a,
				> li.current > a {
					font-weight: 700;
					color: $black;
					text-decoration: underline;
				}
			}
			.out_link {
				display: inline-block;
				position: relative;
				padding-right: 18px;
				&::after {
					content: '';
					position: absolute;
					top: 50%;
					// right: 0;
					right: -2px; //230221_디자인변경사항
					transform: translateY(-50%);
					@include bg(16px,16px,'/images/webhome2/ico_pc_link_16_black.png'); //230221_디자인변경사항
					// @include bg(24px,24px,'/images/webhome2/ico_pc_link_24_gray.png');
				}
			}
		}
		//어드민관리 AS-IS
		nav.lnb {
			margin-top: 20px;
			> ul {
				> li {
					> a {
						@include font (16px,700,$gray02,1.5);
					}
					&.on {
						> a {
							color: $black;
						}
					}
					& + li {
						margin-top: 16px;
					}
				}
			}
		}
	}
}
/* //서브 레이아웃 */

/* 윈도우팝업 */
#contents {
	&.win_pop {
		min-height:auto;
		padding:0;
	}
}

/* 카드신청 서브 레이아웃(카드 다른데서 오류나면 수정필요) */
.card {
	.container {
		.min {
			display:flex;
			justify-content: space-between;
			.card_sub {
				width:1110px;
			}
			.aside {
				position:static;
			}
		}
	}
}


/* no data */
.no_result {
	padding:120px 0; // 디자인에 맞게 변경
	border:none;
	p {
		position:relative;
		padding-top:100px;
		text-align: center;
		@include font(16px,400,$gray03,1.5);
		&:before {
			content:'';
			position:absolute;
			top:0;
			left:50%;
			@include bg(80px,80px,'/images/webhome2/ico_nodata_80.png');
			transform:translateX(-50%);
		}
		&.no_ico { // 아이콘 없는 경우
			padding:64px 0;
			@include font(18px,400,$gray03,1.4);
			&:before {
				content:none;
			}
		}
	}
	&.brd { // 단독형식으로 위아래 라인이 있는 경우
		border-top:1px solid $black;
		border-bottom:1px solid $gray05;
	}
}

// 게시판 상세 보기
.board_wrap {
	.board_list {
		border-top:1px solid $black;
		border-bottom:1px solid $gray05;
		.board_head {
			display:flex;
			justify-content: space-between;
			align-items: center;
			padding:20px;
			border-bottom:1px solid $gray05;
			.sup {
				padding-right:20px;
				@include font(22px,700,$green,1.4);
			}
			.tit {
				@include font(22px,700,$black,1.4);
			}
			.date {
				margin-left:auto;
				padding-top:4px;
				@include font(16px,400,$black,1.5);
			}
			.info {
				> dl {
					display:inline-block;
					> dt {
						display:inline-block;
						@include font(16px,700,$black,1.5);
					}
					> dd {
						display:inline-block;
						margin-left:8px;
						@include font(16px,400,$black,1.5);
					}
					& + dl {
						margin-left:20px;
					}
				}
			}
		}
		.board_body {
			padding:64px 0;
		}
		& + .btn_wrap {
			margin-top:24px;
			text-align:right;
		}
	}
}